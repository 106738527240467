import React, {
	Component
} from 'react';

import Icon from 'components/Icon.js';
import VehicleAgencyLogo from 'components/Card/VehicleAgencyLogo.js';
import VehicleColor from 'components/Card/VehicleColor.js';
import lokalise from 'utils/lokalise';

class CardRowLeft extends Component {

	checkRouteNameParsing(routeName) {
		// Replace encoded dashes with text dashes
		if (routeName && routeName.match(/(&#8209;)|(&nbsp;)/)) {
			routeName = routeName.replace("&#8209;", "-");
			routeName = routeName.replace("&nbsp;", "-");
		}
		/* Custom char check for SBS to replace dashes (-) with line break */
		if (routeName.indexOf("SBS") > -1) {
			routeName.replace("-", "\n");
		}
		return routeName
	}

	// This is route name, ubers, etc
	getVehicleName() {
		var vehicleName = "";
		if (this.props.card.agency.mode === "floating") {
			vehicleName = this.props.rowData.name;
		} else {
			vehicleName = this.props.rowData.shortRoute; // masstransit
		}
		if (typeof vehicleName === "string") {
			vehicleName = this.checkRouteNameParsing(vehicleName);
		}
		return vehicleName
	}

	getVehicleCount() {
		var vehicleCount = null;
		if (this.props.card.agency.mode === "carshare" && this.props.rowData.dock) {
			vehicleCount = this.props.rowData.dock.vehicleCount.toString(); // Important for "0"
		}
		return vehicleCount;
	}

	getVehicleCountLabel() {
		var vehicleCountLabel = null;
		if (this.props.card.agency.mode === "carshare" && this.props.rowData.dock) {
			vehicleCountLabel = this.props.rowData.dock.vehicleCount === 1 ? lokalise.getKey("ts4g", {}, "one") : lokalise.getKey("ts4g", {}, "other")
		}
		return vehicleCountLabel;
	}

	getVehicleIcon() {
		var vehicleIcon = null;
		if (this.props.card.agency.mode === "pointtopoint") {
			vehicleIcon = this.props.rowData.vehicles[0].type;
		}
		return vehicleIcon;
	}


	getFlexStyle() {
		var flexStyle = {
			flex: '1 1 auto',
		};

		if (this.props.card.agency.mode === "floating") {
			flexStyle = {
				flex: '0 1 70%',
			};
		} else if (this.props.card.category === "dockless" || this.props.card.agency.mode === "pointtopoint") {
			flexStyle = {
				flex: '0 1 10%',
			};
		} else if (this.props.card.category === "docked") {
			flexStyle = {
				flex: '0 1 auto',
			};
		}

		return flexStyle;
	}

	getVOLabel(rowAgency) {
		const {
			card,
			rowData,
		} = this.props;

		const {
			category,
			agency,
			stopName,
			type
		} = card;

		const {
			fullRoute,
			dock,
			name,
		} = rowData;

		const {
			shortName,
		} = rowAgency;

		if (category === 'masstransit') {
			return fullRoute;
		}
		if (category === 'dockless') {
			return `${shortName}`;
		}
		if (category === 'docked') {
			if (dock && dock.vehicleCount) {
				return `${dock.vehicleCount} bikes available`;
			}
		}
		if (category === 'floating') {
			return name;
		}
		// if (type === 'parking') {
		// 	return "";
		// }
		return null;
	}

	render() {

		const {
			rowData,
			card,
		} = this.props;

		const {
			category,
			agency,
			agencies,
		} = card;

		const {
			agencyId
		} = rowData;

		const rowAgency = agencies ? agencies[agencyId] : agency;

		var vehicleType = null;

		// console.log("PROPS", this.props);
		var flexStyle = this.getFlexStyle();
		var vehicleName = this.getVehicleName();
		var vehicleIcon = this.getVehicleIcon();
		var vehicleCount = this.getVehicleCount();
		var vehicleCountLabel = this.getVehicleCountLabel();

		const voTitle = this.getVOLabel(rowAgency);
		return (
			<div role="cell" className="CardRowLeft" style={flexStyle}>

				{voTitle ? <span className="voiceover-text voiceover-fill">{voTitle}</span> : null}

				<div className="CardRowInner" aria-hidden="true">

					{category === 'masstransit' || category === 'floating' || card.type === 'parking' ? <VehicleColor rowData={rowData} card={card} /> : null}
					{category === 'dockless' || category === 'docked' ? <VehicleAgencyLogo rowData={rowData} card={card} /> : null}

					{vehicleName ? <div className="VehicleName">{vehicleName}</div> : null}

					{vehicleType ? <div className="VehicleType">{vehicleType}</div> : null}

					{vehicleCount ? <div className="VehicleCount">{vehicleCount}</div> : null}

					{vehicleCountLabel ? <div className="VehicleCountLabel">{vehicleCountLabel}</div> : null}

					{vehicleIcon ? <Icon category={this.props.card.agency.slug} name={vehicleIcon} /> : null}
				</div>

			</div>
		);
	}
}

export default CardRowLeft;
