import {
    Layer,
    Source,
} from 'react-map-gl';

import MapsHelper from 'utils/maps';
import React from 'react';
import config from 'config';

// @TODO this should be URLparams context, router or something
const searchParams = new URLSearchParams(window.location.search || document.location.search);
const mapColors = searchParams.get("ui") === 'dark' ? config.mapColorSchemes.dark : config.mapColorSchemes.light;

export default class WalkingPath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: null,
            layer: null
        };
    }

    componentDidMount() {
        this.updateWalkingPath();
    }

    setLayer(waypointCoordinates) {
        const sourceData = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: waypointCoordinates,
            },
        };

        const layer = {
            id: 'walkingPathLine',
            type: 'line',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': mapColors.walk,
                'line-width': 5,
                'line-dasharray': [0, 1],
            },
        };

        this.setState({
            source: sourceData,
            layer: layer
        });
    }

    updateWalkingPath() {
        const {
            startingPosition,
            endPosition,
        } = this.props;

        const originLatitude = startingPosition.latitude;
        const originLongitude = startingPosition.longitude;

        const destinationLatitude = endPosition.latitude;
        const destinationLongitude = endPosition.longitude;

        if (startingPosition && originLongitude && destinationLatitude && destinationLongitude) {
            // Save some calls, dont show it if more than 80 km
            // Turned off to verify the routing always works, re-enable if user base growth demands it
            // if (maps.getDistance(originLatitude, originLongitude, destinationLatitude, destinationLongitude) > 80) {
            //     return false
            // }

            MapsHelper.getGoogleWalkDirections(originLatitude, originLongitude, destinationLatitude, destinationLongitude, ({ result, waypointCoordinates }) => {
                // console.log('updateWalkingPath', waypointCoordinates);
                this.props.setGoogleMapsRouting(result);

                if (waypointCoordinates) {
                    this.setLayer(waypointCoordinates);
                }
            });

        }
    }
    // https://uber.github.io/react-map-gl/docs/get-started/adding-custom-data

    render() {
        if (this.state.source) {
            return (
                <Source type="geojson" data={this.state.source}>
                    <Layer {...this.state.layer} />
                </Source>
            )
        };
        return null;
    }
}
