import config from "config";
import lokalise from "./lokalise";
import moment from 'moment';

export default {

    // @param position key (0-..) position in array 
    // @param items array where key comes from
    // @return '' string of icon in icon-library.js
    getIcon(position, items) {
        if (items.length > 1) {
            if (position === 0) return 'stepStart';
            if (position === items.length - 1) return 'stepEnd';
        } else if (items.length === 1) {
            return 'stepSingle';
        };
        return '';
    },

    getModeVerb(tripPlanName) {
        switch (tripPlanName?.toLowerCase()) {
            case 'walking':
                return lokalise.getKey('tripplan_mode_walk');
            case 'car':
                return lokalise.getKey('tripplan_mode_drive');
            case 'bicycle park':
                return lokalise.getKey('tripplan_mode_bike');
            case 'bicycle':
                return lokalise.getKey('tripplan_mode_bike');
            default: return this.capitalize(tripPlanName);
        }
    },

    getDistance(distanceMeters) {
        const miles = (distanceMeters / 1609).toFixed(1);
        return miles < 0.25 ?
            lokalise.getKey('distance_feet', { num: Math.round(miles * 5280) }) :
            lokalise.getKey('distance_miles', { num: miles })
    },

    // routing-backend conversion
    // @param duration milliseconds
    // @param onlyNumber bool - number without the "min"
    getDuration(duration, onlyNumber) {
        if (!duration) return null;
        const minutes = duration / 60;
        const hour = minutes / 60;
        const roundedHour = Math.round(hour);
        const roundedMinutes = Math.round(minutes);

        if (hour > 1) {
            const mins = minutes - (hour * 60);
            if (mins === 0) {
                return <span className="duration">
                    {onlyNumber ? roundedHour : lokalise.getKey('ts13f', {
                        num: <span className="time">{roundedHour}</span>,
                    })}
                </span>;
            }
            return <span className="duration">
                {onlyNumber ? lokalise.getKey('ts13f', { num: roundedHour }) + ' ' + roundedMinutes : lokalise.getKey('ts13h', {
                    numHr: <span className="time">{roundedHour}</span>,
                    numMin: <span className="time">{roundedMinutes}</span>
                })}
            </span>;
        }

        if (roundedMinutes === 0) return null;
        return <span className="duration">
            {onlyNumber ? roundedMinutes : lokalise.getKey('num_min', { num: <span className="time">{roundedMinutes}</span> })}
        </span>;
    },

    // Duration is ms
    getDurationWithVerb: (duration, verb) => {
        if (!duration) return null;
        const minutes = Math.ceil(duration / 60);
        const hours = minutes / 60;
        const roundedMins = Math.round(minutes);
        const roundedHours = Math.round(hours);

        if (roundedHours > 1) {
            const remainderMinutes = minutes - (roundedHour * 60);
            return <span className="duration">
                {lokalise.getKey('time_hour_min_verb', { h: roundedHours, m: remainderMinutes, verb })}
            </span>;
        }
        if (roundedMins === 0) return null;
        return <span className="duration">
            {lokalise.getKey('time_min_verb', { m: roundedMins, verb })}
        </span>;
    },

    getGoogleMapsWalkPhrase: (googleMapsRouting) => {
        let newWalkMinutes = null;
        const ms = googleMapsRouting.routes[0].legs[0].duration?.value;
        if (ms < 86400) {
            const mins = Math.round(ms / 60);
            if (mins > 60) {
                const numHr = Math.round(mins / 60);
                const numMin = Math.round(mins % 60);
                newWalkMinutes = lokalise.getKey('ts13h', { numHr, numMin });
            } else if (mins > 0) {
                newWalkMinutes = `${mins} ${lokalise.getKey('ts3j')}`;
            }
        }
        return newWalkMinutes;
    },

    getWalkPhrase: (walkMinutes) => {
        return <><span>{walkMinutes}</span> {lokalise.getKey('ts3j')}</>;
    },
    getEstimatedArrival: (walkMinutes) => {
        const arrivalTime = moment().add(walkMinutes, 'minutes').format('h:mm a');

        return lokalise.getKey('tripplan_est_arrival', { time: <span>{arrivalTime}</span> })
    },

    // Text sanitization should probably should be part of the Journey API
    capitalize(fullString) {

        if (!fullString || !fullString.length) return '';

        function upperCaseFirstLetter(txt) {
            return txt.charAt(0).toUpperCase() + txt.toLowerCase().slice(1);
        }

        fullString = fullString.replace("/", " / ");

        const pieces = fullString.split(" ");
        const final = pieces.map((txt) => {
            if (txt.includes("-")) {
                let dashed = txt.split("-");
                dashed = dashed.map((txt) => upperCaseFirstLetter(txt));
                return dashed.join("-");
            }
            return upperCaseFirstLetter(txt);
        });
        return final.join(" ");
    },

    impactGramToKilo(impact) {
        impact /= 1000;
        return impact.toFixed(1);
    },

    getAIResultText(name, value) {
        if (name === 'cost') {
            return value === 0 ? '0.0' : value;
        } else if (name === 'impact') {
            return `${this.impactGramToKilo(value)} kg`;
        } else if (name === 'calories') {
            return value; // Calories is in the translation
        } else if (name === 'interaction') {
            if (value <= .33) return lokalise.getKey('interaction_low');
            if (value > .33 && value <= .66) lokalise.getKey('interaction_medium');
            if (value < .66) return lokalise.getKey('interaction_high');
        }
        return value;
    },

    isTransitMode(mode) {
        const phrase = mode && mode.toLowerCase() || '';
        return [
            'transit',
            'subway',
            'bus',
            'tram',
            'train',
            'shuttle',
            'ferry',
            'gondola',
            'funicular',
            'cable-car',
            'flight',
            'streetcar'
        ].indexOf(phrase) !== -1;
    },

    isTransitTrip(name) {
        const phrase = name && name.toLowerCase() || '';
        return [
            'bicycle rent',
            'vanpool',
            'shuttle',
            'carpool',
            'park and ride',
            'hybrid',
            'transit',
            'bus',
            'tram',
            'train',
            'subway',
            'cable car',
            'ferry',
            'streetcar',
            'gondola',
            'funicular',
            'airplane'
        ].indexOf(phrase) !== -1;
    },

    isNonTransitTrip(name) {
        const phrase = name && name.toLowerCase() || '';
        return [
            'walking',
            'bicycle',
            'car',
            'driving and parking',
            'bicycle park'
        ].indexOf(phrase) !== -1;
    }

}
