// Config defines many swappable magic and api keys and endpoints for the app
// Also a lot of UI stuff like row limits and navigation bar presets

const today = new Date();
const oneYear = 1000 * 60 * 60 * 24 * 365;
const cookieExpiration = new Date(today.getTime() + (oneYear));
const isProd = window.location.hostname.split('.')[0] !== 'staging' && window.location.hostname.split('.')[0] !== 'localhost';

export default {
	publicAppName: 'Actionfigure Mobile',
	internalAppName: 'citymotion-web', // Sentry release reporting
	version: '3.7.0', // Sentry release reporting

	updateDataRate: 45000, // This should always be 45000 ms
	updateDataTimeout: 15000,
	//Max Value is 1.79E+308
	maximumServerErrorRetries: Number.MAX_VALUE,

	// Internal AF
	routingBackendURL: 'https://af-routing-backend.herokuapp.com/v3', // localtest http://localhost:5000/
	routingBackendKey: '9EDYtskTvX2VMam9u4ojvlXrL4bFEY6JCC80zzwbeyWYhpfSHcKXcIFHy8SlUjLg',

	mobilityScoreURL: 'https://mobilityscore.transitscreen.io/mobilityscore/v1/score.json',
	mobilityScoreKey: 'NRAj55h62W0UL5rK',

	googleAnalyticsId: 'UA-114916925-5',
	googleAnalytics4Id: 'G-TJ03LQ1YJC',

	//Usage Tracking
	pluginUsageTrackingUrl: 'https://transitscreen.io/customers/trackPluginUsage',

	// All menu header is run off maps
	googleMapsApiKey: 'AIzaSyDFo9NSxCFAqeY-4J0ZIDUvYSJ0pa3Hl48', // All libraries init on this api key
	googleMapsEndpoint: 'https://maps.googleapis.com/maps/api/js',
	googleDirectionsEndpoint: 'https://maps.googleapis.com/maps/api/directions/json',
	googlePlacesEndpoint: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
	googlePlacesSearchRadius: 5000,
	googleMapsHomeURL: 'https://www.google.com/maps',
	googleMapsDirectionsURL: 'https://www.google.com/maps/dir/?api=1&',

	// All trip planning is run off mapbox
	mapboxDefaultMapCenter: { latitude: 39, longitude: -98 }, // USA center
	mapboxLightStyle: 'mapbox://styles/mapbox/light-v10',
	mapboxDarkStyle: 'mapbox://styles/mapbox/dark-v10',
	mapboxGeocodingURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places', // /{search_text}.json?params
	mapboxSearchDebounce: 500,

	apiGatewayTokenUrl: 'https://api.actionfigure.ai/auth/v1/token',
	datadropUrl: 'https://api.actionfigure.ai/data-drop/v1',
	googleAnalytics4Id: 'G-TJ03LQ1YJC',
	googleAnalyticsId: 'UA-114916925-5',
	googleMapsApiKey: 'AIzaSyDFo9NSxCFAqeY-4J0ZIDUvYSJ0pa3Hl48',
	googleMapsEndpoint: 'https://maps.googleapis.com/maps/api/js',
	mapboxToken: 'pk.eyJ1IjoidHJhbnNpdHNjcmVlbmFkbWluIiwiYSI6ImNtMG1xMGNqMTA1ODIybW9yb3A0Y204NW4ifQ.AUCjef5o_T32f8DJjs_zUw',
	oktaClientId: '0oaj3dmakn4SS5JQB357',
	oktaClientSecret: 'ZYL7uUZnmqWlfULYdKxzvwm7eKUflhitMTSH0TF7',
	routingBackendKey: '9EDYtskTvX2VMam9u4ojvlXrL4bFEY6JCC80zzwbeyWYhpfSHcKXcIFHy8SlUjLg',
	routingBackendUrl: 'https://journey.actionfigure.ai/v3',
	sendToDataDrop: isProd,

	// These are default color schemes shared between the TripPlanMap and Template
	mapColorSchemes: {
		light: { default: '#000724', shadow: '#888', walk: '#222', transit: '#888', driving: '#555', bicycle: '#555', },
		dark: { default: '#fff', shadow: '#222', walk: '#fff', transit: '#eee', driving: '#aaa', bicycle: '#ccc', }
	},

	searchMaxCoordinateDecimals: 6,

	routingBackend: {

		defaultMode: 'DEFAULT',
		defaultUserPreferenceValues: { cost: 5, green: 5, health: 5, social: 5, time: 5, easy: 5 },

		// Used to add additional front-end styling to tripOptions
		tripOptionsStyling: [
			{ label: 'Recommended', minMax: 'max', key: 'bestFit', icon: 'recommended', color: '#0FCECE' },
			{ label: 'Fast', minMax: 'min', key: 'duration', icon: 'fast', color: '#ff8c4b' },
			{ label: 'Cheap', minMax: 'min', key: 'cost', icon: 'cheap', color: '#5CDFFF' },
			{ label: 'Direct', minMax: 'min', key: 'easiness', icon: 'direct', color: '#FFCB44' },
			{ label: 'Sustainable', minMax: 'min', key: 'impact', icon: 'sustainable', color: '#752074' },
			{ label: 'Healthy', minMax: 'max', key: 'calories', icon: 'healthy', color: '#A5DF00' },
			{ label: 'Quiet', minMax: 'min', key: 'interaction', icon: 'quiet', color: '#FD4A5C' },
		],

		// modeQueryName: Transit type modes require at least one leg to have a TRANSIT mode marked
		tripModesWithTransit: [
			'TRANSIT,WALK', 'TRAM,WALK', 'SUBWAY,WALK', 'RAIL,WALK', 'CABLE_CAR,WALK', 'FERRY,WALK', 'GONDOLA,WALK', 'FUNICULAR,WALK'
		],
		// modeQueryName: This is used for Trip Map
		tripModesWithLegs: [
			'TRANSIT,WALK', 'TRAM,WALK', 'SUBWAY,WALK', 'RAIL,WALK', 'CABLE_CAR,WALK', 'FERRY,WALK', 'GONDOLA,WALK', 'FUNICULAR,WALK', 'BICYCLE_RENT'
		],
		// modeQueryName: This is used for Trip Map
		tripModesWithGeometry: [
			'WALKING', 'CYCLING', 'DRIVING'
		],

		// modeQueryName: Currently all these modes get generalized into a single template style whereas others get their own
		transitModesTemplate: [
			'TRANSIT,WALK', 'TRAM,WALK', 'SUBWAY,WALK', 'RAIL,WALK', 'CABLE_CAR,WALK', 'FERRY,WALK', 'GONDOLA,WALK', 'FUNICULAR,WALK',
		],
		// modeQueryName: walking, driving
		tripModesWithSteps: [
			'WALKING', 'DRIVING', 'CYCLING', 'BICYCLE_PARK'
		],

	},

	cityMotionBranchURL: 'https://my.citymo.io',

	allowedPathNames: ['trip-plan', 'browser', 'iframe', 'partner'], // Just used for URL tracking purposes

	supportEmailAddress: 'app@actionfigure.ai',

	localStorageItemKey: 'cmwUserData',

	cookies: {
		savedPlaces: {
			name: '_cmwSavedPlaces',
			expires: cookieExpiration,
			maxAge: oneYear,
		},
		favorites: {
			name: '_cmwFavorites',
			expires: cookieExpiration,
			maxAge: oneYear,
		},
		userSettings: {
			name: '_cmwUserSettings',
			expires: cookieExpiration,
			maxAge: oneYear,
		},
	},

	development: {
		autoRouteEndpoint: 'https://staging.transitscreen.io/auto/route.json',
		appSyncEndpoint: 'https://staging.transitscreen.io/customers/appSync.json',
	},

	staging: {
		autoRouteEndpoint: 'https://staging.transitscreen.io/auto/route.json',
		appSyncEndpoint: 'https://staging.transitscreen.io/customers/appSync.json',
	},

	production: {
		autoRouteEndpoint: 'https://api.citymotion.io/auto/route.json',
		appSyncEndpoint: 'https://api.citymotion.io/customers/appSync.json',
	},

	communityMessageRatio: 1.45,

	// Assigns cardTypes based on object property pattern matching
	cardTypes: {
		agency: {
			fullName: {
				traffic: ['inrix', 'traffic', 'trafficmapbox'],
			},
		},
	},

	/*
	 * This app takes certain API properties and transforms them for React flow
	 */
	apiProps: {
		sortOrder: 'sortOrder', // Number ie: 3021
		transitItems: 'predictions',
		transitItemsSort: 'predictionMinutesAway',
		docklessItems: 'locations',
		docklessItemsSort: 'walkMinutes',
	},

	cmwCardProps: {
		sortOrder: 'sortProps', // Obj ie: {column: 3, row: 2, group: 1}
	},

	// These identify TS categorizations of transportation modes for Template assignment
	// Reference 'Modes' obj in /config/app.php in the TransitScreen repo
	// Passed into the card type to allow front-end customization
	// modeCategories:key tells us an arbitrary Template name
	// key:array tells us which TS modes/cards to be piped into this Template
	modeCategories: {
		masstransit: ['bus', 'subway', 'streetcar', 'train', 'tram', 'cable-car', 'gondola', 'ferry', 'shuttle', 'high-speed-rail'],
		dockless: ['scootershare', 'sit-scootershare', 'ebikeshare', 'dockless-bikeshare', 'pointtopoint'],
		docked: ['bikeshare', 'carshare'],
		floating: ['floating'],
	},

	// See TabBar.js
	// These are exclusively for the nav bar (and related mode filtering) and differ in wording from above
	// The keys matches the Documentation keys for the feature openTo= all, train, bus, bike, car, shuttle
	modeFilterButtons: {
		all: { modes: 'all', lokaliseTitleKey: 'nav_all', navIconName: 'all' },
		train: { modes: ['subway', 'streetcar', 'train', 'tram', 'high-speed-rail'], lokaliseTitleKey: 'nav_rail', navIconName: 'rail' },
		bus: { modes: ['bus'], lokaliseTitleKey: 'ts1m', navIconName: 'bus' },
		car: { modes: ['car', 'pointtopoint', 'taxi-stand', 'carshare', 'floating', 'parking'], lokaliseTitleKey: 'ts6w', navIconName: 'car' },
		bike: { modes: ['bike', 'bikeshare', 'scootershare-docked', 'scootershare', 'sit-scootershare', 'dockless-bikeshare', 'ebikeshare'], lokaliseTitleKey: 'nav_micro', navIconName: 'micro' },
		shuttle: { modes: ['shuttle', 'ferry', 'funicular', 'cable-car', 'gondola'], lokaliseTitleKey: 'nav_other', navIconName: 'other' },
	},

	modeNames: ['bike', 'bus', 'car', 'shuttle', 'train'],

	// On MobilityScreen and CardScreen card rows are limited to make view less busy
	defaultRowLimit: {
		masstransit: 8, // Approx 1 full viewport height in mobile
		waittime: 15, // Special event cards, in this case we basically want to "show all"
		floating: 6,
		dockless: 6,
		docked: 6,
		carshare: 6,
		weather: 4,
	},

	//Params that Mapbox uses to parse address data
	ADDRESS_DETAIL_SENT_TO_COMMUTES_TABLE: ['country', 'postcode', 'region', 'place'],

	//List of color schemes that can be edited under colorScheme param
	COLOR_PARAMS: ['mainBg', 'cardBg', 'cardText', 'mainText'],

	// Flight

	flight: {
		imageHost:
			"https://fe54981f5455028e4da4-91d59b46056b5806f131a0e9762d81ab.ssl.cf5.rackcdn.com",
	},
};
