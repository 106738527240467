import Icon from 'components/Icon';
import React from 'react';
import Tappable from 'components/common/Tappable';

function TabBarButton(props) {
	const {
		selectedTab,
		modeClass,
		onNavBarButtonClick,
		title,
		navIconName
	} = props;

	function tabBarButtonClick(e) {
		e.preventDefault();
		onNavBarButtonClick(modeClass);
	}

	const tabSelected = selectedTab === modeClass;

	const classNames = tabSelected ? 'TabBarButton selected' : 'TabBarButton';

	const voText = `Show all ${title} options`;

	return (
		<div className={`${classNames} ${modeClass} ${modeClass !== 'tripplan' ? 'filterButtons' : ''}`}>
			<Tappable type="button" role="navigation" onTap={tabBarButtonClick}>
				<div className="icon">
					<Icon category="nav" name={navIconName} />
				</div>
				<span className="voiceover-text">{voText}</span>
				<span className="text" aria-hidden="true">{title}</span>
				<div className="selected-indicator"></div>
			</Tappable>
		</div>
	);
}

export default TabBarButton;
