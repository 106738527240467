import React, {
	Component,
} from 'react';

import Icon from 'components/Icon';
import Tappable from 'components/common/Tappable';
import {allowSeeMoreOptions} from 'utils/params'

function SeeMore(props) {
	const {
		showAll,
		tapToggleCardExpand,
		hiddenCount,
		showCardScreen,
	} = props;

	if (!allowSeeMoreOptions) {
		return null
	}

	return (
		<Tappable className="SeeMore" pressDelay={1500} role="button" aria-label="Toggle more rows" onTap={tapToggleCardExpand} stopPropagation>

			{showCardScreen ?
				<>
					<Icon category="ui" name={showAll ? "collapse" : "expand"} />
					{showAll ? "Show less" : `Show ${hiddenCount} more options`}
				</>
				:
				<>
					<Icon category="ui" name={"plus"} />
					{hiddenCount} more options
				</>
			}

		</Tappable>
	);
}

export default SeeMore;
