import React, { Component } from 'react';

import AgencyAlertCard from 'components/Card/AgencyAlertCard.js';
import CommunityMessageCard from 'components/Card/CommunityMessageCard.js';
import Cookies from 'universal-cookie';
import FlightCard from 'components/Card/FlightCard.js';
import LogoCard from 'components/Card/LogoCard.js';
import Nearby from 'components/Card/Nearby.js';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import StaticImageCard from 'components/Card/StaticImageCard.js';
import StaticTextCard from 'components/Card/StaticTextCard.js';
import TrafficCard from 'components/Card/TrafficCard.js';
import TrafficMapboxCard from 'components/Card/TrafficMapboxCard.js';
import TrafficV2Card from 'components/Card/TrafficV2Card.js';
import TransportationCard from 'components/Card/TransportationCard.js';
import WeatherCard from 'components/Card/WeatherCard.js';
import analytics from 'utils/analytics';
import config from 'config';
import lokalise from 'utils/lokalise';

const UniversalCookie = new Cookies();

/* All props are passed through from CardList */
class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            opened: false,
        };
        this.getCardComponent = this.getCardComponent.bind(this);
        this.tapOpenCardView = this.tapOpenCardView.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    // componentDidMount() {
    // // Fancy nice fade in
    // setTimeout(() => {
    //     const ele = ReactDOM.findDOMNode(this);
    //     ele.style.opacity = 1;
    // }, this.props.index * 100);
    // }

    // Only used for updating front-end
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    // Only used for reporting
    // componentDidCatch(error, info) {
    //     console.log("Card Error:", error, info);
    // }
    componentWillUnmount() {
        // Remove any visibility classes
        const ele = ReactDOM.findDOMNode(this);
        if (ele && ele.className) ele.className = 'Card';

        // Clean up listeners
        document.removeEventListener('keydown', this.escFunction, false);

        // console.log("UNMOUNT", this.props.card.id);
    }

    async tapOpenCardView() {
        const { opened } = this.state;

        this.props.toggleCardScreen(true, this.props.card);

        /* Rest of the code from here manages the interior card state and reporting */

        ReactGA.event('Card Header', {
            category: 'Card Header',
            action: `Card Header Clicked`,
            agencyId: this.props.card.agencyId,
            stopName: this.props.card.stopName,
        });

        analytics.postToDataDrop(
            {
                agencyId: this.props.card.agencyId,
                stopName: this.props.card.stopName,
            },
            'card-header-clicked'
        );

        // TODO: Remove internal state, move to app.js
        this.setState({
            opened: !opened,
        });
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.tapOpenCardView();
        }
    }

    /*
     * By now this.props.card will represent the transformed entity in utils/sorter
     * And additional UI values added by CardColumns.js
     * Card.js only considers delivering the proper HTML template and CSS info
     */
    getCardComponent() {
        // console.log("getCardComponent", this.props.card);
        const { params } = this.props;
        // console.log(params)
        const cardShadow = params?.cardShadow;
        const borderRounding = params?.borderRounding;
        const vehicleColor = params?.vehicleColor;
        const cardHeader = params?.cardHeader;

        let className = 'Card';

        // FAVORITING FEATURE IS CONDITIONALLY TURNED ON HERE
        // EVEN IF THE CARD INHERENTLY WAS FAVORITED BEFORE
        let cardIsFavorite = false;
        if (params.favorites === 'true' && this.props.card?.isFavorite) {
            className += ' favorite ';
            cardIsFavorite = true;
        }

        className += cardShadow === 'false' ? ' noCardShadow' : ' cardShadow';

        className +=
            borderRounding === 'false'
                ? ' noCardBorderRounding'
                : ' cardBorderRounding';

        className += ` ${this.props.card.visible ? 'visible' : 'hidden'}`;
        className += vehicleColor === 'false' ? ' noVehicleColor' : '';
        className += cardHeader === 'false' ? ' noCardHeader' : '';

        /* Then create component */
        var cardContentComponent = null;

        // Card Types are set in Sorter.helper.js

        if (this.props.card.type === 'agencyAlert') {
            cardContentComponent = <AgencyAlertCard {...this.props} />;
        } else if (
            this.props.card.type === 'masstransit' ||
            this.props.card.type === 'parking' ||
            this.props.card.type === 'dockless' ||
            this.props.card.type === 'docked'
        ) {
            className += ' TransportationCard';
            cardContentComponent = (
                <TransportationCard
                    {...this.props}
                    opened={this.state.opened}
                    tapOpenCardView={this.tapOpenCardView}
                    isFavorite={cardIsFavorite}
                    setCardFavorite={this.props.setCardFavorite}
                />
            );
        } else if (this.props.card.type === 'traffic') {
            className += ' TrafficCard';
            cardContentComponent = <TrafficCard {...this.props} />;
        } else if (this.props.card.type === 'trafficv2') {
            className += ' TrafficV2Card';
            cardContentComponent = <TrafficV2Card {...this.props} />;
        } else if (this.props.card.type === 'trafficmapbox') {
            className += ' TrafficMapboxCard';
            cardContentComponent = <TrafficMapboxCard {...this.props} />;
        } else if (this.props.card.type === 'staticText') {
            className += ' StaticTextCard';
            cardContentComponent = <StaticTextCard {...this.props} />;
        } else if (this.props.card.type === 'nearby') {
            className += ' NearbyCard';
            cardContentComponent = <Nearby {...this.props} />;
        } else if (this.props.card.type === 'message') {
            className += ' CommunityMessageCard';
            cardContentComponent = <CommunityMessageCard {...this.props} />;
        } else if (this.props.card.type === 'image') {
            className += ' StaticImageCard';
            cardContentComponent = <StaticImageCard {...this.props} />;
        } else if (this.props.card.type === 'logo') {
            className += ' LogoCard';
            cardContentComponent = <LogoCard {...this.props} />;
        } else if (this.props.card.type === 'weather') {
            className += ' WeatherCard';
            cardContentComponent = <WeatherCard {...this.props} />;
        } else if (this.props.card.type === 'flight') {
            className += ' FlightCard';
            cardContentComponent = <FlightCard {...this.props} />;
        } else if (this.props.card.type === 'localevents') {
            return null;
        } else {
            return null;
        }

        if (this.state.opened) className += ' opened';

        return <article className={className}>{cardContentComponent}</article>;
    }

    render() {
        if (this.state.hasError) return null;

        return this.getCardComponent();
    }
}

export default Card;
