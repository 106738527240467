import React, {
	Component
} from 'react';

import ExternalAppButton from 'components/Card/ExternalAppButton.js';

import lokalise from 'utils/lokalise';

/* For Transit cards, shows a single mobile tickets button */
function MobileTicketButton(props) {
	if (props.card.category === "masstransit" && props.card.agency) {
		return (
			<ExternalAppButton linkType="GET_MOBILE_TICKET" agency={props.card.agency} buttonText={lokalise.getKey('ts1g')} />
		);
	}
	return null;
}

export default MobileTicketButton;
