import ExitButton from 'components/ExitButton';
import styled from 'styled-components';

const Wrapper = styled.div`
flex: 0 0 60px;
height: 60px;
display: flex;
align-items: center;
color: var(--appHeaderText);
background: var(--appHeaderBackground);
padding: 0 0 0 1em;
position: relative;
.Text {
  font-weight: 400;
  color: var(--appHeaderTextSecondary);
  max-width: 85%;
  .divider {
    color: var(--appHeaderTextSecondary);
  }
  span {
    font-size: 1em;
    font-weight: 600;
    color: var(--appHeaderText);
  }
}

`;

export default function CardScreenHeader({ text, tapEvent }) {
  return (
    <Wrapper>
      <div className="Text">{text}</div>
      <ExitButton showButton={true} tapEvent={tapEvent} />
    </Wrapper>
  )
}
