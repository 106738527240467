import styled from 'styled-components';
import { useRef } from 'react';

// We keep the wrapper a button tag for accessibility
const TappableWrapper = styled.button`
    background: none;
    position: relative;
    text-align: unset;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    * {
        z-index: 2;
    }
    &:hover, &:active {
        background: none;
        .Highlight {
            opacity: 0.5;
        }
    }
`;
const Highlight = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: var(--buttonBackgroundHover);
    transition: opacity 200ms ease-out;
`;

export default function Tappable({ ariaLabel, onTap, className, style, role = "button", children, pressDelay, stopPropagation }) {
    const highlightRef = useRef(null);

    const onClick = (e) => {
        // Skipping pressDelay for now
        fadeAndTap(e);
    };

    const fadeAndTap = (e) => {
        if (highlightRef && highlightRef.current) {
            highlightRef.current.style.opacity = 0.8;
            const timer = setTimeout(() => {
                if (onTap) onTap(e);
                if (highlightRef && highlightRef.current) highlightRef.current.style.opacity = 0;
                clearTimeout(timer);
            }, 200);
        } else {
            if (onTap) onTap(e);
        }
    };
    return (
        <TappableWrapper className={className} aria-label={ariaLabel} onClick={onClick} style={style} role={role}>
            {children}
            <Highlight ref={highlightRef} className="Highlight" />
        </TappableWrapper>
    );
}