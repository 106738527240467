import React, {
    useEffect,
    useState,
} from 'react';

function UpdateAnimation(props) {

    const {
        loading,
        updating
    } = props;

    const divRef = React.useRef();

    function removeAnimation() {
        setTimeout(() => {
            if (updating === false && divRef && divRef.current && divRef.current.className.indexOf('Stretch') !== -1) {
                setTimeout(() => {
                    window.requestAnimationFrame(() => {
                        if (divRef && divRef.current) {
                            divRef.current.className = 'UpdateAnimation Stretch Fade';
                        }
                    });
                    setTimeout(() => {
                        if (divRef && divRef.current) {
                            divRef.current.className = 'UpdateAnimation';
                        }
                    }, 250);
                }, 250);
            }
        }, 2500); // Give enough time for card rendering update
    }

    useEffect(() => {
        removeAnimation();
    });

    if (updating && divRef && divRef.current) {
        window.requestAnimationFrame(() => {
            if (divRef && divRef.current) {
                divRef.current.className = 'UpdateAnimation Stretch';
            }
        });
    }

    let style = null;
    if (props.params && props.params.cardBg) {
        style = {
            backgroundColor: '#' + props.params.cardBg,
            boxShadow: '0 0 2em #' + props.params.cardBg,
        };
    }

    return (
        <div ref={divRef} style={style}>
            <div className="ActiveWorkingIndicator" />
        </div>
    );
}

export default UpdateAnimation;
