import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, {
	Component
} from 'react';

import {
	Carousel
} from 'react-responsive-carousel';
import config from 'config.js';

export default class CommunityMessageCard extends Component {
	constructor(props) {
		super(props);
		// Default minimum dimensions with 1.45 ratio
		this.state = {
			width: 280,
			height: 240
		}

		this.carouselWrapper = React.createRef();

		this.getSlides = this.getSlides.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);

		this.resizes = 0;
	}

	componentDidMount() {
		this.updateDimensions();
	}

	componentDidUpdate() {
		// Arbitrarily picks a no of render cycles to when the page widths are settled
		if (this.resizes < 2) {
			this.updateDimensions();
			this.resizes += 1;
		}
	}

	getBackgroundColor(item) {
		if (item.type === "emergency") {
			return "#D50024"
		} else if (item.type === "notice") {
			return "#2c68ed"
		} else if (item.type === "warning") {
			return "#cb4400"
		} else if (item.type === "celebration") {
			return "#127b36"
		} else {
			return "#555"
		}
	}

	// Recalculates the dimensions to fit perfectly into the column
	// This is because the plugin needs to adjust its slider in responsive design
	updateDimensions() {
		var width = this.state.width;
		if (this.carouselWrapper.current) {
			width = this.carouselWrapper.current.clientWidth;
		}
		// Limit the creeping flex width when user is dragging window back and forth
		this.setState({
			width: width,
			height: Math.round(width / config.communityMessageRatio) + 20 // Accommodate buttons
		})
	}

	getSlides() {
		return this.props.card.messages.map((item, i) => {
			return (
				<div key={i} className="Slide" style={{ backgroundImage: `url(${item.image})`, width: this.state.width, height: this.state.height }}>
					{item.title ? <div className="Title" style={{ backgroundColor: this.getBackgroundColor(item) }}><span>{item.title}</span></div> : null}
					{item.message ? <div className="Message"><span>{item.message}</span></div> : null}
				</div>
			)
		});
	}

	render() {
		const isAutoPlay = this.props.params.interactive === 'false' ? true : false;
		return (
			<div className="Content" ref={this.carouselWrapper} >
				<div className="wrapper" style={{ width: this.state.width, height: this.state.height }}>
					<Carousel
						emulateTouch={true}
						useKeyboardArrows={true}
						showArrows={true}
						showStatus={false}
						showThumbs={false}
						autoPlay={isAutoPlay}
						infiniteLoop={isAutoPlay}
						interval={5000}
					>
						{this.getSlides()}
					</Carousel>
				</div>
			</div>
		);
	}
}
