import React, {
    Component
} from 'react';

import CardNotice from 'components/Card/CardNotice';
import lokalise from 'utils/lokalise';

// camelCase the filename
import caOntarioHwy from 'img/svg/traffic/ca-ontario-hwy.svg';
import caOntarioKingsJct from 'img/svg/traffic/ca-ontario-kings-jct.svg';
import caOntarioKings from 'img/svg/traffic/ca-ontario-kings.svg';
import generic from 'img/svg/traffic/generic.svg';
import ilFreeway from 'img/svg/traffic/il-freeway.svg';
import ilIntercityExpressway from 'img/svg/traffic/il-intercity-expressway.svg';
import ilLocalRoad from 'img/svg/traffic/il-local-road.svg';
import ilRegionalRoad from 'img/svg/traffic/il-regional-road.svg';
import usCa from 'img/svg/traffic/us-ca.svg';
import usHwyCa from 'img/svg/traffic/us-hwy-ca.svg';
import usHwyWide from 'img/svg/traffic/us-hwy-wide.svg';
import usHwy from 'img/svg/traffic/us-hwy.svg';
import usInterstateWide from 'img/svg/traffic/us-interstate-wide.svg';
import usInterstate from 'img/svg/traffic/us-interstate.svg';

// Map the file back to the JSON hyphenated name and provide customizations
const trafficIcons = {
    'ca-ontario-hwy': {
        icon: caOntarioHwy,
        textColor: "#222"
    },
    'ca-ontario-kings-jct': {
        icon: caOntarioKingsJct,
        textColor: "#222"
    },
    'ca-ontario-kings': {
        icon: caOntarioKings,
        textColor: "#222"
    },
    'generic': {
        icon: generic,
        textColor: "#222"
    },
    'il-freeway': {
        icon: ilFreeway,
        textColor: "#222"
    },
    'il-intercity-expressway': {
        icon: ilIntercityExpressway,
        textColor: "#222"
    },
    'il-local-road': {
        icon: ilLocalRoad,
        textColor: "#222"
    },
    'il-regional-road': {
        icon: ilRegionalRoad,
        textColor: "#222"
    },
    'us-ca': {
        icon: usCa,
        textColor: "#fff"
    },
    'us-hwy-ca': {
        icon: usHwyCa,
        textColor: "#222"
    },
    'us-hwy-wide': {
        icon: usHwyWide,
        textColor: "#222"
    },
    'us-hwy': {
        icon: usHwy,
        textColor: "#222"
    },
    'us-interstate-wide': {
        icon: usInterstateWide,
        textColor: "#f2f2f2"
    },
    'us-interstate': {
        icon: usInterstate,
        textColor: "#f2f2f2"
    }
}

export default class TrafficMapboxCard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.getRoutes = this.getRoutes.bind(this);
    }

    componentDidMount() { }

    getRoutes() {
        if (!this.props.card.routes) {
            return (<CardNotice {...this.props} />);
        }

        return this.props.card.routes.map((item, i) => {
            return (
                <div key={i} className="row">
                    <div className="destination">{item.destinationName}</div>
                    <div className="route">
                        <RouteIcon item={item} />
                    </div>
                    <div className="prediction">
                        {item.predictions[0]} <div className="unit">{lokalise.getKey("ts3i")}</div>
                    </div>
                </div>
            )
        });
    }

    render() {

        let visibleClassName = this.props.card.visible ? "visible" : "hidden";

        return (
            <div className={`Content ${visibleClassName}`}>
                <div className="CardHeader"><h2>{lokalise.getKey('ts10c')}</h2></div>
                <div className="CardBody">
                    <div className="CardRows">
                        <div className="header">
                            <div className="destination">{lokalise.getKey('ts9y')}</div>
                            <div className="route">{lokalise.getKey('ts9z')}</div>
                            <div className="prediction">{lokalise.getKey('ts10a')}</div>
                        </div>
                        {this.getRoutes()}
                    </div>
                </div>
            </div>
        )
    }
}

function RouteIcon(props) {

    if (props.item && props.item.routeClassName) {
        var trafficIcon = trafficIcons[generic];
        var textColor = "#222";

        let name = props.item.routeClassName;
        if (trafficIcons[name]) {
            trafficIcon = trafficIcons[name].icon;
            textColor = trafficIcons[name].textColor;
        }

        return (
            <div className="RouteIcon" style={{
                backgroundImage: `url(${trafficIcon})`,
                color: textColor
            }}>
                <div className="label">{props.item.routeNumber}</div>
            </div>
        )
    } else {
        return (<div className="label text">{props.item.routeDescription}</div>)
    }
}
