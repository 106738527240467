import React from 'react';



export default function StaticImageCard(props) {
    if (!props.card.imageUrl) return null;
    return (
        <div className="Content">
            <div className="Image"><img src={props.card.imageUrl} alt="" /></div>
        </div>
    )
}
