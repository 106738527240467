import React, { createContext, useEffect, useRef } from 'react';

const GoogleMapsContext = createContext(null);

function GoogleMapsProvider({ children }) {
  const searchService = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (window.google) {
      const map = new window.google.maps.Map(document.getElementById("gmaps"), {
        zoom: 15,
      });
      const service = new window.google.maps.places.PlacesService(map);
      map.current = map;
      searchService.current = service;
    }
  }, [window.google]);

  function getGMPlacesLocation(location) {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        if (!searchService || !searchService.current) {
          reject(null); // This should only happen on first load before user interacts
          return;
        }

        if (!location.googlePlaceId) {
          location.googlePlaceId = await getPlacesId(location);
        }
        const parsedLocation = await getParsedLocationDetails(location);
        resolve(parsedLocation);
      }, 100);
    });
  }

  function getGMPlacesResults(inputValue, searchAreaLocation, searchLanguage) {
    return new Promise(async (resolve, reject) => {

      if (!searchService || !searchService.current) {
        reject(null); // This should only happen on first load before user interacts
        return;
      }

      const location = searchAreaLocation && searchAreaLocation.coords ? { lat: parseFloat(searchAreaLocation?.coords?.latitude), lng: parseFloat(searchAreaLocation?.coords?.longitude) } : null;
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#TextSearchRequest
      searchService.current.textSearch({
        query: inputValue,
        // below params biases but doesnt exclude
        region: "us",
        location,
        language: searchLanguage
      }, (results, status) => {
        // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
        // console.log("raw", results);
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const newResults = results.map((result) => ({
            name: result.name,
            address: result.formatted_address,
            coords: {
              latitude: result.geometry?.location?.lat() || null,
              longitude: result.geometry?.location?.lng() || null,
            },
            googlePlaceId: result.place_id,
          }));
          resolve(newResults);
        }
        reject(null);
      });
    });

  }

  function getPlacesId(location) {
    return new Promise(async (resolve, reject) => {
      searchService.current.textSearch({
        query: location.name
      }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && result[0]) {
          resolve(result[0].place_id);
        }
        reject(null);
      });
    });
  }

  function getParsedLocationDetails(location) {
    return new Promise(async (resolve, reject) => {
      searchService.current.getDetails({
        placeId: location.googlePlaceId
      }, (result, status) => {
        // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
        location.parsedAddress = {};
        // console.log("getParsedLocationDetails", result, location);
        if (status === window.google.maps.places.PlacesServiceStatus.OK && result.address_components) {
          location.name = result.name;
          location.address = result.formatted_address;
          result.address_components.forEach((c) => {
            // Address components are inconsistent so we must prioritize which ones matter to us
            if (c.types.indexOf('country') !== -1) {
              location.parsedAddress['country'] = c.long_name;
            } else if (c.types.indexOf('postal_code') !== -1) {
              location.parsedAddress['postcode'] = c.long_name;
            } else if (c.types.indexOf('administrative_area_level_1') !== -1) {
              location.parsedAddress['region'] = c.long_name;
            } else if (c.types.indexOf('locality') !== -1) {
              location.parsedAddress['place'] = c.long_name;
            } else if (c.types.indexOf('administrative_area_level_3') !== -1) {
              location.parsedAddress['place'] = c.long_name;
            } else if (c.types.indexOf('administrative_area_level_2') !== -1) {
              location.parsedAddress['place'] = c.long_name;
            }
          });
          resolve(location);
        }
        reject(null);
      });
    });
  }

  return (
    <GoogleMapsContext.Provider value={{ searchService, map, getGMPlacesLocation, getGMPlacesResults }}>
      {children}
      <div id="gmaps"></div>
    </GoogleMapsContext.Provider>
  )
}

export { GoogleMapsContext, GoogleMapsProvider }