import { useEffect, useState } from "react";

import DirectionsCardRowLeftColumn from "components/common/DirectionsCardRowLeftColumn"
import lokalise from "utils/lokalise";
import styled from "styled-components";

const Wrapper = styled.div`
flex: 0 1 60px;
background: var(--cardBackground);
border-radius: 0.6em;
box-shadow: 0 0.2em 0.4em var(--cardBoxShadow);
padding: 0.5em;
margin: 0.5em 0.5em 2em 0.5em;
`;

const Header = styled.h3`
    padding: 1em 0 0 0.8em;
    font-size: 1.1em;
`;

const Row = styled.div`
    display: flex;
    .main-text {

    }
    .distance {
        font-size: 0.8em;
    }
    .address {
        font-size: 0.8em;
    }
    
`;

export default function DirectionsCard({ googleMapsRouting, card, walkMinutes }) {

    const [leg, setLeg] = useState(null);

    useEffect(() => {
        // Only GMaps contains additional leg data (this is not a Trip Plan, it is Card Screen)
        if (googleMapsRouting && googleMapsRouting.routes && googleMapsRouting.routes[0] && googleMapsRouting.routes[0].legs) {
            setLeg(googleMapsRouting.routes[0].legs[0]);
        }
    }, [googleMapsRouting]);

    if (card?.category === null || card?.agency?.mode === 'floating') return null;

    let destination = card?.stopName;
    if (!destination && card) {
        console.log(card);
        let vehicle = '';
        if (card.agency.mode.match(/bikeshare|dockless-bikeshare|ebikeshare/)) vehicle = lokalise.getKey('ts4i', null, 'one');
        if (card.agency.mode.match(/scootershare|sit-scootershare/)) vehicle = lokalise.getKey('ts4h', null, 'one');
        if (card.agency.mode.match(/carshare|pointtopoint/)) vehicle = lokalise.getKey('ts4g', null, 'one');
        if (card.agency.mode.match(/parking/)) vehicle = lokalise.getKey('ts9b', null, 'one');
        destination = lokalise.getKey('route_nearest_vehicle', { vehicle });
    }

    return (
        <>
            <Header>{lokalise.getKey('tripplan_what_to_expect')}</Header>
            <Wrapper>
                <Row>
                    <DirectionsCardRowLeftColumn modeIcon="walk"></DirectionsCardRowLeftColumn>
                    <div className="right-column">
                        <strong className="main-text">
                            {
                                lokalise.getKey('tripplan_time_verb_to_dest', {
                                    time_verb: walkMinutes || lokalise.getKey('tripplan_mode_walk'),
                                    destination
                                })
                            }
                        </strong>
                        {' '}
                        <span className="distance">({leg && leg?.distance?.text})</span><br />
                        <span className="address">{leg && leg?.end_address}</span>
                    </div>
                </Row>
            </Wrapper>
        </>
    )
}

