import Icon from 'components/Icon';
import LocationSearchField from 'components/LocationSearchField/LocationSearchField';
import config from 'config';
import googlemaps from 'utils/googlemaps';
import lokalise from 'utils/lokalise';
import mapbox from 'utils/mapbox';
import { useState } from 'react';

function LocationWaypoint({
    icon,
    label,
    locked,
    location,
    placeholder,

    searchAreaLocation,
    updateLocation,
}) {
    // console.log("LocationWaypoint", location);

    const [statusMessage, setStatusMessage] = useState(null);

    // newLocation is our formatted object seen in GoogleMapsPlacesSearcher
    function setLocation(newLocation) {
        // console.log("setLocation newLocation", newLocation);
        if (newLocation) {
            // We redundantly double check prop passing here 
            updateLocation({
                coords: {
                    // Very important to ensure the lat and long are number types here
                    latitude: parseFloat(newLocation.coords.latitude.toFixed(config.searchMaxCoordinateDecimals)),
                    longitude: parseFloat(newLocation.coords.longitude.toFixed(config.searchMaxCoordinateDecimals)),
                },
                name: newLocation.name,
                address: newLocation.address,
                parsedAddress: newLocation.parsedAddress
            });
        }
    }

    function setUserPosition() {
        setStatusMessage(lokalise.getKey("ts12m"));
        window.navigator.geolocation.getCurrentPosition((position) => {
            setTimeout(() => {
                setStatusMessage(null);
            }, 250);
            if (position) {
                updateLocation({
                    name: `${position.coords.latitude},${position.coords.longitude}`,
                    type: 'usergeolocation', // Used for doing reverse geocoding later
                    address: '',
                    coords: position.coords
                });
            }
        }, (e) => {
            setStatusMessage(lokalise.getKey("ts12n"));
            setTimeout(() => {
                setStatusMessage(null);
            }, 800);
        });
    }

    return (
        <div className="LocationWaypoint">

            <div className="location-icon">
                <Icon category="ui" name={icon} />
            </div>

            {locked
                ? <div className="location-label">
                    {label}
                </div>
                : null
            }

            <div className="location-name">
                <div className={`status ${statusMessage ? 'active' : ''}`}>{statusMessage}</div>
                <LocationSearchField
                    locked={locked}
                    setLocation={setLocation}
                    placeholder={placeholder}
                    location={location}
                    searchAreaLocation={searchAreaLocation} 
                    setUserPosition={setUserPosition}
                />
                {/* {location && location.name + location.address + location.coords.latitude + location.coords.longitude} */}
            </div>
        </div>
    )
}

export default LocationWaypoint
