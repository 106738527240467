/* eslint-disable no-unused-expressions */
/* global google */

import config from 'config';

export default {

	getGoogleWalkDirections(originLatitude, originLongitude, destinationLatitude, destinationLongitude, callback) {
		const DirectionsService = new google.maps.DirectionsService();
		DirectionsService.route({
			origin: new google.maps.LatLng(originLatitude, originLongitude),
			destination: new google.maps.LatLng(destinationLatitude, destinationLongitude),
			travelMode: google.maps.TravelMode.WALKING,
		}, (result, status) => {
			if (status === 'OK') {
				const waypointCoordinates = this.getPolylinePath(result.routes[0].overview_path);
				// const waypointCoordinates = this.getDecodedPolyline(result.routes[0].overview_polyline);
				// console.log("getGoogleWalkDirections", waypointCoordinates, result);
				callback({ result, waypointCoordinates });
			}
		});
	},

	getPolylinePath(paths) {
		return paths.map((path) => [path.lng(), path.lat()]);
	},

	getUserPosition(appLocation, screen) {
		let latitude = null;
		let longitude = null;
		if (screen && screen.coordinates.latitude && screen.coordinates.longitude) {
			latitude = screen.coordinates.latitude;
			longitude = screen.coordinates.longitude;
		} else if (appLocation && appLocation.coords) {
			latitude = appLocation.coords.latitude;
			longitude = appLocation.coords.longitude;
		}

		if (!latitude && !longitude) return null;

		return {
			latitude: parseFloat(latitude),
			longitude: parseFloat(longitude),
		};
	},

	getMapboxBounds(positions) {
		const nsewBounds = {
			NW: {
				lat: null,
				lng: null
			},
			SW: {
				lat: null,
				lng: null
			},
			SE: {
				lat: null,
				lng: null
			},
			NE: {
				lat: null,
				lng: null
			},
		};

		function evalBound(position) {
			// the coordinate with the largest
			// lat and smallest lng is the most NW?
			if (position[1] > nsewBounds.NW.lat || !nsewBounds.NW.lat) {
				nsewBounds.NW.lat = position[1];
			}
			if (position[0] < nsewBounds.NW.lng || !nsewBounds.NW.lng) {
				nsewBounds.NW.lng = position[0];
			}

			// the largest lat and largest
			// lng is the NE corner?
			if (position[1] > nsewBounds.NE.lat || !nsewBounds.NE.lat) {
				nsewBounds.NE.lat = position[1];
			}
			if (position[0] > nsewBounds.NE.lng || !nsewBounds.NE.lng) {
				nsewBounds.NE.lng = position[0];
			}

			// the smallest lat and smallest
			// lng is the SW corner?
			if (position[1] < nsewBounds.SW.lat || !nsewBounds.SW.lat) {
				nsewBounds.SW.lat = position[1];
			}
			if (position[0] < nsewBounds.SW.lng || !nsewBounds.SW.lng) {
				nsewBounds.SW.lng = position[0];
			}

			// the smallest lat and largest
			// lng is the SE corner?
			if (position[0] > nsewBounds.SE.lng || !nsewBounds.SE.lng) {
				nsewBounds.SE.lng = position[0];
			}
			if (position[1] < nsewBounds.SE.lat || !nsewBounds.SE.lat) {
				nsewBounds.SE.lat = position[1];
			}
		}

		// console.log(positions);
		positions.forEach((position) => evalBound(position));

		return [[nsewBounds.SW.lng, nsewBounds.SW.lat], [nsewBounds.NE.lng, nsewBounds.NE.lat]];
	},

	/*
	 *
	 */
	getCardStopMarkerData(card, screen) {
		if (card.category === 'masstransit') {
			let latitude;
			let longitude;
			let isExact;
			if (card.coordinates && card.coordinates.latitude && card.coordinates.longitude) {
				latitude = card.coordinates.latitude;
				longitude = card.coordinates.longitude;
				isExact = true;
			} else if (screen && screen.coordinates.latitude && screen.coordinates.longitude) {
				latitude = screen.coordinates.latitude;
				longitude = screen.coordinates.longitude;
				isExact = false;
			}
			return [{
				isExact,
				bgColor: card.agency.style.brandColor,
				mode: card.agency.mode,
				logo: card.agency.style.logoSvgOnlight, 
				latitude: parseFloat(latitude),
				longitude: parseFloat(longitude),
			}];
		}

		if (card.category === 'docked' || card.category === 'dockless') {
			// console.log("Docked or Dockless Card Map", card);
			if (card.locations && card.locations.length > 0) {
				return card.locations.map(
					(location) => {
						const agency = card.category === 'dockless' && card.agencies && card.agencies[location.agencyId] || card.agency;
						return {
							bgColor: agency.style.brandColor,
							logo: agency.style.logoSvgOnlight,
							latitude: parseFloat(location.coordinates.latitude),
							longitude: parseFloat(location.coordinates.longitude),
							dock: location.dock,
							isExact: true
						};
					},
				);
			}
		}

		if (card.type === 'parking' && card.parking) {
			return card.parking.map((garage) => {
				const agency = card.agency;
				return {
					isExact: true,
					bgColor: agency.style.brandColor,
					logo: agency.style.logoSvgOnlight,
					mode: agency.mode,
					latitude: parseFloat(garage.coordinates.latitude),
					longitude: parseFloat(garage.coordinates.longitude),
				};
			});
		}

		return [];
	},

	/*
	 * Deprecated Fetch method of obtaining coordinates
	 */
	fetchGoogleWalkDirections(apiKey, originLatitude, originLongitude, destinationLatitude, destinationLongitude) {
		const origin = `${originLatitude},${originLongitude}`;
		const destination = `${destinationLatitude},${destinationLongitude}`;
		const mode = 'walking';
		const url = `${config.googleDirectionsEndpoint}?origin=${origin}&destination=${destination}&key=${apiKey}&mode=${mode}`;
		return fetch(url)
			.then((response) => response.json())
			.then((responseJson) => {
				// console.log('Get Directions!', origin, destination);
				const result = {};
				if (responseJson.routes.length) {
					const coords = this.getDecodedPolyline(responseJson.routes[0].overview_polyline.points);
					result.coords = coords;
					if (responseJson.routes[0].legs.length) {
						const walkTime = responseJson.routes[0].legs[0].duration.value;
						result.walkTime = Math.ceil((~~walkTime / 60));
					}
					return result;
				}
				return false;
			}).catch((e) =>
				// console.log('GMaps Directions Failure ', e);
				false);
	},

	/*
	 * Google Directions outputs a special kind of polyline that must be translated into coordinates
	 * We can use overview_path now, but this may be needed in the future
	 * Taken from https://stackoverflow.com/questions/15924834/decoding-polyline-with-new-google-maps-api
	 * @return out array of {latitude,longitude} objects
	 */
	getDecodedPolyline(encoded) {
		if (!encoded) {
			return [];
		}
		const poly = [];
		let index = 0;
		const len = encoded.length;
		let lat = 0;
		let lng = 0;

		while (index < len) {
			let b;
			let shift = 0;
			let result = 0;

			do {
				b = encoded.charCodeAt(index++) - 63;
				result |= ((b & 0x1f) << shift);
				shift += 5;
			} while (b >= 0x20);

			const dlat = (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);
			lat += dlat;

			shift = 0;
			result = 0;

			do {
				b = encoded.charCodeAt(index++) - 63;
				result |= ((b & 0x1f) << shift);
				shift += 5;
			} while (b >= 0x20);

			const dlng = (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);
			lng += dlng;

			const p = [
				lng / 1e5, // longitude
				lat / 1e5, // latitude
			];
			poly.push(p);
		}
		return poly;
	},
};
