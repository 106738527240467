import Icon from 'components/Icon';
import RoutingIcons from '../TripPlanScreen/RoutingIcons';
import lokalise from 'utils/lokalise';
import tripscreen from 'utils/tripscreen';

// @method selectTripOption tells the parent to choose this one
// @param category object { label: "Best Fit", option: {name: 'mode name', ... }}
function TripOption({ tripOption, selectTripOption }) {
    if (!tripOption || typeof tripOption !== 'object' || tripOption.name === 'missing') return null;

    // console.log(tripOption);

    const title = tripOption.name;
    const className = title && title.toString().toLowerCase().replaceAll(' ', '-') || '';

    return (
        <div className={`TripOption ${className} ${tripOption.recommended ? 'recommended' : ''}`}>

            {tripOption.recommended && <div className="recommended-flag">{lokalise.getKey('tripplan_recommended')} <Icon category="ui" name="recommended" /></div>}

            <button style={{ pointerEvents: !selectTripOption ? 'none' : '' }} disabled={!tripOption.legs ? true : false} onClick={() => { selectTripOption && selectTripOption(tripOption) }}>

                <div className="option-icons">
                    <div className="option-icons--mini-trip">
                        <RoutingIcons trip={tripOption} />
                    </div>

                    <div className="option-icons--time">
                        <RouteDuration trip={tripOption} />
                    </div>

                </div>

                {/* <Icon className="open-new-icon" category="ui" name="openNew" color="#ccc" /> */}
            </button>
        </div>
    )
}

function RouteDuration({ trip }) {
    const duration = trip?.scores?.duration;
    return tripscreen.getDuration(duration);
}


export default TripOption
