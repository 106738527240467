import React, {
	Component,
	useRef,
	useEffect,
} from 'react';

import Icon from 'components/Icon';
import lokalise from 'utils/lokalise';


class LocationTitle extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			screen,
			address,
			toggleLocation,
			menuType,
			menuOpen,
			locationInput,
			handleChange,
			appLocation,
		} = this.props;

		return (
			<div className="LocationTitle">
				{menuType === 'anywhere' ? <LocationInput menuOpen={menuOpen} handleChange={handleChange} locationInput={locationInput} /> : null}
				<StaticLocationName menuType={menuType} menuOpen={menuOpen} address={address} screen={screen} appLocation={appLocation} />
			</div>
		);
	};
}

/*
 * We treat text input as throwaway and dont store state, it merely is sent to the search results
 */
function LocationInput(props) {
	const {
		menuOpen,
		handleChange,
	} = props;

	const textInput = useRef(null);

	useEffect(() => {
		if (textInput.current) {
			if (menuOpen) {
				// This doesnt work for Mobile Safari, see original click action in
				textInput.current.focus();
			} else {
				textInput.current.value = '';
				textInput.current.blur();
			}
		}
	});

	return (
		<input
			role="search"
			style={{ display: menuOpen ? 'block' : 'none' }}
			ref={textInput}
			className="LocationInput"
			//The word placemark doesn't get translated in lokalize
			placeholder={lokalise.getKey("ts12l")}
			type="text"
			id="SearchInput"
			onChange={handleChange}
		/>
	);
}

function StaticLocationName(props) {
	const {
		screen,
		address,
		appLocation,
		menuOpen,
		menuType,
	} = props;

	let title = '';
	if (appLocation && appLocation.name) {
		title = appLocation.name;
	} else if (address) {
		// Anywhere geocode
		title = address;
	} else if (screen) {
		const {
			prettyName,
			address
		} = screen;
		title = prettyName ? prettyName : address;
	}

	let usedCoords = false;
	if (!title) {
		let coords = appLocation && appLocation.coords ? `${appLocation.coords.latitude.toFixed(5)}, ${appLocation.coords.longitude.toFixed(5)}` : '';
		title = `${coords}`;
		usedCoords = true;
	}

	const style = { display: (menuOpen && menuType === 'anywhere') ? 'none' : 'block' };

	const voTitle = usedCoords ? `Coordinates ${title}` : title;
	const voiceoverTitleText = `Showing transportation choices at ${voTitle}, double tap to open location search`;

	return (
		<div className="StaticLocationName" style={style}>
			<span className="voiceover-text voiceover-fill">
				<h1 style={{ fontSize: '2em' }}>{voiceoverTitleText}</h1>
			</span>
			<h1 aria-hidden="true" className="heading">
				<Icon className="location-icon" category="ui" name="mapLocation" />
				<span aria-hidden="true">
					<span className="location-name">{title}</span>
				</span>
			</h1>
		</div>
	);
}

export default LocationTitle;
