module.exports = {
    /*
     * @param arr - Array of vehicles
     * @param attrs - Array of property attributes to sort on, recursive
     * Will allow fallback to single string value
     */
    sortArray: function (arr, attrs) {
        const module = this;


        if (!attrs || !attrs.length) return arr;

        let sortAttrs = attrs;
        if (!Array.isArray(attrs)) sortAttrs = [attrs];

        sortAttrs.forEach(function (sortAttribute) {
            let sortProperty = module.snakeToCamel(sortAttribute);
            if (sortProperty === 'prediction') sortProperty = 'predictionMinutesAway';
            
            // JS sort is mutable
            arr.sort(function (a, b) {
                // Compare requested attr of first item in array
                if (a[0][sortProperty] > b[0][sortProperty]) {
                    return 1;
                }
                if (a[0][sortProperty] < b[0][sortProperty]) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        })
        return arr;
    },

    snakeToCamel: function (str) {
        return str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );
    },

    /*
     * @param collection - Object of props (usually vehicles/items)
     * @param rowLimit - Integer to reduce collection props to
     */
    limitObjectArray: function (collection, rowLimit) {
        if (rowLimit && rowLimit <= 0) {
            var newRows = {};
            for (var k in collection) {
                // Recreate row object by counting down with rowlimit
                // This is because Object.keys not supported
                if (rowLimit !== 0) {
                    newRows[k] = collection[k];
                    rowLimit--;
                }
            }
            return newRows;
        } else {
            return collection;
        }
    }

}