import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import React from 'react';
import Tappable from 'components/common/Tappable';
import { allowCardTapOpen } from 'utils/params';

// All transit, bike, car, etc cards
export default function TransportationCard(props) {

	const {
		card,
		params,
		opened,
		tapOpenCardView, // toggles card screen with other state things
		showCardScreen, // used in the child components
	} = props;

	// console.log(card);

	// @TODO Update UI Card Visibility at this level if it affects Footer and Header (right now in CardRows)
	const visibleClassName = card.visible ? 'visible' : 'hidden';
	const openedClassName = opened ? 'opened' : 'closed';
	const hasMaps = params && params.maps && params.maps === "true";
	const modeClassName = card.agency && card.agency.mode ? card.agency.mode : '';

	return (
		<div className={`Content ${openedClassName} ${visibleClassName} ${'mode-' + modeClassName}`}>

			{allowCardTapOpen ? (
				<Tappable className="OpenCardTapArea" pressDelay={1500} onTap={tapOpenCardView} />
			) : null}

			<CardHeader hasMaps={hasMaps} {...props} />
			<CardBody hasMaps={hasMaps} {...props} />
			<CardFooter hasMaps={hasMaps} {...props} />
		</div>
	);
}
