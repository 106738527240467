import Map, { Marker } from 'react-map-gl';

import Icon from 'components/Icon.js';
import React from 'react';

export default function StopMarkers(props) {
	const { stopMarkerPositions } = props;

	function getStopMarkers() {
		if (stopMarkerPositions) {
			return stopMarkerPositions.map((marker, i) => (
				<StopMarker
					key={i}
					logo={marker.logo}
					bgColor={marker.bgColor}
					longitude={marker.longitude}
					latitude={marker.latitude}
					dock={marker.dock}
					mode={marker.mode}
					isExact={marker.isExact}
				/>
			));
		}
		return null;
	}

	return getStopMarkers();
}

function StopMarker({ isExact, longitude, latitude, logo, bgColor, dock, mode }) {

	if (!longitude || !latitude) return null;

	return (
		<Marker longitude={longitude} latitude={latitude} anchor="bottom">

			<div className="StopMarker">
				{isExact === true ?
					<>
						<Head dock={dock} mode={mode} logo={logo} bgColor={bgColor} />
						<Pin bgColor={bgColor} />
					</> :
					<div className="Notice">Stop location is nearby</div>
				}
			</div>

		</Marker>
	)
}



function Head(props) {
	const { dock, bgColor, logo, mode } = props;
	let agencyLogo = <Icon category="modes" name={mode} />;
	// if (logo) {
	// 	agencyLogo = <img className="AgencyLogo" src={logo} alt="" />;
	// }
	return (
		<div className="Head" style={{ background: bgColor }}>
			<div className="Logo">{agencyLogo}</div>
			{dock ? <span className="VehicleCount">{dock.vehicleCount}</span> : null}
		</div>
	);
}

function Pin(props) {
	return (
		<svg className="Pin" width="100%" height="90px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M 100 100 L 300 100 L 200 300 z"
				fill={props.bgColor}
				stroke="#222"
				strokeWidth="0"
			/>
		</svg>
	);
}
