import ButtonGroup from 'components/common/ButtonGroup';
import ColorLibrary from 'components/common/ColorLibrary';
import googlemaps from 'utils/googlemaps';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export default function CardScreenFooter({ googleMapsRouting }) {

  let buttons = [
    {
      text: "Walk to station / stop",
      iconCategory: "ui",
      iconName: "play",
      onClick: () => {
        const url = googlemaps.getGMHomeURL();
        window.open(url, "new");
      }
    },
    {
      text: "Build a trip plan",
      iconCategory: "ui",
      iconName: "compass",
      buttonColor: ColorLibrary.afBlueberry,
      buttonText: ColorLibrary.afOffWhite,
      onClick: () => {
        const url = googlemaps.getGMHomeURL();
        window.open(url, "new");
      }
    }
  ];

  // Most routings are unbroken single routings over land on foot
  if (googleMapsRouting?.routes[0]?.legs[0]) {
    const leg = googleMapsRouting.routes[0].legs[0];
    buttons[0].onClick = () => {
      const url = googlemaps.getGMDirectionsURL({
        startingLocation: { address: leg.start_address },
        endingLocation: { address: leg.end_address },
        travelMode: "walking"
      })
      window.open(url, "new");
    };
    buttons[1].onClick = () => {
      const url = googlemaps.getGMTripStarterURL({
        startingLocation: { address: leg.start_address },
        endingLocation: { address: leg.end_address },
        travelMode: "walking"
      })
      window.open(url, "new");
    };
  }


  return (
    <Wrapper>
      <ButtonGroup
        buttons={buttons}
      />
    </Wrapper>
  )
}
