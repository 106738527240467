import PredictionMinutesAway from 'components/Card/PredictionMinutesAway.js';
import PredictionFrequency from 'components/Card/PredictionFrequency.js';
import PredictionString from 'components/Card/PredictionString.js';

function MassTransitPredictions(props) {
	const {
		rowData,
		vehicleGroup,
		timeDisplay
	} = props;

	const {
		predictionMinutesAway,
		predictionFrequency,
		predictionString
	} = rowData;

	let component = '--';

	if (predictionMinutesAway !== null) {
		component = <PredictionMinutesAway vehicleGroup={vehicleGroup} predictionMinutesAway={predictionMinutesAway} timeDisplay={timeDisplay}/>;
	} else if (predictionFrequency !== null) {
		component = <PredictionFrequency predictionFrequency={predictionFrequency} />;
	} else if (predictionString !== null) {
		component = <PredictionString predictionString={predictionString} />;
	}

	return <div className={`MassTransitPredictions`}>{component}</div>;
}

export default MassTransitPredictions;
