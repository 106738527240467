import React, {
	Component
} from 'react';

import Card from 'components/Card/Card';
import config from 'config';

function CardList(props) {

	const {
		cards
	} = props;

	/*
	* We need to always check if card is valid because of API and the various transforms
	*/
	function getCards() {
		return cards.map((card, i) => {
			// console.log("CARD", card);
			if (card) {
				const cardId = card.id || i;
				return (
					<Card
						key={cardId}
						index={i}
						card={card}
						{...props}
					/>
				);
			}
			return null;
		});
	}

	return (
		<div aria-label="List of transportation options" className="CardList">
			{cards && cards.length > 0 ? getCards() : null}
		</div>
	);
}

export default CardList;
