import React, { memo, useRef } from "react";

export default function CardTable({ className, headers, rows }) {
	return (
		<div className={`CardTable ${className}`}>
			<table>
				<thead>
					<tr>
						{headers?.map((header, i) => (
							<th key={header || i}>{header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows?.map((row, i) => (
						<CardTableRow key={row.key || i} row={row} />
					))}
				</tbody>
			</table>
		</div>
	);
}

function CardTableRow({ row }) {
	const popupElement = useRef(null);

	const toggleExpand = (e) => {
		e.preventDefault();
		if (popupElement.current) {
			if (popupElement.current.classList.contains("active")) {
				popupElement.current.classList.remove("active");
			} else {
				popupElement.current.classList.add("active");
			}
		}
	};

	return (
		<>
			<tr onClick={row.expandedContent ? toggleExpand : null}>
				{row.cells.map((cell, i) => (
					<CardTableCell
						key={cell.className || i}
						className={cell.className || ""}
						content={cell.content}
						breakWord={cell.breakWord}
					/>
				))}
			</tr>
			{row.expandedContent && (
				<CardTableRowExpanded
					expandedContent={row.expandedContent}
					popupRef={popupElement}
				/>
			)}
		</>
	);
}

const CardTableCell = memo(function CardTableCell({
	className,
	content,
	breakWord,
}) {
	// "-" character will break line with css
	return (
		<td className={className}>
			{breakWord ? content.replace(breakWord, "-") : content}
		</td>
	);
});

const CardTableRowExpanded = memo(function CardTableRowExpanded({
	expandedContent,
	popupRef,
}) {
	return (
		<tr ref={popupRef} className="CardTableRowExpanded">
			<td colSpan="100%">
				<div>{expandedContent}</div>
			</td>
		</tr>
	);
});
