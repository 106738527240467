import { Layer, Marker, Source } from 'react-map-gl';
import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import config from 'config';
import tripscreen from 'utils/tripscreen';

// @TODO this should be URLparams context, router or something
const searchParams = new URLSearchParams(window.location.search || document.location.search);
const mapColors = searchParams.get("ui") === 'dark' ? config.mapColorSchemes.dark : config.mapColorSchemes.light;

function TripPlanMapUI({ setMapBounds, startingLocation, endingLocation, tripPlan, showTripPlanScreen }) {
    if (!startingLocation || !endingLocation || !tripPlan) return null;

    return (
        <>
            <Marker color="#0000FF" longitude={startingLocation.coords.longitude} latitude={startingLocation.coords.latitude} anchor="center">
                <Icon category="ui" name="mapStart" />
            </Marker>
            <Marker color="#555" longitude={endingLocation.coords.longitude} latitude={endingLocation.coords.latitude} anchor="center">
                <Icon category="ui" name="mapEnd" />
            </Marker>

            {/* <MapPath
                coordinates={[[startingLocation.coords.longitude, startingLocation.coords.latitude], [endingLocation.coords.longitude, endingLocation.coords.latitude]]}
            /> */}

            <MapPaths setMapBounds={setMapBounds} showTripPlanScreen={showTripPlanScreen} tripPlan={tripPlan} />

        </>
    )
}

function MapPaths({ setMapBounds, showTripPlanScreen, tripPlan }) {

    // Architecture Note: We cannot easily re-use Source/Layer comps so it all gets demounted 
    // when the user closes trip plan screen
    if (!showTripPlanScreen) return null;
    if (tripPlan.legs) {
        return (<MapSourceLayerGroup legs={tripPlan.legs} />);
    }
    // if (config.routingBackend.tripModesWithLegs.indexOf(modeQueryName) !== -1) {
    //     const legs = tripPlan.legs; // @TODO might not be the path anymore
    //     if (legs) {
    //         return (<MapSourceLayerGroup legs={legs} />);
    //     }
    // } 
    // else if (config.routingBackend.tripModesWithGeometry.indexOf(modeQueryName) !== -1) {
    //     const coordinates = tripPlan.legs[0]?.geometry?.coordinates;
    //     if (coordinates) {
    //         return (<MapSourceLayer mode={modeQueryName} coordinates={coordinates} />);
    //     }
    // }

    return null;
}

function MapSourceLayerGroup({ setMapBounds, legs }) {
    let comps = [];
    legs.forEach((leg, i) => {
        const mode = leg.mode.toUpperCase();
        const color = leg?.route?.color;
        comps.push(<MapSourceLayer key={leg.id} color={color} mode={mode} coordinates={leg.geometryCoordinates} />);
    })
    return comps;
}

// @NOTE This component generalizes styling for individual single modes 
function MapSourceLayer({ coordinates, mode, color }) {
    if (!coordinates) return null;
    const sourceData = {
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': 'LineString',
            'coordinates': coordinates
        }
    };

    let layerStyle = {
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': mapColors.default,
            'line-width': 4,
            'line-dasharray': [0, 1],
        },
    };

    let shadowLayerStyle = {}
    if (mode === 'WALK') {
        layerStyle.paint = {
            'line-color': color || mapColors.walk,
            'line-width': 5,
            'line-dasharray': [0, 1],
        }
    } else if (tripscreen.isTransitMode(mode)) {
        layerStyle.paint = {
            'line-color': color || mapColors.transit,
            'line-width': 5,
        };
        shadowLayerStyle = {
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': mapColors.shadow,
                'line-width': 8,
            },
        }
    } else if (mode === 'CAR') {
        layerStyle.paint = {
            'line-color': color || mapColors.driving,
            'line-width': 5,
        }
    } else if (mode === 'BICYCLE' || mode === 'CYCLING') {
        layerStyle.paint = {
            'line-color': color || mapColors.bicycle,
            'line-width': 5,
        }
    }

    const [layer, setLayer] = useState({
        type: 'line',
        ...layerStyle
    });

    // Outlines transit lines only in case the color is too bright to be seen on the map
    const [shadowLayer, setShadowLayer] = useState({
        type: 'line',
        ...shadowLayerStyle
    });

    const [source, setSource] = useState({
        type: 'geojson',
        data: sourceData
    })
    return (
        <Source {...source}>
            <Layer {...shadowLayer} />
            <Layer {...layer} />
        </Source>
    )
}

export default TripPlanMapUI
