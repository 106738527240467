import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import moment from 'moment';

const ActivationCodeGenerator = ({pluginParams, updateShortCode, playlist, slideId, slideUrl, isUpdateInstance}) => {
    const [customerName, setCustomerName] = useState('');
    const [newCustomerName, setNewCustomerName] = useState('');
    const [customerOptions, setCustomerOptions] = useState([])
    const [isExistingCustomer, setIsExistingCustomer] = useState(true);
    const [maxActivations, setMaxActivations] = useState(null);
    const [activationTime, setActivationTime] = useState('');
    const [activationDate, setActivationDate] = useState('');
    const [activateNow, setActivateNow] = useState(true);
    const [deactivationTime, setDeactivationTime] = useState('');
    const [deactivationDate, setDeactivationDate] = useState('');
    const [deactivateExists, setDeactivateExists] = useState(true);
    const [hours, setHours] = useState([]);
    const [shortCode, setShortCode] = useState('');
    const [showShortCode, setShowShortCode] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState({});
    const domain = pluginParams.environment === 'staging.citymotion.io'
    ? 'https://ts-business-core-staging.herokuapp.com'
    : pluginParams.environment === 'citymotion.io'
    ? 'https://biz.actionfigure.ai'
    : 'http://localhost:6060'
    // const domain = 'http://localhost:6060'
    const headers = { headers: { 'X-api-key': process.env.BIZ_API_KEY } };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;        
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();  
        return `${year}-${month}-${day}`
    }

    const formatTime = (time) => {
        //Rounding is for a display purpose as we only show times in the dropdown per 15 minutes
        const roundedMinute = Math.round(time.getMinutes() / 15) * 15;
        let hour = time.getHours() < 10 ? `0${time.getHours()}` : `${time.getHours()}`;
        let minutes = roundedMinute === 0 ? `0${roundedMinute}` : `${roundedMinute}`;

        if (roundedMinute === 60) {
            minutes = '00';
            hour = time.getHours() < 9 ? `0${time.getHours() + 1}` : `${time.getHours() + 1}`;
        }
        return `${hour}:${minutes}`
    }

    useEffect(async () => {
        //Get current customer names in BIZ
        const url = `${domain}/api/customers`;
        const customers = await fetch(url, headers)
            .then(response => response.json())
            .then(data => data)
            .catch(e => {
                console.log("Data Fetching Failed")
            });
        const names = customers?.map(customer => {
            return {value: customer.id, label: customer.name}
        });

        setCustomerOptions(names);

        //Getting all times to populate de/activation times
		const totalOptions = (60 / 15) * 24;
		const timeOptions = [];
		const momentStart = moment('00:00', 'h:mm a');
        const militaryTimeStart = moment('00:00', 'HH:mm')
		for (let i = 0; i < totalOptions; i++) {
			const timeText = i > 0 ? momentStart.add(15, 'minutes') : momentStart;
            const militaryTimeValue = i > 0 ? militaryTimeStart.add(15, 'minutes') : militaryTimeStart;
			timeOptions.push({value: militaryTimeValue.format('HH:mm'), label:timeText.format('h:mm a')});
		}
		setHours(timeOptions);

        //Update values if plugin exists
        if (isUpdateInstance) {

            setShowShortCode(true);

            //Update short code
            setIsExistingCustomer(true);
            setShortCode(updateShortCode);
            //Update customer
            const customer = names.find(name => name.value === playlist?.customerId);
            setCustomerName(customer);

            //Set Max activations
            setMaxActivations(playlist?.activationLimit)

            //Set activation Date and Time
            if (playlist.activationDate) {
                setActivateNow(false)
                const activationDate = new Date(playlist.activationDate);
                setActivationDate(formatDate(activationDate))
                const activationTime = formatTime(activationDate);
                const activationTimeObject = timeOptions.find(time => time.value === activationTime);
                setActivationTime(activationTimeObject)
            }

            //Set Deactivation Date and Time
            if (playlist.deactivationDate) {
                setDeactivateExists(true)
                const deactivationDate = new Date(playlist.deactivationDate);
                setDeactivationDate(formatDate(deactivationDate))
                const deactivationTime = formatTime(deactivationDate);
                const deactivationTimeObject = timeOptions.find(time => time.value === deactivationTime);
                setDeactivationTime(deactivationTimeObject)
            }
        }
        else {
            //Generate random short code
            //TODO: Make sure we don't get duplicates
            setShortCode((Math.random() + 1).toString(36).substring(2,7));
        }
        
    }, [domain, playlist]) //Reload BIZ if domain changes or a new playlist is retrieved

    const changeState = (value, updateState) => {
        updateState(value);
    }

    const evaluateError = () => {
        //No customer selected on dropdown
        if (isExistingCustomer && !customerName) {
            setError(true);
            return true
        }
        //No customer input
        if (!isExistingCustomer && !newCustomerName) {
            setError(true);
            return true
        }
        //No activation date
        if (!activateNow && (!activationDate || !activationTime)) {
            setError(true);
            return true
        }
        //No deactivation date
        if (deactivateExists && (!deactivationDate || !deactivationTime)) {
            setError(true);
            return true
        }
        return false;
    }

    const submitInput = async () => {
        if (evaluateError()) {
            return;
        }
        setError(false);
        setShowShortCode(true);

        let activationTimestamp;
        let deactivationTimestamp;
        if (!activateNow) {
            activationTimestamp = new Date(activationDate)
            activationTimestamp.setDate(activationTimestamp.getDate() + 1);
            const activationHour = activationTime.value.split(':')[0];
            const activationMinute = activationTime.value.split(':')[1];
            activationTimestamp.setHours(activationHour);
            activationTimestamp.setMinutes(activationMinute);
        }

        if (deactivateExists) {
            deactivationTimestamp = new Date(deactivationDate)
            deactivationTimestamp.setDate(deactivationTimestamp.getDate() + 1);
            const deactivationHour = deactivationTime.value.split(':')[0];
            const deactivationMinute = deactivationTime.value.split(':')[1];
            deactivationTimestamp.setHours(deactivationHour);
            deactivationTimestamp.setMinutes(deactivationMinute);
        }

        for (const [key, value] of Object.entries(pluginParams)) {
            if (value === 'true') {
                pluginParams[key] = true
            }
            else if (value === 'false') {
                pluginParams[key] = false
            }
            else if (value === '') {
                pluginParams[key] = null
            }
        }
        
        const body = {
            id: playlist.id,
            coordinates: pluginParams.coordinates,
            name: isExistingCustomer ? customerName.label : newCustomerName,
            playlistId: playlist?.id,
            playlistName: `${shortCode}-playlist`,
            slideName: `${shortCode}-plugin-slide`,
            customerId: customerName?.value,
            isExistingCustomer: isExistingCustomer,
            activationLimit: maxActivations,
            activationDate: activateNow ? new Date() : activationTimestamp,
            deactivationDate: deactivateExists ? deactivationTimestamp : null,
            shortCode: shortCode,
            slideId,
            slideUrl,
        }

        const url = isUpdateInstance
        ? `${domain}/api/playlists/updatePlaylistWithCustomer`
        : `${domain}/api/playlists/createPlaylistWithCustomer`;

        var postHeaders = JSON.parse(JSON.stringify(headers));
        postHheaders.headers['Content-Type'] = 'application/json';

        await fetch(url, {
            method: 'POST',
            postHeaders,
            body: JSON.stringify(body)
        })
        .then((response) => {
            setResponse(response)
            if (!isUpdateInstance) {
                setShortCode((Math.random() + 1).toString(36).substring(2,7));
            }
            return  response;
        })
        .catch((error) => {
            console.error('Something went wrong:', error);
        });
    }

	return (
        <div className="ActivationCode">
            <hr />
            <h1>Activation Code (Beta)</h1>
            <div className="customerName">
                <p className="pure-u-1-5">Company:</p>
                <div className="customerOptions pure-u-1-2">
                    <div className="activation-container">
                        <input type="radio" checked={isExistingCustomer} name="customer" onChange={() => setIsExistingCustomer(true)} />
                        <span className="radio-button" />
                        <Select  
                            onChange={(customer) => changeState(customer, setCustomerName)}
                            options={customerOptions}
                            value={customerName}
                            style={{border: 'none'}}
                            className={"customerDropdown " + (error && !customerName && isExistingCustomer  ? "error" : null)}
                            isDisabled={!isExistingCustomer}
                        />
                    </div>
                    {
                        error && !customerName && isExistingCustomer 
                        ? <p style={{"color": "#FD4A5C", "marginLeft": "1.5em"}}>Select a customer</p>
                        : <p style={{"visibility": "hidden"}}></p>
                    }
                    <div className="activation-container">
                        <input type="radio" name="customer" checked={!isExistingCustomer} onChange={() => setIsExistingCustomer(false)} />
                        <span className="radio-button" />
                        <input 
                            className={"customerText " + (error && !isExistingCustomer && !newCustomerName ? "error" : null)}
                            placeholder="Create a new customer" 
                            value={newCustomerName} 
                            onChange={(e) => setNewCustomerName(e.target.value)} 
                            disabled={isExistingCustomer}
                        />                        
                    </div>
                    {
                        error && !isExistingCustomer && !newCustomerName
                        ? <p style={{"color": "#FD4A5C", "marginLeft": "1.5em"}}>Create a customer</p>
                        : <></>
                    }
                </div>
            </div>
            <div className="maxActivations">
                <p className="pure-u-1-5">Max activations:</p>
                <input 
                    className="maxActivationText pure-u-1-2" 
                    placeholder="None (not recommended)" 
                    value={maxActivations} 
                    onChange={(e) => setMaxActivations(e.target.value)}
                />
            </div>
            <div className="activationDate">
                <p className="pure-u-1-5">Activation date:</p>
                <div className="activationDateOptions pure-u-1-2">
                    <div className="activation-container">
                        <input type="radio" name="activateDate" checked={activateNow} onChange={() => setActivateNow(true)} />
                        <span className="radio-button" />
                        <p>Now</p>
                    </div>
                    <div className="activation-container">
                        <input type="radio" name="activateDate" checked={!activateNow} onChange={() => setActivateNow(false)} />
                        <span className="radio-button" />
                        <Select  
                            onChange={(time) => changeState(time, setActivationTime)}
                            options={hours}
                            value={activationTime}
                            style={{border: 'none'}}
                            className={"dateDropdown " + (error && !activateNow && !activationTime ? "error" : null) }
                            // className={"dropdown " + (showError ? "error" : null)}
                            isDisabled={activateNow}
                        />
                        <p style={{'margin': '1em 1em'}}>on</p>
                        <input
                            className={"dateSelect " + (error && !activateNow && !activationDate ? "error" : null)}
                            type={'date'}
                            value={activationDate}
                            onChange={(e) => changeState(e.target.value, setActivationDate)}
                            disabled={activateNow}
                        />
                    </div>
                    {
                        error && !activateNow && (!activationDate || !activationTime)
                        ? <p style={{"color": "#FD4A5C", "marginLeft": "1.5em"}}>Select an activation datetime</p>
                        : <></>
                    }
                </div>
            </div>

            <div className="deactivationDate">
                <p className="pure-u-1-5">Deactivation date:</p>
                <div className="deactivationOptions pure-u-1-2">
                    <div className="activation-container">
                        <input type="radio" name="deactivateDate" checked={deactivateExists} onChange={() => setDeactivateExists(true)} />
                        <span className="radio-button" />
                        <Select  
                            onChange={(time) => changeState(time, setDeactivationTime)}
                            options={hours}
                            value={deactivationTime}
                            style={{border: 'none'}}
                            className={"dateDropdown " + (error && deactivateExists && !deactivationTime ? "error" : null) }
                            // className={"dropdown " + (showError ? "error" : null)}
                            isDisabled={!deactivateExists}
                        />
                        <p style={{'margin': '1em 1em'}}>on</p>
                        <input
                            className={"dateSelect " + (error && deactivateExists && !deactivationDate ? "error" : null)}
                            type={'date'}
                            value={deactivationDate}
                            onChange={(e) => changeState(e.target.value, setDeactivationDate)}
                            disabled={!deactivateExists}
                        />                 
                    </div>
                    {
                        error && deactivateExists && (!deactivationDate || !deactivationTime)
                        ? <p style={{"color": "#FD4A5C", "marginLeft": "1.5em"}}>Select a deactivation datetime</p>
                        : <></>
                    }
                    <div className="activation-container">
                        <input type="radio" name="deactivateDate" checked={!deactivateExists} onChange={() => setDeactivateExists(false)} />
                        <span className="radio-button" />
                        <p>Never (not recommended)</p>
                    </div>
                </div>
            </div>
            <div className="submitActivationCode" onClick={submitInput}>
                {
                    isUpdateInstance
                    ? "Update Code"
                    : "Get Code"
                }
            </div>
            {
                response?.status === 201
                ? <p className="message success">Instance created successfully</p>
                : response?.status === 200
                ? <p className="message success">Instance updated successfully</p>
                : response?.status
                ? <p className="message" style={{"color": "#FD4A5C"}}>Could not create instance</p>
                : <p style={{"visibility": "hidden"}}></p>
            }
            <div className="customerUrl">
                <div className="activationCode pure-u-1-4">
                    <p>Code:</p>
                    <h1 style={{"fontSize": '2em', "margin": 0}}>
                        {
                            showShortCode
                            ? shortCode
                            : null
                        }
                    </h1>
                </div>
                <div className="activationUrl pure-u-1-2">
                    <p>Customer facing URL:</p>
                    <input className="urlContainer" value={`https://api.actionfigure.ai/${showShortCode ? shortCode : ''}`} disabled/>
                </div>
                <div className="activationUrl pure-u-1-4">
                    <p style={{"visibility": "hidden"}}>hidden</p>
                    <div className="copyButton">Copy</div>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default ActivationCodeGenerator
