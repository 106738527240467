import React from 'react';
import styled from 'styled-components';

var sanitizeHtml = require('sanitize-html');

const defaultOptions = {
    allowedTags: ['svg', 'path', 'ellipse'],
    allowedAttributes: {
        'svg': ['width', 'height', 'viewbox'],
        'path': ['fill', 'stroke', 'stroke-width', 'd', 'stroke-color', 'fill']
    },
    allowedIframeHostnames: []
};

const createMarkup = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty, {
        ...defaultOptions,
        ...options
    }
    )
});

const Branding = styled.div`
    svg * {
        fill: var(--icons);
    }
`;

export default function IconInlineSVG(props) {
    return <Branding className="Icon" dangerouslySetInnerHTML={createMarkup(props.svg)} />
}
