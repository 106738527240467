import config from "config";

export default {

    getGMDirectionsURL: ({ startingLocation, endingLocation, travelMode = "walking" }) => {
        if (startingLocation && (startingLocation.address || startingLocation.coords) &&
            endingLocation && (endingLocation.address || endingLocation.coords)) {
            const origin = startingLocation.address ? encodeURIComponent(startingLocation.address) : `${startingLocation.coords.latitude},${startingLocation.coords.longitude}`;
            const destination = endingLocation.address ? encodeURIComponent(endingLocation.address) : `${endingLocation.coords.latitude},${endingLocation.coords.longitude}`;
            const url = `${config.googleMapsDirectionsURL}origin=${origin}&destination=${destination}&travelmode=${travelMode}`;
            return url;
        }
        // Default link to just open up the app
        return `${config.googleMapsDirectionsURL}`;
    },

    getGMTripStarterURL: ({ startingLocation, endingLocation }) => {
        if (startingLocation && (startingLocation.address || startingLocation.coords) &&
            endingLocation && (endingLocation.address || endingLocation.coords)) {
            const origin = startingLocation.address ? encodeURIComponent(startingLocation.address) : `${startingLocation.coords.latitude},${startingLocation.coords.longitude}`;
            const destination = endingLocation.address ? encodeURIComponent(endingLocation.address) : `${endingLocation.coords.latitude},${endingLocation.coords.longitude}`;
            const url = `${config.googleMapsDirectionsURL}origin=${origin}&destination=${destination}`;
            return url;
        }
        // Default link to just open up the app
        return `${config.googleMapsDirectionsURL}`;
    },

    getGMHomeURL: () => {
        return `${config.googleMapsHomeURL}`;
    }
}