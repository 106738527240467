import React, {
	Component
} from 'react';

import CardRowCenter from 'components/Card/CardRowCenter.js';
import CardRowLeft from 'components/Card/CardRowLeft.js';
import CardRowRight from 'components/Card/CardRowRight.js';
import ExpandedInfo from 'components/Card/ExpandedInfo.js';
import ExternalAppButton from 'components/Card/ExternalAppButton.js';
import ReactGA from 'react-ga4';
import RouteAlert from 'components/Card/RouteAlert.js';
import Tappable from 'components/common/Tappable';

class CardRow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
		};

		this.cardWasOpened = false;

		this.tapToggleCardRowExpand = this.tapToggleCardRowExpand.bind(this);
		this.getExternalAppButton = this.getExternalAppButton.bind(this);
	}

	componentDidMount() {
		if (this.props.opened && !this.state.isExpanded) {
			this.setState({
				isExpanded: true
			});
		}
	}

	componentDidUpdate() {
		// Permanently keeps rows expanded when card is opened
		// if (this.props.opened && !this.state.isExpanded) {
		// 	this.setState({
		// 		isExpanded: true
		// 	});
		// }

		// Additionally do a one-time post-action to de-expand on card close
		if (this.props.opened) {
			this.cardWasOpened = true;
		} else if (!this.props.opened) {
			if (this.cardWasOpened) {
				this.setState({
					isExpanded: false
				});
			}
			this.cardWasOpened = false
		}
	}

	tapToggleCardRowExpand() {
		ReactGA.event("Row Clicked", {
			category: "Row Clicked",
			action: `${this.state.isExpanded ? 'Row Expanded' : 'Row Compressed'}`,
			agencyId: this.props.card.agencyId,
			stopName: this.props.card.stopName,
			expanded: !this.state.isExpanded
		});
		this.setState({
			isExpanded: !this.state.isExpanded
		});
	}

	getExternalAppButton() {
		const {
			card,
			rowData,
		} = this.props;
		const {
			agency,
			agencies,
			category,
		} = card;

		const {
			externalUrl,
			agencyId,
		} = rowData;

		const rowAgency = agencies ? agencies[agencyId] : agency;

		let component = null;

		if (rowAgency) {
			if (externalUrl) { // API has given us the url already
				component = <ExternalAppButton linkType="GET_VEHICLE" universalLink={externalUrl} agency={rowAgency} />;
			} else if (rowAgency?.app?.appName) { // We will sniff it out of the app info
				component = <ExternalAppButton linkType="GET_VEHICLE" agency={rowAgency} />;
			}
		}
		return component;
	}


	render() {

		// console.log("CardRow", this.props);
		return (
			<div role="row" className="CardRow">

				{
					this.props.card.category === 'masstransit' && this.props.interactive !== 'false'
					? <Tappable className="TapArea" pressDelay={1500} onTap={this.tapToggleCardRowExpand} stopPropagation />
					: <></>
				}

				{this.getExternalAppButton()}

				<div className="RouteInfo">
					<CardRowLeft {...this.props} />
					<CardRowCenter {...this.props} />
					<CardRowRight {...this.props} />
				</div>
				<ExpandedInfo {...this.state} {...this.props} />
				<RouteAlert {...this.state} {...this.props} />
			</div>
		);
	}
}

export default CardRow;
