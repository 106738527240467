import React from 'react';

import ProgressArc from 'react-progress-arc';



export default function Capacity(props) {
	const color = props.color || '#ff0000';
	const eColor = '#E9632C';

	const vehicleCount = parseFloat(props.vehicleCount);
	const electricVehicleCount = parseFloat(props.electricVehicleCount) || 0;
	const spaceCount = parseFloat(props.spaceCount) || 1;

	// Inner width calculated based upon this container width
	const capacityWidth = parseFloat(props.width) || 60;
	const capacityHeight = parseFloat(props.height) || 18;
	const pctCapacity = vehicleCount / (vehicleCount + spaceCount);
	const ePctCapacity = electricVehicleCount / (vehicleCount + spaceCount);


	return (
		<div className="Capacity">
			<ProgressArc
				completed={pctCapacity}
				stroke={color}
				background="#ccc"
				strokeWidth="4"
				diameter="45"
				viewBox="0 0 45 45" />
			<span className="VehicleCount">{vehicleCount}</span>
		</div>
	);
}
