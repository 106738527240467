import React, {
    Component,
} from 'react';

import lokalise from 'utils/lokalise';

class BrowserGeolocation extends Component {

    constructor(props) {
        super(props);
        this.shouldContinueTrackingGeolocation = false;
        this.geolocationWasSetup = false;
        this.receivedGeolocation = this.receivedGeolocation.bind(this);
        this.failedGeolocation = this.failedGeolocation.bind(this);
        this.startBrowserGeolocation = this.startBrowserGeolocation.bind(this);

    }

    // Implicitly loading this component implies that browser based geolocation is wanted
    // We check for authorization of such then
    componentDidMount() {
        this.checkAuth();
    }

    componentDidUpdate() {
        if (!this.geolocationWasSetup) {
            this.geolocationWasSetup = true;
            this.checkAuth();
        }
    }

    componentWillUnmount() {

    }

    checkAuth() {
        const { customer, updateApp } = this.props;

        // @TODO This check should happen upstream not here
        // It determines if the customer has Anywhere access
        if (customer && customer.citymotionWebAccessType === 2) {
            updateApp({
                loading: true,
                status: lokalise.getKey('ts11a'),
            });

            this.startBrowserGeolocation();
        } else {
            updateApp({
                loading: false,
                status: lokalise.getKey('ts11b'),
                statusContactSupport: true,
            })
        }
    }

    // Functional method to process *pre-validated* Geolocation object
    // @params position object - position:coords:latitude,longitude
    receivedGeolocation(position) {
        if (!this.watcher && this.shouldContinueTrackingGeolocation === true) {
            this.watcher = navigator.geolocation.watchPosition(this.receivedGeolocation, this.failedGeolocation);
        }

        // Throttler for moving people
        if (this.throttle) return false;

        this.throttle = true;
        setTimeout(() => {
            this.throttle = false;
        }, 60000);

        // console.log(position);
        this.props.updateApp({
            appLocation: position,
        });
    }

    failedGeolocation(error) {
        console.log('Failed Geolocation: ', error);
        if (error.message.includes('Origin')) {
            this.props.updateApp({
                debug: error.message,
                status: lokalise.getKey('ts4e'), // Cany show anything
                statusInstructions: lokalise.getKey("ts12w"),
                statusContactSupport: true,
                loading: false,
            });
        } else if (error.message.includes('TypeError') || error.message.includes('Bad coordinates')) {
            this.props.updateApp({
                debug: error.message,
                status: lokalise.getKey('ts4e'),
                statusInstructions: lokalise.getKey("ts12v"),
                statusContactSupport: true,
                loading: false,
            });
        } else {
            this.props.updateApp({
                debug: error.message,
                loading: false,
                status: lokalise.getKey('ts4e'),
                statusInstructions: lokalise.getKey('ts2b'), // Location recovery instructions
            });
        }
    }

    startBrowserGeolocation() {
        this.props.updateApp({
            status: lokalise.getKey('ts1b'), // Getting your location
            loading: true,
        });
        this.shouldContinueTrackingGeolocation = true;
        navigator.geolocation.getCurrentPosition(this.receivedGeolocation, this.failedGeolocation, {
            timeout: 30000,
        });
    }

    render() {
        return null;
    }
}

export default BrowserGeolocation;

