import React from 'react';
import lokalise from 'utils/lokalise';

const sanitizeHtml = require('sanitize-html');

// https://www.npmjs.com/package/sanitize-html
const options = {
    allowedTags: ['iframe', 'b', 'span', 'i', 'em', 'strong', 'a', 'h1', 'h2', 'h3', 'table', 'tr', 'td', 'hr'],
    allowedAttributes: {
        a: ['href', 'name', 'target'],
        // We don't currently allow img itself by default, but
        // these attributes would make sense if we did.
        img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
        iframe: ['src', 'style', 'width', 'height', 'allowfullscreen', 'frameborder', 'title']
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    // allowedIframeHostnames: ['kitchen.screenfeed.com']
};

export default function StaticTextCard(props) {
    return (
        <div className="Content">
            <span className="voiceover-text" style={{ fontSize: '1.5em' }}>
                <span className="voiceover-hide"> {lokalise.getKey("ts10w")}</span>
            </span>
            <div className="Text" dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.card.text, options) }} />
        </div>
    );
}
