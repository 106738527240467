import React from 'react';
import Tappable from 'components/common/Tappable';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
	allowedTags: ['a', 'p', 'span', 'h1', 'h2', 'h3', 'b', 'strong', 'i', 'em', 'br'],
	allowedAttributes: {
		'a': ['href', 'target'],
		'span': ['style'],
	},
	allowedIframeHostnames: [],
};

// Converts URLs to a tag links
function allowAgencyLinks(html) {
	// Only allow certain URLs!
	const urlRegex = /(https?:\/\/[^\s]*[mbta|mta][^\s][^<]+)/g;
	return html.replace(urlRegex, function (url) {
		return '<a target="_new" href="' + url + '">' + url + '</a>';
	});
}

function concatLinks(html) {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return html.replace(urlRegex, function (url) {
		return url.slice(0, 30) + '...';
	});
}

function getAgencyLink(html) {
	const urlRegex = /(https?:\/\/[^\s]*[mbta|mta][^\s][^<|.]+)/g;
	const match = html.match(urlRegex);

	function openLink(match) {
		if (match && match[0]) {
	        window.open(match[0], "_new");
        }
	}

	if (match !== null) {
		return (<Tappable style={{display: 'block', marginTop: '1em', padding: '1em', fontSize: '1.2em', border: '1px solid #ccc', textAlign: 'center'}} onTap={() => openLink(match)} stopPropagation>Open Alert Link</Tappable>);
	}
	return null;
}

const createMarkup = (rawText, options) => ({
	__html: sanitizeHtml(
		rawText, {
			...defaultOptions,
			...options,
		},
	),
});

export default function InsertHTML(props) {
	const {
		html,
	} = props;

	// const newHTML = allowAgencyLinks(html);

	const newHTML = concatLinks(html);

	return <span><span dangerouslySetInnerHTML={createMarkup(newHTML)} />{getAgencyLink(html)}</span>;
}
