import React, {
	Component
} from 'react';

import lokalise from 'utils/lokalise';
import moment from 'moment';

class CardRowCenter extends Component {

	// constructor(props) {
	//     super(props);
	// }

	// Handle some instances of unformatted parsers for now, clean up later
	checkDestinationParsing(destination) {
		let uppercaseAgencies = ["actransit"];
		if (uppercaseAgencies.indexOf(this.props.card.agency.slug) !== -1) {
			let words = destination.split(" ");
			words = words.map(word => {
				word = word.toLowerCase();
				word = word.charAt(0).toUpperCase() + word.slice(1);
				if (word === "Bart") word = "BART";
				return word;
			});
			destination = words.join(" ");
		}
		return destination;
	}

	getVOLabel(rowAgency) {
		const {
			rowData,
			card,
		} = this.props;

		const {
			destination,
			name,
		} = rowData;

		const {
			category,
		} = card;

		if (category === 'masstransit') {
			if (destination) {
				return `Destination to ${destination}`;
			}
		}

		if (category === 'dockless' || category === 'docked') {
			return `Located at ${name}`;
		}

		return null;
	}

	render() {
		const {
			rowAgency,
			rowData,
		} = this.props;

		var flexStyle = null;

		let locationName, locationInfo;

		// Describe what this vehicle is (ie: where it is going, what it is called)
		var vehicleDescription = this.checkDestinationParsing(this.props.rowData.destination);

		var vehicleInfo = "";

		if (this.props.rowData.dock) {
			if (this.props.card.agency.mode === "bikeshare") {
				const bikeCount = this.props.rowData.dock.vehicleCount === 1 ? `1 ${lokalise.getKey("ts4i", null, "one")}` : `${this.props.rowData.dock.vehicleCount} ${lokalise.getKey("ts4i", null, "other")}`;
				const spaceCount = this.props.rowData.dock.availableSpaceCount === 1 ? `1 ${lokalise.getKey("ts3m", null, "one")}` : `${this.props.rowData.dock.availableSpaceCount} ${lokalise.getKey("ts3m", null, "other")}`;
				vehicleInfo = `${bikeCount}, ${spaceCount}`;
			} else if (this.props.card.agency.mode === "pointtopoint") {
				vehicleInfo = this.props.rowData.vehicles ? this.props.rowData.vehicles[0].model : null;
			}
		}

		if (this.props.card.category === "masstransit") {
			// via is an array
			if (this.props.rowData.via && this.props.rowData.via[0]) {
				var vehicleVia = this.props.rowData.via[1] ? `${this.props.rowData.via[0]} and ${this.props.rowData.via[1]}` : this.props.rowData.via[0];
			}

			if (this.props.rowData.platform) {
				var vehiclePlatform = `${this.props.rowData.platform}`;
			}

			// Directions can be translated
			var vehicleDirection = this.props.rowData.direction ? lokalise.getReverseKey(this.props.rowData.direction) : null;

			// If description is missing, use vehicleDirection, meant for NYC Subway
			if (!vehicleDescription) {
				vehicleDescription = vehicleDirection;
				vehicleDirection = null;
				vehicleInfo = this.props.rowData.fullRoute;
			}

			// Ultimately never duplicate left and right col content
			if (vehicleInfo === this.props.rowData.shortRoute) {
				vehicleInfo = null;
				flexStyle = {
					flex: "1 1 0"
				};
			}
		}

		if (this.props.card.category === "dockless" || this.props.card.category === "docked" || this.props.card.agency.mode === "pointtopoint") {
			vehicleDescription = this.props.rowData.name;
			flexStyle = {
				flex: "0 1 70%"
			};
		}

		if (this.props.card.type === "parking") {
			locationName = this.props.rowData.name;
			locationInfo = this.props.rowData.isOpenNow ? "Open" : "Closed";
			if (this.props.rowData.nextCloseTime) {
				locationInfo = `Open until ${moment.tz(this.props.rowData.nextCloseTime, this.props.rowData.timezone).format('h:mm a')}`;
			}
			if (!this.props.rowData.isOpenNow && this.props.rowData.nextOpenTime) {
				locationInfo = `Closed. Opens at ${moment.tz(this.props.rowData.nextOpenTime, this.props.rowData.timezone).format('h:mm a')}`;
			}
			flexStyle = {
				flex: "0 1 70%"
			};
		}


		if (this.props.card.agency.mode === "floating") {
			flexStyle = {
				flex: "0 1 20%"
			};
		}

		const voTitle = this.getVOLabel(rowAgency);

		return (
			<div role="cell" className="CardRowCenter" style={flexStyle}>
				<span className="voiceover-text">{voTitle}</span>
				<div className="VehicleText" aria-hidden="true">
					{locationName ? <div className="LocationName">{locationName}</div> : null}
					{locationInfo ? <div className="LocationInfo">{locationInfo}</div> : null}

					{vehicleDirection ? <div className="VehicleDirection">{vehicleDirection}</div> : null}
					<VehicleDescription text={vehicleDescription} />
					{vehicleVia ? <div className="VehicleVia">{lokalise.getKey("ts4y", { cities: vehicleVia })}</div> : null}
					{vehiclePlatform ? <div className="VehiclePlatform">{lokalise.getKey("ts4z", { platformName: vehiclePlatform })}</div> : null}
					{vehicleInfo ? <div className="VehicleInfo">{vehicleInfo}</div> : null}
				</div>

				{this.props.rowData.pricingMultiplier > 1 ? <div className="surge">{this.props.rowData.pricingMultiplier.toFixed(1)}x</div> : null}

			</div>
		);
	}
}

/*
 * Destination
 */
function VehicleDescription(props) {
	const {
		text
	} = props;

	if (!text) return null;

	const fontSize = text.length > 40 ? '0.9em' : '1em';

	return (
		<div className="VehicleDescription" style={{ fontSize }}>
			<p style={{ 'wordWrap': 'break-word' }}>{text}</p>
		</div>
	)
}

export default CardRowCenter;
