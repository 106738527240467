import { ErrorBoundary } from '@sentry/react';
import {
    ReactSVG,
} from 'react-svg';
import iconLibrary from 'img/svg/icon-library.js';
import styled from 'styled-components';

const IconWrapper = styled(ReactSVG)`
    ${({ color }) => color && `
        svg {
            * {
                stroke: ${color} !important;
            }
        }
    `}
    ${({ fillcolor }) => fillcolor && `
        svg {
            * {
                fill: ${fillcolor} !important;
            }
        }
    `}
`;

// @NOTE svgs are not sanitized, some have stylesheets with classes that will override each other

function Icon({ className, color, fillColor, category, name, fallback, url }) {
    if (url) {
        return <ErrorBoundary>
            <IconWrapper
                color={color}
                fillcolor={fillColor}
                className={`Icon ${className || ''}`}
                src={url}
                beforeInjection={(svg) => {
                    svg.removeAttribute('id');
                    svg.innerHTML = svg.innerHTML.replaceAll('st0', 'st0' + name);
                    svg.innerHTML = svg.innerHTML.replaceAll('st1', 'st1' + name);
                    svg.innerHTML = svg.innerHTML.replaceAll('st2', 'st2' + name);
                }} />
        </ErrorBoundary>
    } else if (iconLibrary[category] && iconLibrary[category][name]) {
        return <ErrorBoundary>
            <IconWrapper
                color={color}
                fillcolor={fillColor}
                className={`Icon ${className || ''}`}
                beforeInjection={(svg) => {
                    svg.removeAttribute('id');
                    svg.innerHTML = svg.innerHTML.replaceAll('st0', 'st0' + name);
                    svg.innerHTML = svg.innerHTML.replaceAll('st1', 'st1' + name);
                    svg.innerHTML = svg.innerHTML.replaceAll('st2', 'st2' + name);
                }}
                src={iconLibrary[category][name]} />
        </ErrorBoundary>
    } else if (fallback) {
        return <>{fallback}</>
    }
    return null;
}

export default Icon
