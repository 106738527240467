import { useEffect, useRef } from 'react';

import LoadingAnimation from './common/LoadingAnimation';
import config from 'config';
import lokalise from 'utils/lokalise';

function AppStatus({ statusMessage, showAppStatus, statusInstructions, statusContactSupport, customer, screen, params }) {

	const appStatusEle = useRef();

	useEffect(() => {
		// By default the comp is hidden and then has a fade in-fade-out effect upon showAppStatus
		appStatusEle.current.style.display = 'block';
		setTimeout(() => {
			window.requestAnimationFrame(() => {
				if (appStatusEle?.current) appStatusEle.current.style.opacity = showAppStatus ? 1 : 0;
				if (!showAppStatus) {
					setTimeout(() => {
						if (appStatusEle?.current) appStatusEle.current.style.display = 'none';
					}, 210);
				}
			});
		}, 10);
	}, [showAppStatus])

	const supportMailto = encodeURI(`
		subject=${config.publicAppName} Plugin Support&body=Please describe your problem: \n\n\n\n\n 
		Version: ${config.version} \n URL: ${window.location.href} \n 
		Customer: ${JSON.stringify(customer)} \n
		Screen: ${JSON.stringify(screen)}`);

	const showSupportMessage = !params || !params.key || statusContactSupport;

	return (
		<div ref={appStatusEle} className={`AppStatus`}>
			<div className="container">
				<div className="loading-animation"><LoadingAnimation color={params?.accentColor} stopAnimation={showSupportMessage} /></div>
				<div className="StatusText">{statusMessage}</div>
				<div className="Instructions">{statusInstructions}</div>
				{showSupportMessage ?
					<div className="ContactSupport">{lokalise.getKey("ts12f")} <a href={`mailto:${config.supportEmailAddress}?${supportMailto}`}>{config.supportEmailAddress}</a></div> : null}
			</div>
		</div>
	)
}

export default AppStatus
