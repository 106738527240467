import React, {
	Component
} from 'react';



function VehicleColor(props) {
	const {
		rowData,
		card,
	} = props;

	const {
		category,
		type
	} = card;

	let colorStripe = null;

	if (category === 'masstransit') {
		if (rowData && rowData.routeColor) {
			colorStripe = rowData.routeColor;
		} else {
			// Default to agency color
			if (card.agency && card.agency.style) {
				colorStripe = card.agency.style.brandColor;
			}
		}
	} else if (category === 'docked' || category === 'floating') {
		if (card && card.agency && card.agency.style) {
			colorStripe = card.agency.style.brandColor;
		}
	} else if (type === 'parking') {
		colorStripe = "#ccc";
	}

	if (!colorStripe) return null;

	return (
		<div className="VehicleColor" style={{ backgroundColor: colorStripe }}></div>
	);
}

export default VehicleColor;
