const url = new URL(window.location.href || document.location.search);
const queryParams = new URLSearchParams(url.search);

import config from '../config';

export default {

    getUserRouting: () => {

        return new Promise(async (resolve) => {

            let localUserData = await getLocalUserData();

            // Try a second time to be sure
            if (!localUserData) {
                localUserData = await getLocalUserData();
            }

            const params = getURLParams(['coordinates', 'start', 'end', 'startDate', 'startTime']);

            // We are 
            resolve({
                start: getLocationFromParams(localUserData, params, 'start') || getLocationFromParams(localUserData, params, 'coordinates'),
                end: getLocationFromParams(localUserData, params, 'end'),
                startDate: params['startDate'],
                startTime: params['startTime']
            });
        });

    },

    setUserRouting: (startLocation, endLocation, startDate, startTime) => {
        // console.log("SETUSERROUTING", startLocation, endLocation);
        // Dont set params coords to localstorage 
        // It may be that localstorage does have previous data but couldnt read
        if (startLocation && startLocation.type !== "params") {
            setURLParams('start', startLocation.coords.latitude + ',' + startLocation.coords.longitude);
            setLocalUserData('start', startLocation);
        }
        if (endLocation && endLocation.type !== "params") {
            setURLParams('end', endLocation.coords.latitude + ',' + endLocation.coords.longitude);
            setLocalUserData('end', endLocation);
        }
        if (startDate) {
            setURLParams('startDate', startDate);
            setLocalUserData('startDate', startDate);
        }
        if (startTime) {
            setURLParams('startTime', startTime);
            setLocalUserData('startTime', startTime);
        }
    }
}


function getLocationFromParams(localUserData, params, paramName) {
    if (!params[paramName]) return null;

    const coordString = params[paramName].split(',');

    if (!coordString.length) return null; // Quietly ignore bad coords

    let newLocation = {
        coords: { latitude: parseFloat(coordString[0]), longitude: parseFloat(coordString[1]) },
        name: coordString.toString(),
        type: "params",
    }

    // Ingest existing local data over parameter data
    const existingData = localUserData && localUserData[paramName];
    // console.log("userstore compare existing", localUserData, coordString);
    if (existingData && existingData.coords &&
        parseFloat(existingData.coords.latitude) === parseFloat(coordString[0]) &&
        parseFloat(existingData.coords.longitude) === parseFloat(coordString[1])) {
        newLocation.name = existingData.name;
        newLocation.address = existingData.address;
        newLocation.type = "local";
    }
    return newLocation;
}

function getURLParams(paramArray) {
    let response = {};
    paramArray.forEach((param) => {
        if (queryParams.has(param)) {
            response[param] = queryParams.get(param);
        }
    });
    return response;
}

function setURLParams(name, value) {
    queryParams.set(name, value);

    // Avoids page load
    const newRelativePathQuery = window.location.pathname + '?' + queryParams.toString();
    history.pushState(null, '', newRelativePathQuery);
}

function getLocalUserData() {
    // localStorage sometimes is not available right away so this gives js some time to exec
    return new Promise((resolve) => {
        if (localStorage) {
            const existing = localStorage.getItem(config.localStorageItemKey);
            // console.log("DOES LOCALSTORAGE", localStorage, existing);
            if (existing) {
                const existingJson = JSON.parse(existing);
                // console.log("EXISTING DATA", existingJson);
                if (existingJson) {
                    resolve(existingJson);
                }
            }
        }
        resolve(null);
    }, 100);
}

function setLocalUserData(key, value) {
    if (localStorage) {

        let newItem = {};

        const existing = localStorage.getItem(config.localStorageItemKey);
        if (existing) {
            const existingJson = JSON.parse(existing);
            newItem = {
                ...existingJson,
            };
        }

        newItem[key] = value;

        // console.log("SET", newItem);
        localStorage.setItem(config.localStorageItemKey, JSON.stringify(newItem));
        return true;
    }
    return false;
}
