import Icon from "components/Icon";
import styled from "styled-components";

const Wrapper = styled.div`
    flex: 0 1 15%;
    padding-left: 0.5em;
    position: relative;

    display: flex;
    align-items: center;

    .line {
        flex: 0 1 1em;
        height: 100%;
        position: relative;
        svg {
            width: auto;
            height: 9px;
        }
        .stepStart svg {
            position: absolute;
            top: 0;
            z-index: 2;
            * {
                fill: var(--mainText);
            }
        }
        .stepEnd svg {
            position: absolute;
            bottom: 0;
            left: 2px;
            z-index: 2;
            * {
                fill: var(--mainText);
            }
        }
        .border {
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 6px;
            z-index: 1;
            border-left: 1px dashed var(--mainText);
        }
    }

    .mode {
        flex: 0 0 auto;
        margin: 1em 1em 1em 1.5em;
        .Icon {
            svg {
                height: 2em;
                * {
                    fill: var(--mainText);
                }
            }
        }
    }    
`;

export default function DirectionsCardRowLeftColumn({ modeIcon }) {
    return (
        <Wrapper>
            <div className="line">
                <Icon className="stepStart" category="ui" name="stepStart" />
                <Icon className="stepEnd" category="ui" name="stepEnd" />
                <div className="border"></div>
            </div>
            <div className="mode">
                {modeIcon ? <Icon category="modes" name={modeIcon} /> : null}
            </div>
        </Wrapper>
    )
}