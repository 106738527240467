import config from 'config';

// import mockdata from 'components/mockdata/appsync.json';

// The Google methods here only serve the menu, see googlemaps.js for general location search fields
export default {
    environment: 'production',

    sessionToken: null,

    hasTimer: false,

    async getSearchResults(inputText, appLocation, mapId) {
        if (!this.service && window.google) {
            this.initGooglePlaces(mapId, appLocation);
        }

        // console.log('TIMER', this.hasTimer);
        if (this.hasTimer) {
            clearTimeout(this.hasTimer);
            this.hasTimer = false;
        }

        return new Promise((resolve, reject) => {
            // Throttle call after 200ms for about 10qps
            this.hasTimer = setTimeout(async () => {
                const response = await this.getGooglePlaces(inputText, appLocation);
                this.hasTimer = false;
                resolve(response);
            }, 200);
        });
    },

    getGooglePlaces(inputText, appLocation) {
        const request = {
            query: inputText,
            location: {
                lat: appLocation.coords.latitude,
                lng: appLocation.coords.longitude,
            },
            radius: config.googlePlacesSearchRadius,
        };

        return new Promise((resolve, reject) => {
            this.service.textSearch(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve(results);
                }
                resolve(false); // We will interpret any errors as just No results
            });
        });
    },

    initGooglePlaces(mapId, appLocation) {
        this.map = new window.google.maps.Map(document.getElementById(mapId), {
            center: {
                lat: appLocation.coords.latitude,
                lng: appLocation.coords.longitude,
            },
            zoom: 15,
        });
        this.service = new window.google.maps.places.PlacesService(this.map);
    },

    async getScreens(apiKey) {
        const encodedQueryUrl = `${config[this.environment].appSyncEndpoint}?key=${apiKey}`;
        try {
            const response = await this.fetchJSON(encodedQueryUrl);
            // console.log("onboardAppUser", response);
            if (response && response.data && response.data.screens) {
                return response.data.screens;
            }
        } catch (e) {

        }
        // if (mockdata && mockdata.data.screens) {
        //     return mockdata.data.screens;
        // }
        return null;
    },

    fetchJSON(encodedQueryUrl, method, data) {
        const body = method === 'POST' ? JSON.stringify({
            data,
        }) : null;
        return new Promise((resolve, reject) => {
            fetch(encodedQueryUrl, {
                method,
                body,
                cache: 'no-store', // Big bust for now, change to no-cache later
                mode: 'cors',
                redirect: 'follow',
                credentials: 'same-origin',
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((json) => {
                        resolve(json);
                    }).catch((error) => {
                        reject(error);
                    });
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    },
};
