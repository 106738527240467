import { useEffect, useState } from 'react';

import CardBody from 'components/Card/CardBody';
import CardBodyHeader from 'components/Card/CardBodyHeader';
import CardButtons from './CardButtons';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardMap from 'components/Card/Map/CardMap';
import CardScreenFooter from 'components/common/CardScreenFooter';
import CardScreenHeader from 'components/common/CardScreenHeader';
import DirectionsCard from 'components/common/DirectionsCard';
import PoweredByAF from 'components/common/PoweredByAF';
import React from 'react';
import lokalise from 'utils/lokalise';
import styled from 'styled-components';
import tripscreen from 'utils/tripscreen';

const Scrollable = styled.div`
	overflow-y: scroll;
    position: relative;
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    > div { 
        flex: 0 1 auto;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--appBackground);
    &.opened {
        left: 0;
    }
    &.closed {
        left: 105%; /* Moves card off screen with any overflow */
    }
    .Content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .CardHeader {
            flex: 0 0 auto;
            margin: 0 0.5em 0 0.5em;
        }
        .CardBody {
        }
        .CardFooter {
            padding: 1em;
        }
    }
`;

export default function CardScreen({ toggleCardScreen, openTripScreenFromCardScreen, showCardScreen, screen, selectedCard, appLocation, userLocation, params, setCardFavorite }) {

    const [googleMapsRouting, setGoogleMapsRouting] = useState(null); // Used to retrieve child call for Google Maps Directions API route

    const [walkMinutes, setWalkMinutes] = useState(null);

    const openedClassName = showCardScreen ? 'opened' : 'closed';
    const hasMaps = params.maps && params.maps === "true";
    const modeClassName = selectedCard?.agency?.mode || '';

    useEffect(() => {
        let newWalkMinutes = selectedCard?.walkMinutes > 0 ? tripscreen.getWalkPhrase(selectedCard?.walkMinutes) : null;
        // Journey does not always return walk mins or Hub screen blocks missing walk mins
        // So try to use gmap incoming routing
        if (!newWalkMinutes && googleMapsRouting && googleMapsRouting.routes && googleMapsRouting.routes[0] && googleMapsRouting.routes[0].legs && googleMapsRouting.routes[0].legs[0]) {
            const walkMinFromGmaps = tripscreen.getGoogleMapsWalkPhrase(googleMapsRouting);
            if (walkMinFromGmaps) newWalkMinutes = walkMinFromGmaps;
        }

        setWalkMinutes(newWalkMinutes); // Make walk time consistent for all parts of CardScreen
    }, [googleMapsRouting, selectedCard]);

    function getScreenHeaderText() {

        if (selectedCard?.agency.mode === 'floating') return null;

        return <>{walkMinutes} {walkMinutes ? <span className="divider">/</span> : null} {tripscreen.getEstimatedArrival(selectedCard?.walkMinutes)}</>
    };

    return (
        <Wrapper className={`Card ${openedClassName} ${'mode-' + modeClassName}`}>
            <div className="Content">
                <CardScreenHeader
                    text={getScreenHeaderText()}
                    tapEvent={() => toggleCardScreen(false)}
                />
                <CardMap
                    card={selectedCard}
                    screen={screen}
                    appLocation={appLocation}
                    userLocation={userLocation}
                    showCardScreen={showCardScreen}
                    params={params}
                    setGoogleMapsRouting={setGoogleMapsRouting}
                />
                <Scrollable>
                    <CardHeader
                        hasMaps={hasMaps}
                        card={selectedCard}
                        showCardScreen={showCardScreen}
                        params={params}
                        isFavorite={selectedCard?.isFavorite}
                        setCardFavorite={setCardFavorite}
                    />
                    <CardBody
                        hasMaps={hasMaps}
                        card={selectedCard}
                        params={params}
                        showCardScreen={showCardScreen}
                        setCardFavorite={setCardFavorite}
                    />
                    <CardButtons
                        setCardFavorite={setCardFavorite}
                        googleMapsRouting={googleMapsRouting}
                        card={selectedCard}
                        isFavorite={selectedCard?.isFavorite}
                        toggleCardScreen={toggleCardScreen}
                        openTripScreenFromCardScreen={openTripScreenFromCardScreen}
                    />
                    <CardFooter
                        card={selectedCard}
                        opened={showCardScreen}
                    />
                    <DirectionsCard
                        card={selectedCard}
                        walkMinutes={walkMinutes}
                        googleMapsRouting={googleMapsRouting}
                    />
                    <PoweredByAF />
                </Scrollable>
                {/* <CardScreenFooter
                    googleMapsRouting={googleMapsRouting}
                /> */}
            </div>
        </Wrapper>
    );
}
