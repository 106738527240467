import Icon from "components/Icon";
import lokalise from "utils/lokalise";
import styled from "styled-components";

const Wrapper = styled.div`
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 2em;
    .text {
        margin-right: 0.5em;
=    }
    .Icon {
        svg {
            height: 19px;
            width: auto;
            * {
                fill: var(--mainText);
            }
        }
    }
`;

export default function PoweredByAF() {
    function visitAF() {
        window.open('https://actionfigure.ai', '_new');
    }
    return (
        <Wrapper onClick={visitAF}>
            <div className="text">{lokalise.getKey('ts13l')}</div> <Icon category="ui" name="actionfigure" />
        </Wrapper>
    )
}