import React, {
	Component,
} from 'react';

import Cookies from 'universal-cookie';

import BrowserGeolocation from 'components/BrowserGeolocation';

import lokalise from 'utils/lokalise';
import config from 'config';

const UniversalCookie = new Cookies();

/*
 * global window.CITYMOTION_WEB_IOS_LOCATION_UPDATE
 * Name of method is called in Plugin code to communicate location updates to the front-end 
 */
let _deviceLatitude = '';
let _deviceLongitude = '';
window.CITYMOTION_WEB_IOS_LOCATION_UPDATE = function (latitude, longitude) {
	_deviceLatitude = latitude.toString();
	_deviceLongitude = longitude.toString();
};

class AppLocation extends Component {

	constructor(props) {
		super(props);
		this.state = { useBrowserGeolocation: null }
		this.setAppLocation = this.setAppLocation.bind(this);
		this.startIOSLocationListener = this.startIOSLocationListener.bind(this);
	}

	componentDidMount() {
		const {
			params
		} = this.props;

		const {
			geolocation
		} = params;

		// On first load, the location is the last set saved place
		const savedPlaces = UniversalCookie.get(config.cookies.savedPlaces.name);
		this.setAppLocation(savedPlaces);

		// Set up auto-updating location, favoring native methods over browser
		if (geolocation) {
			if (window.isIOSPluginCoordinates) {
				this.startIOSLocationListener();
			} else if (window.isAndroidPlugin) {
				// Future Android Plugin listener
			} else {
				this.setState({ useBrowserGeolocation: true });
			}
		}
	}

	// @TODO In the future this component ingests geolocation updates
	// For now it represents setting the location on the first page load
	// which is presumed to be the user's location in absence of geolocation
	// @TODO Merge this with the setLocation method in App.js or use a Router like component wrapper 
	// @param savedPlaces array - Helps provide address data when lacking geocode
	setAppLocation(savedPlaces) {
		const {
			params,
			screen,
			updateApp,
			customer,
		} = this.props;

		const {
			locationCode,
			coordinates,
			geolocation,
			key,
		} = params;

		if (!key) return null;

		if (geolocation) return null; // See BrowserGeolocation 

		if (!locationCode && !coordinates) {
			updateApp({
				statusMessage: lokalise.getKey('ts4d'),
				debug: 'No coordinates or location code',
				loading: false,
			});
			return false;
		}

		// LocationCode takes priority but do store all location data for other services
		if (locationCode) {

			let initialName = `Hub ${locationCode}`;
			if (screen) {
				initialName = screen.prettyName || screen.address;
			}

			updateApp({
				statusMessage: lokalise.getKey('ts1b'), // Getting your location
				loading: true,
				appLocation: {
					locationCode,
					name: initialName,
				},
				userLocation: {
					type: 'user',
					name: initialName,
					locationCode,
				},
			});
		} else if (coordinates) {
			try {
				const coordArray = coordinates.split(',');
				const latitude = parseFloat(coordArray[0].trim());
				const longitude = parseFloat(coordArray[1].trim());
				if (latitude && longitude) {

					// Tries to determine a usable name
					let name = '';
					if (savedPlaces) {
						savedPlaces.some((place) => {
							if (place.coords.latitude === latitude && place.coords.longitude === longitude) {
								name = place.name;
								return true;
							}
						})
					}

					updateApp({
						statusMessage: lokalise.getKey('ts1b'), // Getting your location
						loading: true,
						appLocation: {
							name,
							coords: {
								latitude,
								longitude,
							},
						},
						userLocation: {
							name,
							type: 'user',
							coords: {
								latitude,
								longitude,
							},
						},
					});
				} else {
					throw new Error('Lat long was corrupt');
				}
			} catch (e) {
				console.error(e);
				updateApp({
					debug: e.toString(),
					loading: false,
					statusMessage: lokalise.getKey('ts4e'),
					statusInstructions: lokalise.getKey("ts12x"),
					statusContactSupport: true,
				});
			}
		}
	}

	// This method applies a throttle interval to the frequent location updates by 
	// iOS talking to window.CITYMOTION_WEB_IOS_LOCATION_UPDATE.
	startIOSLocationListener() {
		this.previousLatitude = '';
		this.previousLongitude = '';
		setInterval(() => {
			if (_deviceLatitude !== this.previousLatitude || _deviceLongitude !== this.previousLongitude) {
				this.props.updateApp({
					appLocation: {
						coords: {
							latitude: _deviceLatitude,
							longitude: _deviceLongitude,
						},
					},
				});
				this.previousLatitude = _deviceLatitude;
				this.previousLongitude = _deviceLongitude;
			}
		}, 10000);
	}

	render() {
		const {
			params,
			updateApp,
			customer,
		} = this.props;

		const {
			useBrowserGeolocation
		} = this.state;

		if (useBrowserGeolocation) return <BrowserGeolocation customer={customer} updateApp={updateApp} />;

		return null;
	}
}

export default AppLocation
