import CardHeader from "components/Card/CardHeader";
import CardTable from "components/Card/CardTable";
import React from "react";
import config from "config";
import lokalise from "utils/lokalise";

// Replace these with Lokalise
const lokaliseKeys = {
	flight: "Flight",
	flight_origin: "Origin",
	flight_destination: "Destination",
	flight_gate: "Gate",
	flight_arrival_time: "Arrival Time",
	flight_departure_time: "Departure Time",
	flight_status: "Status",
	flight_scheduled: "Scheduled",
	flight_delayed: "Delayed",
};

const flightHeaders = [
	lokaliseKeys.flight_origin,
	lokaliseKeys.flight,
	lokaliseKeys.flight_status,
	lokaliseKeys.flight_arrival_time,
];

export default function FlightCard(props) {
	const {
		card,
		params,
		opened,
		tapOpenCardView, // toggles card screen with other state things
		showCardScreen, // used in the child components
	} = props;

	const { flights } = card;

	const hasMaps = params && params.maps && params.maps === "true";

	const isDeparturesInformation =
		flights[0].estimatedDepartureTime !== null ? true : false;

	if (isDeparturesInformation) {
		flightHeaders[0] = lokaliseKeys.flight_destination;
		flightHeaders[3] = lokaliseKeys.flight_departure_time;
	}

	const rows = flights.map((flight) => ({
		key: flight.number,
		cells: [
			{
				className: "city",
				content: isDeparturesInformation
					? flight.destination
					: flight.origin,
				breakWord: "/",
			},
			{
				className: "info",
				content: (
					<div>
						<img
							src={
								config.flight.imageHost +
								"/" +
								flight.airlineCode +
								".png"
							}
						/>
						{flight.airlineCode}
						<p>&nbsp;&nbsp;</p>
						{flight.number}
					</div>
				),
			},
			{ className: "status", content: flight.status },
			{
				className: "time",
				content: isDeparturesInformation
					? flight.estimatedDepartureTime
					: flight.estimatedArrivalTime,
			},
		],
		expandedContent: `${flight.terminal ? "Terminal " + flight.terminal : "Check signs for terminal"} / Gate ${flight.gate}`,
	}));

	return (
		<div
			className={`Content ${opened ? "opened" : "closed"} ${
				card.visible ? "visible" : "hidden"
			}`}
		>
			<CardHeader hasMaps={hasMaps} {...props} />
			<div className={`CardBody`}>
				<CardTable
					className={"FlightTable"}
					headers={flightHeaders}
					rows={rows}
				/>
			</div>
		</div>
	);
}
