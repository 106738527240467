import React from 'react';

export default function LogoCard(props) {
    if (!props.card.clientLogoUrl) return null;
    let visibleClassName = props.card.visible ? "visible" : "hidden";
    return (
        <div className={`WidgetContent ${visibleClassName}`}>
            <div className="Logo"><img src={props.card.clientLogoUrl} alt="" /></div>
        </div>
    )
}