import ColorLibrary from 'components/common/ColorLibrary';
import Icon from 'components/Icon';
import styled from 'styled-components';
import tripscreen from 'utils/tripscreen';

const Wrapper = styled.div`
overflow: hidden;
height: 1.5em;
position: relative;

.container {
    width: 150%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.fadeout {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 100%;
    width: 5em;
    background: rgb(246,246,244);
    background: linear-gradient(90deg, rgba(246,246,244,0) 0%, var(--cardBackground) 100%);
}

.text {
    font-size: 0.8em;
    margin: 0.2em;
}

.route {
    flex: 0 1 auto;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        margin-right: 0.2em;
    }
    .mode-icon {
        svg {
            width: auto;
            height: 1em;
        }
    }
    .time {
        font-size: 0.8em;
        font-weight: bold;
    }
    .caret { 
        color: #afb1bf;
        margin-left: 0.5em;
    }
    .min { 
        display: none;
    }
    .agency {
        width: auto;
        height: 1em;
    }
}
`;

// Shows mini trip of icons
export default function RoutingIcons({ trip }) {
    if (!trip.legs || !trip.legs[0]) return null;
    // console.log("RoutingIcons", trip);
    // const modeNames = trip.legs?.map((leg) => leg.otpModeDisplayName?.toLowerCase() || leg.mode?.toLowerCase());
    // const uniqueModeNames = modeNames.filter((value, index, self) => self.indexOf(value) === index);

    // Transit doesnt need a mode icon because the Vehicle icon shows a mode icon inside of it

    return (
        <Wrapper>
            <div className="fadeout"></div>
            <div className="container">
                {trip.legs.map((leg, i) => {

                    const bgColor = leg ? (leg?.route?.color || "#555555") : "#555555";
                    const iconColor = ColorLibrary.getTextColor(bgColor, "#fff", "#000");

                    return (
                        <div className="route" key={i}>
                            {!tripscreen.isTransitMode(leg.mode) ? <Icon className="mode-icon" category="modes" url={leg.style.iconSvgUrl} /> : <AgencyIcon leg={leg} />}

                            {(leg.mode === 'walk' || trip.legs.length === 1) && leg.duration && tripscreen.getDuration(leg.duration, true)}

                            {tripscreen.isTransitMode(leg.mode) && <Vehicle bgColor={bgColor} iconColor={iconColor} icon={leg.style.iconSvgUrl} />}

                            {i < trip.legs.length - 1 && <div className="caret">&#8250;</div>}
                        </div>
                    )
                })}
            </div>
        </Wrapper>
    )
}

function AgencyIcon({ leg }) {
    if (leg.agency && leg.agency.style) {
        return <img className="agency" src={leg.agency.style.logoOnlightPngUrl || null} />
    } else {
        return <>{tripscreen.capitalize(leg.mode)}</>
    }
}

const VehicleWrapper = styled.div`
width: 0.8em;
height: 0.8em;
border-radius: 0.2em;
padding: 0.2em;
display: flex;
justify-content: center;
align-items: center;
margin-left: 0.2em;
svg {
    width: auto;
    height: 0.8em;
    padding-top: 0.1em;
}
`;

function Vehicle({ bgColor, iconColor, icon }) {
    return (
        <VehicleWrapper style={{ background: bgColor }}>
            <Icon category="modes" url={icon} fillColor={iconColor} />
        </VehicleWrapper>
    )
}