import actionArrowDown from 'img/svg/ui/action-arrow-down.svg';
import actionArrowLeft from 'img/svg/ui/action-arrow-left.svg';
import actionArrowOut from 'img/svg/ui/action-arrow-out.svg';
import actionArrowRight from 'img/svg/ui/action-arrow-right.svg';
import actionArrowUp from 'img/svg/ui/action-arrow-up.svg';
import actionfigure from 'img/actionfigure/actionfigure.svg';
import afIcon from 'img/actionfigure/af-icon.svg';
import arrowUpDown from 'img/actionfigure/arrow-up-down.svg';
import bicycle from 'img/actionfigure/bike.svg';
import bikeshare from 'img/actionfigure/bikeshare.svg';
import bus from 'img/actionfigure/bus.svg';
import calendar from 'img/svg/ui/calendar.svg';
import car from 'img/actionfigure/car.svg';
import cheap from 'img/svg/ai/cheap.svg';
import clock from 'img/svg/ui/clock.svg';
import compass from 'img/actionfigure/compass.svg';
import compassAlt from 'img/actionfigure/compass-alt.svg';
import crowding from 'img/svg/ui/crowding.svg';
import currentLocationPin from 'img/svg/ui/current-location-pin.svg';
import destinationPin from 'img/svg/ui/destination-pin.svg';
import direct from 'img/svg/ai/direct.svg';
import directionArrowDown from 'img/svg/ui/direction-arrow-down.svg';
import directionArrowLeft from 'img/svg/ui/direction-arrow-left.svg';
import directionArrowLeftUp from 'img/svg/ui/direction-arrow-left-up.svg';
import directionArrowRight from 'img/svg/ui/direction-arrow-right.svg';
import directionArrowRightUp from 'img/svg/ui/direction-arrow-right-up.svg';
import directionArrowUp from 'img/svg/ui/direction-arrow-up.svg';
import ebike from 'img/actionfigure/e-bike.svg';
import fast from 'img/svg/ai/fast.svg';
import favorite from 'img/actionfigure/favorite.svg';
import favoriteAlt from 'img/actionfigure/favorite-alt.svg';
import ferryShuttle from 'img/actionfigure/ferry-shuttle.svg';
import flight from 'img/actionfigure/airplane.svg';
import googleMapsIcon from 'img/actionfigure/google-maps-icon.svg';
import healthy from 'img/svg/ai/healthy.svg';
import home from 'img/actionfigure/home.svg';
import loaderCircle from 'img/actionfigure/loader-circle.svg';
import loaderHomeIcon from 'img/actionfigure/loader-homeicon.svg';
import loaderModeIcons from 'img/actionfigure/loader-modeicons.svg';
import loading from 'img/svg/ui/loading.svg';
import map from 'img/svg/ui/map.svg';
import mapEnd from 'img/actionfigure/map-end.svg';
import mapPin from 'img/actionfigure/map-pin.svg';
import mapStart from 'img/actionfigure/map-start.svg';
import micromobility from 'img/actionfigure/micromobility.svg';
import openExternal from 'img/actionfigure/open-external.svg';
import openNew from 'img/actionfigure/open-new.svg';
import parking from 'img/actionfigure/parking.svg';
import phone from 'img/actionfigure/phone.svg';
import play from 'img/actionfigure/play.svg';
import plus from 'img/actionfigure/plus.svg';
import quiet from 'img/svg/ai/quiet.svg';
import recommended from 'img/actionfigure/recommended.svg';
import scootershare from 'img/actionfigure/e-scooter.svg';
import ship from 'img/actionfigure/ship.svg';
import shuttle from 'img/actionfigure/shuttle.svg';
import sitScootershare from 'img/actionfigure/moped.svg';
import stepEnd from 'img/actionfigure/stepEnd.svg';
import stepStart from 'img/actionfigure/stepStart.svg';
import streetcar from 'img/actionfigure/streetcar.svg';
import subway from 'img/actionfigure/subway.svg';
import sustainable from 'img/svg/ai/sustainable.svg';
import taxi from 'img/actionfigure/taxi.svg';
import train from 'img/actionfigure/train.svg';
import tram from 'img/actionfigure/tram.svg';
import tripLine from 'img/svg/ui/tripLine.svg';
import waiting from 'img/actionfigure/waiting.svg';
import walk from 'img/svg/mode-icons/walk.svg';
import wiCloudy from 'img/svg/weather/wi-cloudy.svg';
import wiCloudyGusts from 'img/svg/weather/wi-cloudy-gusts.svg';
import wiDayCloudyHigh from 'img/svg/weather/wi-day-cloudy-high.svg';
import wiDaySunny from 'img/svg/weather/wi-day-sunny.svg';
import wiFog from 'img/svg/weather/wi-fog.svg';
import wiHail from 'img/svg/weather/wi-hail.svg';
import wiNightAltCloudy from 'img/svg/weather/wi-night-alt-cloudy.svg';
import wiNightClear from 'img/svg/weather/wi-night-clear.svg';
import wiRain from 'img/svg/weather/wi-rain.svg';
import wiSleet from 'img/svg/weather/wi-sleet.svg';
import wiSnow from 'img/svg/weather/wi-snow.svg';
import wiThunderstorm from 'img/svg/weather/wi-thunderstorm.svg';
import wiTornado from 'img/svg/weather/wi-tornado.svg';

// The object keys are quoted '' to remind devs these are to be matched by strings coming from the API
export default {
	'nav': {
		'all': home,
		'rail': train,
		'bus': bus,
		'car': car,
		'micro': micromobility,
		'other': ferryShuttle,
		'tripplan': compass,
	},

	'modes': {
		train,
		car,
		bus,
		walk,
		bicycle,
		bike: bicycle,
		subway,
		tram,
		flight,
		streetcar,
		parking,
		transit: bus,
		shuttle,
		walk,
		walking: walk,
		'bicycle-park': bicycle,
		'bicycle-rent': ebike,
		'park-and-ride': car,
		ferry: ship,
		rail: train,
		floating: taxi,
		'bikeshare': bikeshare,
		'dockless-bikeshare': bicycle,
		'ebikeshare': ebike,
		'scootershare': scootershare,
		'sit-scootershare': sitScootershare,
		'carshare': car,
		'pointtopoint': taxi,
		'traffic': car,
		waiting,
		'cable-car': tram,
	},

	'ai': {
		recommended,
		cheap,
		direct,
		fast,
		healthy,
		quiet,
		sustainable,
	},

	'directions': {
		'up': directionArrowUp,
		'down': directionArrowDown,
		'left': directionArrowLeft,
		'right': directionArrowRight,
		'slight left': directionArrowLeftUp,
		'slight right': directionArrowRightUp,
	},

	// @TODO Convert these back to file imports or not?
	'car2go': {
		'smart': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M28.407 26.135l-10.562-.12M2.185 24.14C.965 23.41.86 22.787 1.11 21.245 3.41 15.886 2.284 4.102 3.72 2.52 5.15.938 7.56 1.08 7.56 1.08s13.06-.288 15.636.23c3.576.25 11.626 9.32 16.513 12.256 4.886 2.934 6.552 1.92 6.06 8.975-.12 1.728-.158 2.306-1.354 2.98"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="9.723" cy="25.52" r="5.48"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="36.499" cy="25.52" r="5.48"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M3.034 10.177l33.833 1.782m-25.53 2.246l5.93.267"/></svg>',
		'subcompact': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#36A7DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M9.3 20.3c-3 0-5.3 2.4-5.3 5.4v.3c.2 2.8 2.5 5.1 5.3 5.1 3 0 5.3-2.4 5.3-5.4v-.2c-.1-2.9-2.4-5.2-5.3-5.2zM45.7 20.3c-3 0-5.3 2.4-5.3 5.4v.3c.2 2.8 2.5 5.1 5.3 5.1 3 0 5.3-2.4 5.3-5.4v-.2c-.1-2.9-2.5-5.2-5.3-5.2z"/><path fill="none" stroke="#36A7DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M1 24.8S.7 16.5 4.3 15c3.6-1.4 2.2-9 7.6-9h18.2l7.9 7.7 11.7 2.2c4.6 2.3 4.3 6.1 4.3 9.9M6.5 13.7H38M35.4 18.2h4.1M37.6 27H17M18.4 13.7V6"/></svg>',
		'suv': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M7.75 25.89c-.15 0-6.03-1.866-6.272-2.187-.3-.4-.478-4.155-.478-4.155-.005-.412.083-.817.26-1.17.19-.38 1.3-2.753 1.3-3.163l.052-5.197c0-.428.052-.96.25-1.32.962-1.767 4.22-7.054 9.652-7.676.065-.007.135-.01.2-.01H28.38c.62-.034 9.368-.43 19.35 8.134.297.256.645.408 1.013.432 2.563.166 13.4 1.213 19.228 5.387.568.402.91 1.126.91 1.9v6.633c0 1.23-2.026 3.27-3.07 3.27m-40.382-.025l23.324.062"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M28.08 4.326H18.8S10.26 4.12 9.308 7.32c0 0-1.284 2.594 2.02 3.797l28.36-.002.64-2.692c-2.852-1.68-6.6-4.097-12.248-4.097zM40.326 8.42v.002-.002zm-23.52 10.288c-3.337 0-6.042 2.75-6.042 6.146 0 .133.006.268.016.396.2 3.21 2.822 5.75 6.027 5.75 3.338 0 6.043-2.752 6.043-6.145 0-.088-.004-.174-.007-.265-.134-3.267-2.784-5.88-6.037-5.882zm40.3 0c-3.337 0-6.042 2.75-6.042 6.146 0 .133.008.268.014.396.2 3.21 2.82 5.75 6.03 5.75 3.337 0 6.04-2.75 6.04-6.145 0-.088-.002-.174-.006-.264-.13-3.27-2.783-5.884-6.035-5.884l-.002.002zM28.04 4.323v6.794M17.98 4.323v6.794"/></svg>',
		'sedan': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22.4 24.4h18M56.3 24.6h2c.3 0 2.1-1.3 2.1-1.3s2.1-1.3.4-7.1c-5.6-3.7-9.2-5.1-17.1-5 0 0-3.5-3.5-4.9-4.5C36.6 5.2 34 4 29.3 4h-4c-2.8 0-7.5 0-10.2 1.7C12.6 6.8 10 9.3 10 9.3s-1.2 1.2-3.3 1.6c-1.5.3-3.1 1.2-3.1 2.9L2 20.2c0 1.2.5 2.4 1.3 3.2.7.6 1.6 1.1 2.6 1.1h.6M26 4l2.1 7.2"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="14.6" cy="23.5" r="5.5"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="48.6" cy="23.5" r="5.5"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M26.3 4c-3.8 0-12 3.6-13.2 7.7l29.5.4"/></svg>'
	},

	'free2move': {
		'smart': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M28.407 26.135l-10.562-.12M2.185 24.14C.965 23.41.86 22.787 1.11 21.245 3.41 15.886 2.284 4.102 3.72 2.52 5.15.938 7.56 1.08 7.56 1.08s13.06-.288 15.636.23c3.576.25 11.626 9.32 16.513 12.256 4.886 2.934 6.552 1.92 6.06 8.975-.12 1.728-.158 2.306-1.354 2.98"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="9.723" cy="25.52" r="5.48"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="36.499" cy="25.52" r="5.48"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M3.034 10.177l33.833 1.782m-25.53 2.246l5.93.267"/></svg>',
		'subcompact': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#36A7DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M9.3 20.3c-3 0-5.3 2.4-5.3 5.4v.3c.2 2.8 2.5 5.1 5.3 5.1 3 0 5.3-2.4 5.3-5.4v-.2c-.1-2.9-2.4-5.2-5.3-5.2zM45.7 20.3c-3 0-5.3 2.4-5.3 5.4v.3c.2 2.8 2.5 5.1 5.3 5.1 3 0 5.3-2.4 5.3-5.4v-.2c-.1-2.9-2.5-5.2-5.3-5.2z"/><path fill="none" stroke="#36A7DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M1 24.8S.7 16.5 4.3 15c3.6-1.4 2.2-9 7.6-9h18.2l7.9 7.7 11.7 2.2c4.6 2.3 4.3 6.1 4.3 9.9M6.5 13.7H38M35.4 18.2h4.1M37.6 27H17M18.4 13.7V6"/></svg>',
		'suv': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M7.75 25.89c-.15 0-6.03-1.866-6.272-2.187-.3-.4-.478-4.155-.478-4.155-.005-.412.083-.817.26-1.17.19-.38 1.3-2.753 1.3-3.163l.052-5.197c0-.428.052-.96.25-1.32.962-1.767 4.22-7.054 9.652-7.676.065-.007.135-.01.2-.01H28.38c.62-.034 9.368-.43 19.35 8.134.297.256.645.408 1.013.432 2.563.166 13.4 1.213 19.228 5.387.568.402.91 1.126.91 1.9v6.633c0 1.23-2.026 3.27-3.07 3.27m-40.382-.025l23.324.062"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M28.08 4.326H18.8S10.26 4.12 9.308 7.32c0 0-1.284 2.594 2.02 3.797l28.36-.002.64-2.692c-2.852-1.68-6.6-4.097-12.248-4.097zM40.326 8.42v.002-.002zm-23.52 10.288c-3.337 0-6.042 2.75-6.042 6.146 0 .133.006.268.016.396.2 3.21 2.822 5.75 6.027 5.75 3.338 0 6.043-2.752 6.043-6.145 0-.088-.004-.174-.007-.265-.134-3.267-2.784-5.88-6.037-5.882zm40.3 0c-3.337 0-6.042 2.75-6.042 6.146 0 .133.008.268.014.396.2 3.21 2.82 5.75 6.03 5.75 3.337 0 6.04-2.75 6.04-6.145 0-.088-.002-.174-.006-.264-.13-3.27-2.783-5.884-6.035-5.884l-.002.002zM28.04 4.323v6.794M17.98 4.323v6.794"/></svg>',
		'sedan': '<svg preserveAspectRatio="xMinYMin" xmlns="http://www.w3.org/2000/svg" width="72" height="32" viewBox="0 0 72 32"><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22.4 24.4h18M56.3 24.6h2c.3 0 2.1-1.3 2.1-1.3s2.1-1.3.4-7.1c-5.6-3.7-9.2-5.1-17.1-5 0 0-3.5-3.5-4.9-4.5C36.6 5.2 34 4 29.3 4h-4c-2.8 0-7.5 0-10.2 1.7C12.6 6.8 10 9.3 10 9.3s-1.2 1.2-3.3 1.6c-1.5.3-3.1 1.2-3.1 2.9L2 20.2c0 1.2.5 2.4 1.3 3.2.7.6 1.6 1.1 2.6 1.1h.6M26 4l2.1 7.2"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="14.6" cy="23.5" r="5.5"/><circle fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" cx="48.6" cy="23.5" r="5.5"/><path fill="none" stroke="#30A7DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M26.3 4c-3.8 0-12 3.6-13.2 7.7l29.5.4"/></svg>'
	},

	'ui': {
		'appButtonArrow': actionArrowOut,
		'exit': actionArrowLeft,
		'exit-right': actionArrowRight,
		'select': actionArrowRight,
		'expand': actionArrowDown,
		'collapse': actionArrowUp,
		favorite,
		favoriteAlt,
		openExternal,
		crowding,
		mapLocation: home,
		mapPin,
		map,
		loading,
		destinationPin,
		mapStart,
		mapEnd,
		stepStart,
		stepEnd,
		currentLocationPin,
		calendar,
		arrowUpDown,
		tripLine,
		clock,
		actionfigure,
		googleMapsIcon,
		compass,
		compassAlt,
		openNew,
		play,
		loaderHomeIcon,
		loaderModeIcons,
		loaderCircle,
		plus,
		afIcon,
		recommended,
		phone
	},

	// Translate Forecast.io weather terms into svg filenames
	'weather': {
		'clear-day': wiDaySunny,
		'clear-night': wiNightClear,
		'rain': wiRain,
		'snow': wiSnow,
		'sleet': wiSleet,
		'wind': wiCloudyGusts,
		'fog': wiFog,
		'cloudy': wiCloudy,
		'partly-cloudy-day': wiDayCloudyHigh,
		'partly-cloudy-night': wiNightAltCloudy,
		'hail': wiHail,
		'thunderstorm': wiThunderstorm,
		'tornado': wiTornado
	}

};
