import Icon from 'components/Icon';
import Tappable from 'components/common/Tappable';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 1em 0 1em 0;
  font-size: 0.8em;
  display: flex;
  align-items: flex-end;
  .button {
    min-height: 40px;
    padding: 1em 0 1em 0;
    background: var(--cardBackground);
    box-shadow: 0 0.3em 0.5em var(--cardBoxShadow);
    border: 1px solid var(--mainText);
    flex: 0 1 100%;
    text-align: center;
    border-radius: 1em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin: 0;
    }
    .icon {
      svg {
        width: auto;
        height: 2em;
      }
    }
}
`;

export default function ButtonGroup({ buttons }) {
  return (
    <Wrapper className="button-group">
      {buttons && buttons.map((button, i) => (
        <Tappable key={i} role="button" className="button" onTap={button.onClick} style={{ color: button.buttonText || null, backgroundColor: button.buttonColor || null }}>
          <div className="icon">{button.iconName ? <Icon name={button.iconName} category={button.iconCategory} color={button.buttonText} /> : null}</div>
          <div className="text">{button.text}</div>
        </Tappable>
      ))}
    </Wrapper>
  )
}
