import React from 'react';
import Icon from 'components/Icon';


function ExitButton(props) {
	const {
		showButton,
		tapEvent,
		icon,
	} = props;

	if (showButton) {
		return (
			<button role="button" className="ExitButton" onClick={tapEvent}>
				<div className="icon">
					<Icon category="ui" name={icon || 'exit'} />
				</div>
			</button>
		);
	}
	return null;
}

export default ExitButton;
