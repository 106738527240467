// All thematic css is hoisted in index.scss via SASS
import './styles/index.scss';
// Imports mapbox styles once for all map components
import 'mapbox-gl/dist/mapbox-gl.css';

import * as Sentry from '@sentry/react';

import {
	isMobileOnly,
	isMobileSafari,
	isTablet,
} from 'react-device-detect';

import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import Tester from 'components/Tester/Tester';
import config from 'config';

// import * as serviceWorker from 'serviceWorker';





// ===========================================================================
// Force HTTPS on hosted sites
if (window.location.host.indexOf('citymotion') !== -1 && window.location.protocol !== 'https:') {
	window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

/* Initialize Sentry as soon as possible in the stack */
if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://10ceffaac6e14bcbba396eeac2b58228@o418412.ingest.sentry.io/5321316',
		release: `${config.internalAppName}@${config.version}`,
		integrations: [
			// Sentry obscures console.log location https://github.com/getsentry/sentry-react-native/issues/794
			new Sentry.Integrations.Breadcrumbs({
				console: process.env.NODE_ENV === 'development' ? false : true
			})
		],
	});
}

// Clean up browser URL, these vars are used for below....
const searchQuery = (window.location.search || document.location.search) + window.location.hash;
const queryParams = new URLSearchParams(searchQuery);
const path = window.location.pathname;
const fullBrowserURL = window.location.toString();

// Allow only certain URL paths by matching exact strings
const paths = config.allowedPathNames;
const pathName = window.location.pathname.replace('/', ''); // "/browser" to "browser"
const context = paths[paths.indexOf(pathName)] || '';

// Remove unrecognized URL paths (preserves parameters)
if (!context && window.location.pathname.length > 1) {
	const cleanedPath = fullBrowserURL.replace(path, ''); // Remove pathname
	if (window.history.pushState) {
		window.history.pushState({}, config.publicAppName, cleanedPath);
	}
}

// Pass parameters as an object for quick lookup
// @TODO Prob goes away when switching to Router
// const params = Object.fromEntries(queryParams); // {abc: "foo", def: "[asf]", xyz: "5"}
const params = {};
queryParams.toString().split('&').forEach((keyValue) => {
	const pair = keyValue.split('=');
	const [
		key,
		value
	] = pair;

	//Handling multiple color scheme insert under one parameter... Maybe become separate function if we get more reqs like this
	if (key === 'colorScheme') {
		const colorScheme = decodeURIComponent(value).split(",");
		colorScheme.forEach((color, i) => {
			if (!params[config.COLOR_PARAMS[i]]) {
				params[config.COLOR_PARAMS[i]] = color.replace(/[^0-9\A-z\-\,.]/g, '');
			}
		})
	}
	//A regex to apply to all params. May develop into a separate function handling params case by case
	params[key] = decodeURIComponent(value).replace(/[^0-9\A-z\-\,.]/g, '');
});

// ===========================================================================
// Site configurations from parameters

// Sets color mode using CSS variable sheets
const root = document.getElementsByTagName('html')[0];
if (params.ui === 'dark') {
	root.setAttribute('color-mode', 'dark');
} else {
	root.setAttribute('color-mode', 'light');
}

// @TODO remove this when migrating to new GA
if (!params.disable) ReactGA.initialize(config.googleAnalytics4Id);

// ===========================================================================
// UI Overrides

// Device type classes for mostly solving device specific problems
// CSS does responsive design in each component independently
if (isMobileOnly) window.document.body.classList.add('isMobile');
if (isTablet) window.document.body.classList.add('isTablet');
if (!isMobileOnly && !isTablet) window.document.body.classList.add('isDesktop');
if (isMobileSafari) window.document.body.classList.add('isMobileSafari');
// Sniff iframe, wrapped in try due to possible CORS error
try {
	if (window.self !== window.top) {
		window.document.body.classList.add('is-iframe');
	}
} catch (e) {
	// not iframe
}

// Disable double tap to zoom on Safari @TODO This may not be needed anymore
if (isMobileSafari) {
	let lastTouchEnd = 0;
	document.addEventListener('touchend', (event) => {
		const now = (new Date()).getTime();
		if (now - lastTouchEnd <= 300) {
			event.preventDefault();
		}
		lastTouchEnd = now;
	}, false);
}

/*
 * Initialize Google Maps Script dynamically only for required features
 * We only use Google Maps for Directions and Places routing, not mapping
 * @TODO After migration to Mapbox this can be removed
 */
if (!window.google && (params.maps || params.menu || params.tripPlan === 'true' || params.view === 'tripPlan' || params.view === 'onlyTripPlan')) {
	const script = document.createElement('script');
	script.src = `${config.googleMapsEndpoint}?libraries=places&key=${config.googleMapsApiKey}&callback=initMap`;
	script.async = true;
	window.initMap = function () { };
	document.head.appendChild(script);
}

// ===========================================================================
// Render
if (queryParams.has('tester')) {
	ReactDOM.render(<Tester />, document.getElementById('root'));
} else {
	ReactDOM.render(<App context={context} params={params} />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
