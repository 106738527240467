import React from 'react';

import lokalise from 'utils/lokalise';

/* For Dockless cards, describes agencies that are not shown */
export default function UnavailableAgencies(props) {
    if (props.card.agencies && props.card.agencies.length > 0) {

        var agencyNames = "";

        props.card.agencies.forEach(agency => {
            if (agency.available === false) {
                agencyNames += agency.shortName + ", ";
            }
        })

        if (!agencyNames) return null;

        var message = `${lokalise.getKey('ts6c')} ${agencyNames.slice(0, -2)}`;
        return (
            <div className="UnavailableAgencies">
                {message}
            </div>
        )
    }
    return null
}
