function ScrollArea({ children, params }) {
    const {
        barPosition,
        header,
        menu,
    } = params;

    const style = {};

    // Alter the top and bottom padding when addl page elements are added
    const hasHeader = (header !== undefined || menu !== undefined);
    if (hasHeader) {
        style.paddingTop = 70;
    }
    if (hasHeader && barPosition === 'top') {
        style.paddingTop = 145;
    }
    if (!hasHeader && barPosition === 'top') {
        style.paddingTop = 85;
    }

    if (barPosition === 'bottom') {
        style.paddingBottom = 120; // Bar + Notch + extra
    }

    return (
        <div className="ScrollArea" style={style}>
            {children}
        </div>
    )
}

export default ScrollArea
