import Icon from "components/Icon";
import lokalise from "utils/lokalise";
import tripscreen from "utils/tripscreen";

function AIResults({ tripOption }) {
    const template = [{
        icon: 'sustainable',
        value: 'impact',
    }, {
        icon: 'healthy',
        value: 'calories',
    }, {
        icon: 'quiet',
        value: 'interaction'
    }];

    return (
        <div className="AIResults">
            {tripOption?.scores && template.map((t, i) =>
                <AIResult key={i} icon={t.icon} name={t.value} value={tripOption.scores[t.value]} />)}
        </div>
    );
}

function AIResult({ icon, name, value }) {
    const aiValue = <b>{tripscreen.getAIResultText(name, value)}</b>;
    return (
        <div className="AIResult">
            <Icon category="ai" name={icon} />
            <div className="text">
                {icon === 'sustainable' && lokalise.getKey('tripplan_about_co2', { num_g: aiValue })}
                {icon === 'healthy' && lokalise.getKey('tripplan_about_calories', { num: aiValue })}
                {icon === 'quiet' && lokalise.getKey('tripplan_about_interaction', {
                    qualifier: aiValue
                })}
            </div>
        </div>
    )
}

export default AIResults
