const searchQuery = window.location.search || document.location.search;
const params = new URLSearchParams(searchQuery);

export const loadCardScreen = params.get('interactive') !== 'false';

export const allowCardTapOpen = params.get('interactive') !== 'false' && params.get('maps') === 'true';

export const showTapRowOpenApp = (card) => params.get('interactive') !== 'false' && params.has('externalLinks') && card.category !== "masstransit" && card.agency?.app?.appName;

export const showTapRowMore = (card) => params.get('interactive') !== 'false' && params.get('maps') === 'true' && card.category === 'masstransit';

export const allowSeeMoreOptions = params.get('interactive') !== 'false' && params.get('maps') === 'true';
