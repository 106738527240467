import React, {
    useEffect,
    useRef,
    useState
} from 'react';

import GoogleMapsPlacesSearcher from 'components/LocationSearchField/GoogleMapsPlacesSearcher';
import LocationInput from 'components/LocationSearchField/LocationInput';
import lokalise from 'utils/lokalise';
import mapbox from 'utils/mapbox';

let __itemWasSelected = false; // Necessary to bypass onBlur

function LocationSearchField({
    location,
    locked,

    placeholder,

    searchAreaLocation,

    setLocation,
    setUserPosition,
}) {

    const lsfEle = useRef(null);

    const [searchResults, setResults] = useState(null);

    const [searchMessage, setSearchMessage] = useState('');
    const [inputValue, setInputValue] = useState('');

    const [isActive, setIsActive] = useState(null);
    const [disabled, setDisabled] = useState(null);

    // This may look redundant but is needed as the parent location value could be provided
    useEffect(() => {
        // This is only happens on first load to fill the inputvalue initially
        if (location && !inputValue) {
            setInputValue(location.name || location.address || 'New location');
        }
        // This is typically a forced ingestion of tripScreenLocation (see toggleTripScreen)
        if (location && location.name !== inputValue) {
            // console.log("LOCATION", location);
            setInputValue(location.name || location.address);
        }
    }, [location]);

    useEffect(() => {
        if (searchResults) {
            setSearchMessage(searchResults.length + " " + lokalise.getKey("ts10q"));
            // setTimeout(() => {
            //     setIsActive(false);
            // }, 1000);
            // if (disabled) setDisabled(false);
        } else if (searchResults && searchResults.length === 0) {
            setSearchMessage(lokalise.getKey('ts5o'));
            // We still keep the previous search results up
        } else {
            // Some other error but give generic error
            setSearchMessage(lokalise.getKey('ts5o'));
        }
    }, [searchResults]);

    function onFocusEvent(e) {
        setIsActive(true);
        setSearchMessage(lokalise.getKey('ts5g'));
    }

    async function onChangeEvent(e) {
        // console.log("ONCHANGE", e);
        const val = e.target.value || '';
        setInputValue(val);
        if (!val) return null; // The user has zeroed out the field, keeps the last search results up

        setIsActive(true);
        setSearchMessage(lokalise.getKey('ts5n'));
    }

    // User has tapped away
    function onBlurEvent(e) {
        const ele = e.target;
        // Set timeout is required as it will cancel out other concurrent methods (onSelect)
        setTimeout(() => {

            // @IMPORTANT This triggering will cancel out onSelect, we toggle this with __itemWasSelected
            if (__itemWasSelected) {
                __itemWasSelected = false;
                return null;
            }

            if (!location) {
                // ele.value = ''; // Clear the field or user will think something was selected
                setInputValue('');
            } else if (ele.value !== location.address) {
                // A location was previously chosen and this is the user attempting to search for a new one
                // always revert back to an actively set location if the input is not the same
                setInputValue(location.name || location.address || 'Previous location');
            }
            setIsActive(false);
        }, 200); // 200 ms appears to be the appropriate delay to allow onSelect to go first
    }

    // @param location is a Mapbox formatted obj
    function onSelect(location) {
        __itemWasSelected = true;

        // console.log("ON SELECT", location);

        setLocation(location);
        setInputValue(location.name || 'New location');
        setIsActive(false);
    }

    function onKeyUpEvent(e) {
        // Default on return to select the top list item
        if (e.keyCode === 13 && searchResults && searchResults.length) {
            // console.log("ON KEYUP", searchResults[0]);
            setLocation(searchResults[0]);
            setIsActive(false);
        }
    }

    return (
        <div className={`LocationSearchField ${disabled ? 'disabled' : ''} ${locked ? 'locked' : ''}`} ref={lsfEle}>
            <LocationInput placeholder={placeholder} disabled={disabled} locked={locked} inputValue={inputValue} onFocusEvent={onFocusEvent} onKeyUpEvent={onKeyUpEvent} onChangeEvent={onChangeEvent} onBlurEvent={onBlurEvent} />

            <GoogleMapsPlacesSearcher inputValue={inputValue} searchAreaLocation={searchAreaLocation} searchMessage={searchMessage} setResults={setResults} isActive={isActive} onSelect={onSelect} searchResults={searchResults} />

        </div>
    );
}


export default LocationSearchField;
