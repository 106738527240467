/*
* Deprecated: All this moved to TS Commons
*/
import moment from 'moment';

export default {

    headwayMinutes: 1, // The headway difference between each vehicle in minutes
    walkFilterMultiplier: 0.2, // Reducer value 0 to 1, 0.2 = "drive", assumes user can move faster than the walk time advertised

    filterItems(items, card) {
        let filteredItems = [];

        var walkMinutes = false;
        if (card && card.context && card.context.walk_minutes) {
            walkMinutes = card.context.walk_minutes;
            if (walkMinutes >= 0) {
                walkMinutes = Math.round(walkMinutes);
            }
        }

        items.forEach(item => {
            item = this.filterItemPredictionHeadways(item);
            item = this.filterItemWalkablePredictions(item, walkMinutes);
            if (item) {
                filteredItems.push(item);
            }
        })
        filteredItems = this.sortItemsByPrediction(filteredItems);
        return filteredItems;
    },

    filterItemPredictionHeadways(item) {

        let predictions = item.predictions;

        if (!predictions) return item;

        // Filter out duplicates first
        let uniqueArray = predictions.filter(function(item, pos) {
            return predictions.indexOf(item) === pos;
        })

        // Then ingest only predictions greater than our headwayMinutes
        let headwayMinutes = this.headwayMinutes;
        let filteredPredictions = [];
        uniqueArray.forEach((pred, i) => {
            let a = pred;
            let b = uniqueArray[i + 1];
            // console.log(a,b);
            if (i === 0) {
                filteredPredictions.push(a);
            } else if (b) {
                // Only ingest predictions beyond our minprediction
                // This basically skips predictions
                if ((parseFloat(b) - parseFloat(a)) > headwayMinutes) {
                    filteredPredictions.push(a);
                }
            } else if (!b) {
                // If there is no next prediction...
                filteredPredictions.push(a);
            }

        })
        item.predictions = filteredPredictions;

        return item;
    },

    filterItemWalkablePredictions(item, walkMinutes) {
        // Sort walkable only if greater than 2 minutes away
        if (walkMinutes > 2 && item.predictions) {

            // Adjust walk minutes with the multiplier
            walkMinutes = walkMinutes * this.walkFilterMultiplier;

            // Check array for predictions we can only reach
            item.predictions.forEach((pred, i) => {
                if (pred < walkMinutes) {
                    item.predictions.splice(i, 1); // Remove this prediction!
                }
            });

            // Now reassign to the prediction property
            if (item.predictions[0] >= 0) {
                item.prediction = item.predictions[0];
            } else {
                // If there are no walkable predictions we exclude this vehicle
                item = false;
            }
        }

        return item;
    },

    sortItemsByPrediction(items) {
        items.sort((a, b) => {
            return a.prediction - b.prediction
        });
        return items;
    },

    displayArrivalTime(time, timeDisplay) {
        const currentTime = moment(new Date()).add(time, 'm').toDate();
        if (timeDisplay === 'actual') {
            return currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute:'2-digit',
            }).split(' ')[0];
        } 
        else if (timeDisplay === 'actualAMPM') {
            return currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute:'2-digit'
            })
        } 
        else if (timeDisplay === 'actual24') {
            return currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute:'2-digit',
                hour12: false
            })
        }
        else {
            return time
        }

    }
}
