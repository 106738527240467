import platform from 'platform';
import { useContext, useEffect, useRef, useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { GoogleMapsContext } from 'components/context/GoogleMapsProvider';
import ReactGA from 'react-ga4';
import TripPlanScreen from 'components/TripScreen/TripPlanScreen/TripPlanScreen';
import TripSearchScreen from 'components/TripScreen/TripSearchScreen/TripSearchScreen';
import analytics from 'utils/analytics';
import config from 'config';
import lokalise from 'utils/lokalise';
import userstore from 'utils/userstore';

function TripScreen({
    appLocation,
    params,

    isPermanent,

    showTripScreen,
    tripScreenLocation,

    toggleTripScreen,
}) {

    const eleTripScreen = useRef();
    const eleTripPlanScreen = useRef();

    const [tripOptions, setTripOptions] = useState(null);
    const [selectedTripPlan, setTripPlan] = useState(null);
    const [showTripPlanScreen, setTripPlanScreen] = useState(null);
    const [searchingTrips, setSearchingTrips] = useState(false);

    const [statusMessage, setStatusMessage] = useState(null);

    const [startingLocation, setStartingLocation] = useState(tripScreenLocation || null);// {coords: {latitude, longitude}}
    const [endingLocation, setEndingLocation] = useState(null);// {coords: {latitude, longitude}}
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);

    const [errorCount, setErrorCount] = useState(0);
    const gmaps = useContext(GoogleMapsContext);

    useEffect(async () => {
        // On first load, 
        const routing = await userstore.getUserRouting();
        // console.log("Saved Routing", routing);
        if (routing && routing.start) {
            setStartingLocation(routing.start);
        }

        if (routing && routing.end) {
            setEndingLocation(routing.end);
        }

        if (routing && routing.startDate) {
            setStartDate(routing.startDate);
        }
        if (routing && routing.startTime) {
            setStartTime(routing.startTime);
        }
    }, []);

    // We allow for forced setting of the start location to accommodate CardScreen requests
    useEffect(() => {
        if (eleTripScreen.current && tripScreenLocation) {
            setStartingLocation(tripScreenLocation);
        }
    }, [tripScreenLocation]);

    useEffect(() => {
        // Toggles off the trip plan screen if this comp is asked to be hidden
        if (showTripScreen === false) {
            setTripPlanScreen(false);
        }
    }, [showTripScreen])

    // This is necessary to persist input fields next time user reloads the page
    useEffect(() => {
        updateSavedRouting();
    }, [startingLocation, endingLocation, startDate, startTime]);

    function updateSavedRouting() {
        userstore.setUserRouting(startingLocation, endingLocation, startDate, startTime);
    }

    // Converting nameless locations "37,-93" only happens for URL param start/end or user geolocation
    function setNiceNameForLocation(location, setLocationMethod) {
        gmaps.getGMPlacesLocation(location).then((parsedLocation) => {
            if (parsedLocation) {
                // console.log("parsedLocation", parsedLocation);
                setLocationMethod(parsedLocation);
            }
        });
    }

    function setStartingLocationEvent(location) {
        processLocation(location, setStartingLocation);
    }

    function setEndingLocationEvent(location) {
        processLocation(location, setEndingLocation);
    }

    function processLocation(location, setLocationMethod) {
        if (location.type == 'usergeolocation') {
            setNiceNameForLocation(location, setLocationMethod);
        } else {
            setLocationMethod(location);
        }
    }

    function formatTripDateTime(date, time) {
        const [hour, minute] = time.split(':');
        const year = date.slice(0, 4)
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        const today = new Date();
        today.setFullYear(year);
        //TODO: Figure out why it is adding one when using setMonth()
        today.setMonth(month - 1);
        today.setDate(day);
        today.setHours(hour);
        today.setMinutes(minute);
        return new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().slice(0, -5);
    }

    function removeDuplicateJourney(options) {
        // Routing may return multiple routings for each mode (ie: different driving paths)
        // Right now we will only offer one of each
        let existingOptions = [];
        return options.map((option) => {
            if (existingOptions.includes(option.name)) {
                return null
            }
            existingOptions.push(option.name);
            return option
        }).filter((option) => option)
    }

    async function getTripPlan(date, time) {
        setStatusMessage(lokalise.getKey('ts10i'));
        //Start searching
        setSearchingTrips(true);
        setTripOptions(null);

        // console.log(date, time, startingLocation, endingLocation);
        if (!date) {
            setStatusMessage(lokalise.getKey("ts12o"));
            return;
        }

        if (!time) {
            setStatusMessage(lokalise.getKey("ts12p"));
            return;
        }

        if (!startingLocation || !startingLocation.coords || !endingLocation || !endingLocation.coords) {
            setStatusMessage(lokalise.getKey("ts12q"));
            return;
        }

        const data = await fetch(config.routingBackendURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                apiKey: config.routingBackendKey,
                fromCoordinates: {
                    latitude: startingLocation.coords.latitude,
                    longitude: startingLocation.coords.longitude
                },
                toCoordinates: {
                    latitude: endingLocation.coords.latitude,
                    longitude: endingLocation.coords.longitude
                },
                departBy: formatTripDateTime(date, time),
                locationCode: params.locationCode,
                customerKey: params.key,
                metadata: {
                    url: window.location.href,
                    appName: 'Citymotion',
                    appVersion: config.version,
                    browser: platform.name,
                    browserVersion: platform.version,
                    os: platform.os.family,
                    osVersion: platform.os.version,
                    device: platform.product,
                    isoDate: new Date().toISOString(),
                },
            })
        })
            .then((response) => response.json())
            .then((json) => {
                // console.log("TRIP", json);
                if (json.data.journey && json.data.journey.length > 0) {
                    setTripOptions(removeDuplicateJourney(json.data.journey));
                    setStatusMessage("");
                } else if (json.message) {
                    setStatusMessage(`Please try a different location (${json.message})`);
                } else {
                    setStatusMessage(lokalise.getKey("ts12r"));
                }
                setErrorCount(0);
            })
            .catch((error) => {
                setErrorCount(errorCount + 1);
                if (error.toString().includes('TypeError: Failed to fetch')) {
                    // Usually CORS problem
                    setStatusMessage(lokalise.getKey("ts12s"));

                } else {
                    setStatusMessage(`${lokalise.getKey("ts12t")} ` + JSON.stringify(e));
                }
                if (errorCount > 3) {
                    setStatusMessage(lokalise.getKey("ts12u"));
                }
                console.error('Something went wrong:', error);
            });

        //End search
        setSearchingTrips(false);
    }

    function onClickStatusMessage() {
        if (errorCount > 3) {
            // @TODO Mailto Actionfigure.ai
        }
    }

    function formatTripPlanForDataDrop(plan) {
        return {
            ...plan,
            legs: plan.legs.map((leg) => {
                return {
                    ...leg,
                    geometryCoordinates: [
                        leg.geometryCoordinates[0],
                        leg.geometryCoordinates[leg.geometryCoordinates.length - 1],
                    ]
                }
            }),
            startingLocation: {
                latitude: startingLocation.coords.latitude,
                longitude: startingLocation.coords.longitude,
                country: startingLocation.parsedAddress?.country,
                place: startingLocation.parsedAddress?.place,
                postcode: startingLocation.parsedAddress?.postcode,
                region: startingLocation.parsedAddress?.region
            },
            endingLocation: {
                latitude: endingLocation.coords.latitude,
                longitude: endingLocation.coords.longitude,
                country: endingLocation.parsedAddress?.country,
                place: endingLocation.parsedAddress?.place,
                postcode: endingLocation.parsedAddress?.postcode,
                region: endingLocation.parsedAddress?.region
            },
            url_params: params
        }
    }

    async function selectTripOption(plan) {
        ReactGA.event("Trip Option", {
            category: "Trip Option Selection",
            action: `${plan._label} option selected`,
            tripOption: plan._label,
            modeName: plan.name,
            starting_latitude: startingLocation.coords.latitude.toFixed(2),
            starting_longitude: startingLocation.coords.longitude.toFixed(2),
            starting_country: startingLocation.parsedAddress?.country,
            starting_region: startingLocation.parsedAddress?.region,
            starting_place: startingLocation.parsedAddress?.place,
            starting_postcode: startingLocation.parsedAddress?.postcode,
            ending_latitude: endingLocation.coords.latitude.toFixed(2),
            ending_longitude: endingLocation.coords.longitude.toFixed(2),
            ending_country: endingLocation.parsedAddress?.country,
            ending_region: endingLocation.parsedAddress?.region,
            ending_place: endingLocation.parsedAddress?.place,
            ending_postcode: endingLocation.parsedAddress?.postcode,
        });
        setTripPlan(plan);
        analytics.postToDataDrop(formatTripPlanForDataDrop(plan), 'trip-option', params);
        setTripPlanScreen(true);
    }

    useEffect(() => {
        if (showTripScreen || isPermanent) {

            if (isPermanent) {
                eleTripScreen.current.style.transition = 'none';
            }

            // Open screen
            requestAnimationFrame(() => {
                if (eleTripScreen && eleTripScreen.current) {
                    eleTripScreen.current.style.display = 'block';
                    setTimeout(() => { // Ready browser for painting
                        requestAnimationFrame(() => {
                            eleTripScreen.current.style.bottom = 0;
                        });
                    }, 1);
                }
            });
        } else if (!showTripScreen && !isPermanent) {
            // Close screen
            requestAnimationFrame(() => {
                if (eleTripScreen && eleTripScreen.current) {
                    eleTripScreen.current.style.bottom = '-100vh';
                    setTimeout(() => {
                        eleTripScreen.current.style.display = 'none';
                    }, 275);
                }
            });
        }
    }, [showTripScreen])


    useEffect(() => {
        requestAnimationFrame(() => {
            if (eleTripPlanScreen && eleTripPlanScreen.current) {
                if (showTripPlanScreen) {
                    eleTripPlanScreen.current.style.left = 0;
                } else {
                    // Add some extra vw for box-shadow
                    eleTripPlanScreen.current.style.left = '130vw';
                }
            }
        })
    }, [showTripPlanScreen]);

    return (
        <div ref={eleTripScreen} className="TripScreen">

            <div className="screen-search">
                <ErrorBoundary>
                    <TripSearchScreen
                        // Only necessary non-TripScreen props
                        toggleTripScreen={toggleTripScreen}
                        isPermanent={isPermanent}
                        searchingTrips={searchingTrips}

                        // State 
                        statusMessage={statusMessage}
                        tripOptions={tripOptions}
                        showTripPlanScreen={showTripPlanScreen}
                        errorCount={errorCount}

                        // Location objects
                        startingLocation={startingLocation}
                        endingLocation={endingLocation}
                        startDate={startDate}
                        startTime={startTime}

                        // Methods and Setters
                        getTripPlan={getTripPlan}
                        setStartingLocation={setStartingLocationEvent}
                        setEndingLocation={setEndingLocationEvent}
                        setStartDate={setStartDate}
                        setStartTime={setStartTime}

                        searchAreaLocation={appLocation || startingLocation}

                        selectTripOption={selectTripOption}
                        onClickStatusMessage={onClickStatusMessage}

                        params={params}
                    />
                </ErrorBoundary>
            </div>

            <div ref={eleTripPlanScreen} className="screen-trip-plan">
                <ErrorBoundary>
                    <TripPlanScreen
                        // State
                        tripOptions={tripOptions}
                        selectedTripPlan={selectedTripPlan}

                        // Location objs
                        startingLocation={startingLocation}
                        endingLocation={endingLocation}

                        // Methods and setters
                        showTripPlanScreen={showTripPlanScreen}
                        setTripPlanScreen={setTripPlanScreen}
                    />
                </ErrorBoundary>
            </div>
        </div>
    )
}


export default TripScreen
