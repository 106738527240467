/*
 * Constants.js
 * Values that support methods in this library and overridable fallbacks
 * Only store numbers or reference values, no sensitive information such as URLs or API keys
 */

module.exports = {

    // These values are intrinsic product decisions that should apply to all apps universally

    minimumHeadwayMinutes: 2, // The minimum allowed headway difference between each vehicle in minutes

    maximumArrivalMinutes: 99, // Predictions may not exceed this number unless specified by the customer

    minutesToClockTimeLimit: 60, // min Vehicles too far away will show clock time
    predictionClockFormat: 'h:mm a', // Converting mins to 3:30 am

    // Critical API related values for all TransitScreen APIs

    updateDataRate: 55000, // 55 seconds between API calls
    updateDataTimeout: 20000, // 20 second timeout for an API call to succeed

    // The remaining values are overridable fallbacks ==================================

    groupingKey: 'group_id', // Fallback key value that tells us how to group vehicles together

    defaultRowLimit: {
        masstransit: 6,
        waittime: 10,
        floating: 3,
        dockless: 2,
        carshare: 2
    }


}
