import config from 'config.js';

// This deals with sorting at the highest Updater level, cleaning up abstract API data
// Sorting within Columns for favorites is handled in CardColumns.helper.js

function isValidEntity(value) {
	if (value) {
		if (!value.id || value.id === null) return false;

		if (typeof value === 'object' && value.constructor === Object) {
			return (Object.keys(value).length > 0);
		}
	}
	return false;
}

export default {

	/*
	 * Convert array to accessible object
	 * @return object {id: {}}
	 */
	convertArrayToObject(newArray, key) {
		let obj = {};
		for (let i = 0; i < newArray.length; i++) {
			const item = newArray[i];
			obj[item[key]] = item;
		}
		return obj;
	},

	/*
	 * Starting from the validated json.data object, return cards array
	 * Data is structured to be easiest for this front-end to consume
	 * @param data object - containing entities and alerts, etc
	 * @param agencies object - transformed from above
	 * @return cards array or fail to empty array
	 */
	getData(data) {
		// The API fields can be unpredictable, so wrap parsing in a try catch
		if (data && data.entities) {
			try {
				// Convert arrays into referencable objects
				const agencies = data.agencies ? this.convertArrayToObject(data.agencies, 'id') : {};
				const alerts = data.alerts ? this.convertArrayToObject(data.alerts, 'id') : {};

				// Create cards
				let cards = this.processEntitiesIntoCards(data.entities, agencies, alerts);
				if (data.alerts && data.alerts.length > 0) {
					cards = this.appendAgencyAlertCards(cards, agencies, alerts);
				}

				//Widget only comes in from screen so check that first
				if (data.screen) {
					cards = this.appendWidgetCards(data.screen, cards);
				}

				// console.log("=== getCards: ", cards);
				return {
					cards,
					agencies,
					alerts,
				};
			} catch (e) {
				return {
					error: e,
					cards: [],
					agencies: {},
					alerts: {},
				};
			}
		}
		return {
			cards: [],
			agencies: {},
			alerts: {},
		};
	},

	/*
	 * Widget syntax varies depending on the widget type, traffic will serve array
	 */
	appendWidgetCards(screen, cards) {
		const {
			widgets,
		} = screen;

		if (screen && widgets) {
			Object.entries(widgets).forEach((widget, i) => {
				const type = widget[0];
				const props = widget[1];

				if (Array.isArray(props)) {
					props.forEach((block, key) => {
						const card = {
							id: `${type}${key}`,
							type,
							...block,
						};
						card[config.cmwCardProps.sortOrder] = this.getSortOrder(card);
						cards.push(card);
					});
				} else {
					const card = {
						id: `${type}${i}`,
						type,
						...props,
					};
					card[config.cmwCardProps.sortOrder] = this.getSortOrder(card);
					cards.push(card);
				}
			});
		}

		// // Weather is now explicitly added to screen.widgets above
		// const weather = widgets.weather;
		// if (screen && weather) {
		// 	const card = {
		// 		id: 'weather',
		// 		type: 'weather',
		// 		...weather,
		// 	};
		// 	card[config.cmwCardProps.sortOrder] = this.getSortOrder(card);
		// 	console.log(card)
		// 	cards.push(card);
		// }

		return cards;
	},

	/*
	 * Validates entities as containing information
	 * Adds properties to entities for this front-end
	 * @param entities array
	 * @param agenciesObject accessible object
	 * @param alertsObject accessible object
	 * @return entities
	 */
	processEntitiesIntoCards(entities, agenciesObject, alertsObject) {
		// On each fresh update, use these arrays to track and sort data
		const ref = {
			cardIds: [],
			bikeshare: {},
			dockless: {},
			columnRows: [], // Tracks Admin UI column digit (1-4) and row digit (01-10)
			cards: {},
		};

		let cards = [];
		// const terrier = entities.filter(e => e.stopName === "Terroirs de France")
		// console.log(terrier)
		entities.forEach((entity, index) => {
			if (isValidEntity(entity)) {
				let card = entity;

				// The order of operations here is critical, do not change or move unless u know

				// Convenience: Assign agency ref
				card.agency = agenciesObject[card.agencyId];

				// ID always required for React tracking
				card.id = this.getUniqueEntityId(card, ref, index);

				// Determines which React component to render this card with
				card.type = this.getEntityType(card);
				card.category = this.getEntityCategory(card);

				// Convenience: Attach row alerts
				if (card.category === 'masstransit' && Object.keys(alertsObject).length > 0) {
					card = this.attachRouteAlerts(card, alertsObject);
				}

				// Determine if this card should be grouped, this will return null if not
				card = this.groupEntities(card, ref);
				if (card) {
					//If the info are about the same agency under the same block, we show them under one card
					let existingCard = card.blockId ? cards.find(c => c.agencyId === card.agencyId && c.blockId === card.blockId) : null;
					// console.log(existingCard)
					// console.log(card)
					if (existingCard) {
						const index = cards.findIndex(c => c.id === existingCard.id)
						if (card.predictions) {
							// console.log(existingCard.predictions)
							// console.log(card)
							if (existingCard.predictions) {
								existingCard["predictions"] = card["predictions"].concat(existingCard["predictions"])
								// console.log(existingCard["predictions"])
							}
							else {
								card[config.cmwCardProps.sortOrder] = this.getSortOrder(card, entities[index - 1]);
								card = this.getCardWithSortedItems(card);
								cards[index] = card;
							}
						}
					}
					else {
						card[config.cmwCardProps.sortOrder] = this.getSortOrder(card, entities[index - 1]);
						card = this.getCardWithSortedItems(card);
						cards.push(card);
					}
				}
			}
		});

		return cards;
	},

	/*
	 * Inserts an `alerts` array into the prediction property for masstransit
	 */
	attachRouteAlerts(card, alertsObject) {
		if (card && card.predictions && card.predictions.length > 0) {
			card.predictions.forEach((item) => {
				item.alerts = [];
				if (item && item.alertIds && item.alertIds.length > 0) {
					item.alerts = item.alertIds.map((alertId) => {
						return alertsObject[alertId];
					});
				}
			});
		}
		return card;
	},

	// Unwrap the context sort order into an object on first pass
	// @return sortOrderPositions object column row group
	getSortOrder(entity, previousEntity) {
		let sortProps = {
			column: 1,
			row: 0,
			group: 0,
		};

		if (entity[config.apiProps.sortOrder] && entity[config.apiProps.sortOrder] !== null) {
			const sortOrderString = entity[config.apiProps.sortOrder].toString(); // ie: "2031" = Column 2, Order 3, Group 1

			const column = parseFloat(sortOrderString[0]);
			const row = parseFloat(`${sortOrderString[1]}${sortOrderString[2]}`);
			const group = parseFloat(sortOrderString[3]);

			// console.log("getSortOrder", column, row, group, sortOrderString);

			sortProps = {
				column,
				row,
				group,
			};
		} else {
			// In Anywhere call, sortOrder is always null

			// In Hub, sometimes you can retrieve the previous entities sort order
			if (previousEntity && previousEntity[config.cmwCardProps.sortOrder]) {
				entity[config.cmwCardProps.sortOrder] = previousEntity[config.cmwCardProps.sortOrder];
				entity[config.cmwCardProps.sortOrder].row += 1;
			}
		}

		return sortProps;
	},

	/*
	 * Very last step to sort predictions or locations
	 * @return card object - data has been transformed or grouped from entities
	 */
	getCardWithSortedItems(card) {
		let itemsKeyValue = config.apiProps.transitItems;
		let sortKeyValue = config.apiProps.transitItemsSort;

		if (card.category === 'dockless') {
			itemsKeyValue = config.apiProps.docklessItems;
			sortKeyValue = config.apiProps.docklessItemsSort;
		}

		if (card[itemsKeyValue] && card[itemsKeyValue].length > 0) {
			card[itemsKeyValue].sort((a, b) => {
				return a[sortKeyValue] - b[sortKeyValue];
			});
		}

		return card;
	},

	/*
	 * Assigns card type and category (for mode)
	 * @return type string or null for cards we dont recognize in the API
	 */
	getEntityCategory(entity) {
		const cats = config.modeCategories;

		let category = null;

		for (var categoryName in cats) {
			let modes = cats[categoryName];
			if (modes.indexOf(entity.agency.mode) !== -1) {
				category = categoryName;
			}
		}
		return category;
	},

	// Widgets wont be read here
	getEntityType(entity) {
		let type = null;
		const types = config.cardTypes;
		if (entity.type) {
			type = entity.type;
			if (entity.flights) {
				type = "flight";
			}
		} else if (entity.agency && types.agencyFullName.traffic.indexOf(entity.agency.fullName) !== -1) {
			// This conditional is unlikely to be used as widgets are now part of screen object
			type = "traffic";
		}
		return type;
	},


	/* We always needs IDs with React! */
	getUniqueEntityId(entity, ref, index) {
		const entityId = entity.id;

		let newId = entityId;

		// Come up with a random ID, for deprecated datasources
		if (!entityId) {
			newId = entity.agency.full_name;
		}

		if (ref.cardIds.indexOf(newId) !== -1) {
			// This means we have a duplicate, use the index as a randomizer
			newId = `${newId}${index}`;
		} else {
			ref.cardIds.push(newId);
		}

		return newId;
	},

	appendAgencyAlertCards(cards, agencies, alerts) {

		let agencyAlertIds = [];
		const agenciesByAlertId = {};

		Object.entries(agencies).forEach((item) => {
			const agency = item[1];
			if (agency && agency.alertIds && agency.alertIds.length > 0) {
				agency.alertIds.forEach((alertId) => {
					agencyAlertIds.push(alertId);
					agenciesByAlertId[alertId] = agency;
				});
			}
		});

		const agencyAlertCards = [];
		if (agencyAlertIds.length > 0) {
			// Uniquify alerts for now, we don't want noisy duplicate alerts
			agencyAlertIds = agencyAlertIds.filter((v, i, a) => a.indexOf(v) === i);

			agencyAlertIds.forEach((alertId) => {
				const alert = alerts[alertId];
				const agency = agenciesByAlertId[alertId];
				const alertCard = {
					id: alert.id,
					type: 'agencyAlert',
					alerts: [alert],
					agencyId: agency.id,
					agency,
				};
				alertCard[config.cmwCardProps.sortOrder] = {
					column: 4,
					row: 0,
					group: 0,
				};
				agencyAlertCards.push(alertCard);
			});
		}

		if (agencyAlertCards.length > 0) {
			agencyAlertCards.forEach((alertCard) => {
				// Insert card after last matching agency card (reverse lookup)
				// And update sortOrder
				const lastAdjacentCard = cards.reverse().find((card) => card.agencyId === alertCard.agencyId);
				if (lastAdjacentCard) {
					const previousCardSortOrder = lastAdjacentCard[config.cmwCardProps.sortOrder];

					alertCard[config.cmwCardProps.sortOrder].column = previousCardSortOrder.column;
					alertCard[config.cmwCardProps.sortOrder].row = previousCardSortOrder.row + 1;
					cards.push(alertCard);
				}
			});
		}

		return cards;
	},


	// Hook into the entities loop to group certain cards together
	// @param entity card
	// @param ref object used for tracking and sort
	// @return entity or null to indicate this card was grouped and does not need to be singled out
	// Nested datasource refs
	groupEntities(entity, ref) {

		let groupedEntity = entity;
		// These are mutually exclusive grouping methods for this app
		// Refer to each function description to see its specific purpose
		if (entity.category === "dockless") {
			groupedEntity = this.groupDocklessByMode(entity, ref);
		} else if (entity.agency && entity.agency.mode === 'bikeshare') {
			groupedEntity = this.groupByAgencySlug(entity, 'locations', ref);
		} else if (entity.agency && entity.agency.mode === 'carshare') {
			groupedEntity = this.groupByAgencySlug(entity, 'locations', ref);
		} else if (entity.category === 'masstransit' && entity[config.apiProps.sortOrder]) {
			groupedEntity = this.groupNestedEntities(entity, 'predictions', ref);
		}

		return groupedEntity;
	},


	/*
	 * TS Admin allows entities to be nested inside a single block
	 * This allows multiple transit agencies to be represented in one Stop Name
	 * This only occurs in a Hub call and never for Anywhere
	 * Auto API represents this by passing the same column and row position
	 * @param entity obj - entity value that will be added or combined
	 * @param groupingKeyValue string - Card object value to group by (arrivals)
	 * @param ref obj - Tracking index array for quick comparison
	 * @return entity obj - Final entity with grouped or null if discarded
	 */
	groupNestedEntities(entity, groupingKeyValue, ref) {

		const sortOrder = entity[config.apiProps.sortOrder].toString(); // ie: "2031" = Column 2, Order 3, Group 1
		const columnRow = sortOrder.slice(0, 3);

		if (ref.columnRows.indexOf(columnRow) !== -1) {
			const holderCard = ref.cards[columnRow];
			if (holderCard[groupingKeyValue] && entity[groupingKeyValue]) {
				holderCard[groupingKeyValue].push(...entity[groupingKeyValue]);
				return null;
			}
		}

		// If we didnt return above, entity is saved to be added to on next loop
		ref.columnRows.push(columnRow);
		ref.cards[columnRow] = entity;

		return entity;
	},

	/*
	 * Auto API serves all dockless cards as separate agencies
	 * This app decides to combine them by agency mode
	 * Presumes multiple agencies
	 * @param entity obj - Card value that will be added or combined
	 * @param ref string - To track saved cards that get added to
	 * @return entity or null
	 */
	groupDocklessByMode(entity, ref) {
		let docklessRefs = ref.dockless;

		// Append agencyId to rows as we will be mixing locations
		if (entity.locations) {
			entity.locations.map((location) => {
				location.agencyId = entity.agencyId;
				return location;
			});
		}

		const mode = entity.agency.mode;
		if (docklessRefs[mode] === undefined) {
			// Create new placeholder
			const placeholderEntity = entity;
			const agencies = {};
			agencies[entity.agency.id] = entity.agency;
			placeholderEntity.agencies = agencies;
			if (!placeholderEntity.locations) placeholderEntity.locations = [];
			docklessRefs[mode] = placeholderEntity; // We have to return var ref not entity itself

			// Decide to throw cards in third column if no sort order is passed
			if (!docklessRefs[mode].sortOrder) {
				docklessRefs[mode].sortOrder = 3099;
			}

			return docklessRefs[mode];
		}

		// Add to placeholder
		docklessRefs[mode].agencies[entity.agency.id] = entity.agency;
		if (entity.locations) docklessRefs[mode].locations = docklessRefs[mode].locations.concat(entity.locations);
		// Ignore card, we added its locations to the existing
		return null;
	},

	/*
	 * Auto API unravels bikeshare & carshare locations, so we must put them back together
	 * This is similar to groupDocklessByMode but we combine on agency slug here
	 * Presumes only one agency per bikeshare card
	 * @param card obj - Card value that will be added or combined
	 * @param groupKey string - Card object value to group by (arrivals)
	 * @return entity - Final card array passed to front-end
	 */
	groupByAgencySlug(card, groupKey, ref) {
		let bikeshareRefs = ref.bikeshare;

		let slug = card.agency.slug;

		// Assign reference to the first bikeshare card found
		if (!bikeshareRefs[slug]) {
			bikeshareRefs[slug] = card;

			// Missing sort order means closed or bad data, push to end of third col
			if (!card.sortOrder) {
				bikeshareRefs[slug].sortOrder = 3099;
			}

			return bikeshareRefs[slug];
		}

		// On next pass, add items to existing bikeshare card ref above
		if (card[groupKey] && card[groupKey].length > 0) {
			const cardLocation = card[groupKey][0];
			// @TODO look at tsdebug.json -- Handle when bikes are not available
			if (bikeshareRefs[slug].locations) {
				bikeshareRefs[slug].locations.push(cardLocation);
			}
		}
		return null;
	},

	sortValuesByKey(arr, key) {
		if (!arr || !arr[0] || !arr[0][key]) return;
		arr.sort(function (a, b) {
			if (a[key] < b[key]) {
				return -1;
			}
			if (a[key] > b[key]) {
				return 1;
			}
			// a must be equal to b
			return 0;
		});
	}
}
