import ReactGA from 'react-ga4';
import config from 'config.js';

const platform = require('platform');

const userAgent = window.navigator.userAgent.toLowerCase();

// Set device model
const ios = /iphone|ipod|ipad/.test(userAgent) || "other";
const deviceWidth = window.outerWidth;
const deviceHeight = window.outerHeight;
let deviceModel = "other";
if (ios) {
    let wh = deviceWidth / deviceHeight;
    let ratio = window.devicePixelRatio;
    if (wh === 320 / 480 && ratio === 1) {
        deviceModel = "iPhone 1/3g/3gs";
    } else if (wh === 640 / 960 && ratio === 2) {
        deviceModel = "iPhone 4/4s";
    } else if (wh === 640 / 1136 && ratio === 2) {
        deviceModel = "iPhone 5/5c/5s";
    } else if (wh === 750 / 1334 && ratio === 2) {
        deviceModel = "iPhone 6/6s/7";
    } else if (wh === 640 / 1136 && ratio === 2) {
        deviceModel = "iPhone 6/6s/7 (Display Zoom)";
    } else if (wh === 1242 / 2208 && ratio === 3) {
        deviceModel = "iPhone 6/6s/7 Plus";
    } else if (wh === 1125 / 2001 && ratio === 3) {
        deviceModel = "iPhone 6/6s/7 Plus (Display Zoom)";
    }
}

// Set userId
let userId = "unknown-noApiKey";
let searchQuery = window.location.search || document.location.search;
let params = new URLSearchParams(searchQuery);
if (params.has("key")) {
    userId = params.get("key");
} else if (process.env.NODE_ENV === "development") {
    userId = "DEV";
}

const paramsObject = {};
const queryParams = new URLSearchParams(searchQuery);
queryParams.toString().split('&').forEach((keyValue) => {
    const pair = keyValue.split('=');
    const [
        key,
        value
    ] = pair;

    //Handling multiple color scheme insert under one parameter... Maybe become separate function if we get more reqs like this
    if (key === 'colorScheme') {
        const colorScheme = decodeURIComponent(value).split(",");
        colorScheme.forEach((color, i) => {
            if (!paramsObject[config.COLOR_PARAMS[i]]) {
                paramsObject[config.COLOR_PARAMS[i]] = color.replace(/[^0-9\A-z\-\,.]/g, '');
            }
        })
    }
    //A regex to apply to all params. May develop into a separate function handling params case by case
    paramsObject[key] = decodeURIComponent(value).replace(/[^0-9\A-z\-\,.]/g, '');
});

// Disable GA for certain customers
var enabled = true;
if (params.has("key")) {
    let key = params.get("key");
    // Goldman Sachs
    if (key === "yZhIpeOJ2mW0FEvHtbNwJEHhdLavMsPT6y8xv6h1cwMhC26ys2qZDVxWLtfPRh6u") {
        enabled = false;
    }
}

// Add custom dimensions
// cd1 key user string
// cd2 locationCode hit string
// cd3 usingCoordinates hit true or false
// cd4 coordinates string
// cd5 externalLinks string
// cd6 navigationBar string
// cd7 openTo string
var cd1 = "not-provided";
var cd2, cd3, cd4, cd5, cd6, cd7;

if (params.has("key")) {
    let key = params.get("key") || "";
    cd1 = key;
}

if (params.has("coordinates")) {
    let coord = params.get("coordinates") || "";
    cd3 = "true";
    cd4 = coord;
}

if (params.has("locationCode")) {
    let code = params.get("locationCode") || "";
    cd2 = code;
    cd3 = "";
}

if (params.has("externalLinks")) {
    cd5 = params.get("externalLinks") || ""
}
if (params.has("navigationBar")) {
    cd6 = params.get("navigationBar") || ""
}
if (params.has("openTo")) {
    cd7 = params.get("openTo") || "";
}

//Storing token in a variable for this single session
let fetchedToken;
let tokenExpirationTime = null;

// console.log("GA Debug Custom Dimensions", cd1, cd2, cd3, cd4, cd5, cd6, cd7);

// Module ===============================================================================

export default {

    enabled,
    userAgent,
    userId,
    deviceModel,

    campaignName: process.env.NODE_ENV === 'development' ? "DEV" : userId,
    campaignSource: process.env.NODE_ENV === 'development' ? "DEV" : "Direct",
    campaignMedium: process.env.NODE_ENV === 'development' ? "DEV" : "Direct",

    gaBatchEndpoint: "https://www.google-analytics.com/batch",
    gaCollectEndpoint: "https://www.google-analytics.com/collect",

    batch: [],
    batchCallNumber: 4, // Execute when this many calls are logged
    userInfo: {

    },

    /* Public methods =============================== */

    recordPageHit() {
        if (!this.enabled || !ReactGA) return null
        ReactGA.event("Field Names", this.getParamsByFieldName());
        //See if this works
        ReactGA.event("Path", {
            category: "Path",
            action: "Path",
            path: window.location.pathname + window.location.search
        });
    },

    universalMappings: {
        ec: "category",
        ea: "action",
        el: "label",
        utc: "category",
        utv: "variable",
        utt: "value",
    },

    recordHit(hit) {
        if (!this.enabled || !ReactGA) return null

        var universalHit = {};

        // Convert keys to Universal Hit which uses Field Names
        for (var k in hit) {
            let hitValue = hit[k];
            if (this.universalMappings[k]) k = this.universalMappings[k];
            universalHit[k] = hitValue;
        }

        // console.log(universalHit);
        // ReactGA has methods for specific hitTypes
        if (hit.t === "event") {
            ReactGA.event("UniversalHit", universalHit);
        } else if (hit.t === "timing") {
            ReactGA.event("UniversalHit", universalHit);
        }
    },

    // Hit must have type and required cats
    // Screenview: {t: 'screenview', cd: screenName }
    // Event: {t: 'event', ea: 'action', ec: 'category', el: 'label' (optional)}
    // Exception: {t: 'exception', sn: screenName, exd: 'description', exf: 'fatal error'}
    // User timing: {t: 'timing', utc, utv, utt, utl optional}
    sendManualHit(hit) {
        if (!this.enabled) return null

        let query = {
            ...this.getParams(),
            ...hit
        }

        // This probably should be encodeURIComponent
        let queryString = this.convertToQueryString(query)

        let options = {
            method: 'POST',
            cors: 'no-cors',
            headers: {
                'User-Agent': this.userAgent,
            },
            body: queryString
        }

        let url = `${this.gaCollectEndpoint}?${queryString}`;

        // console.log("GA URL: ", url);

        return fetch(url, options).then((response) => {
            console.log("GA Single Hit: ", hit.t, response.status, response.url);
        }).catch(e => {
            console.log("GA Single Hit Error: ", e);
        });
    },

    // async sendGetHit(hit) {
    // let query = {
    //     ...this.getRequiredParams(),
    //     ...hit,
    //     ...this.getHelperParams()
    // }

    // // This probably should be encodeURIComponent
    // let queryString = this.convertToQueryString(query)

    // let options = {
    //     method: 'GET',
    //     cors: 'no-cors',
    //     headers: {
    //         'user-agent': this.userAgent,
    //     }
    // }

    // let url = `${this.gaCollectEndpoint}?${queryString}`;

    // GA will not respect a fetch call as GET, must do window.location hack
    // return fetch(url, options).then((response) => {
    //     console.log("GA Single Hit: ", hit.t, response.status, response.url);
    // }).catch(e => {
    //     console.log("GA Single Hit Error: ", e);
    // });
    // },

    /*
     * This uses Field Reference
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
     */
    getParamsByFieldName() {
        return {
            trackingId: config.googleAnalytics4Id, // Our Tracking ID
            userId: this.userId, // Unique user id

            screenResolution: `${deviceWidth}x${deviceHeight}`, // Screen resolution

            mobileDeviceModel: this.deviceModel, // iPhone 6S

            campaignName: this.campaignName, // Campaign name
            campaignSource: this.campaignSource, // Campaign source
            campaignMedium: this.campaignMedium, // Campaign medium

            appVersion: config.version, // App version
            appName: config.internalAppName, // App name

            dimension1: cd1,
            dimension2: cd2,
            dimension3: cd3,
            dimension4: cd4,
            dimension5: cd5,
            dimension6: cd6,
            dimension7: cd7,

            userAgent: this.userAgent, // Force GA to read user agent

            z: Math.round(Math.random() * 1e8) // Cache break
        }
    },

    /* This uses https://developers.google.com/analytics/devguides/collection/protocol/v1/devguide */
    getParams() {
        return {
            v: 1,
            tid: config.googleAnalyticsId, // Our Tracking ID
            uid: this.userId, // Unique user id

            sr: `${deviceWidth}x${deviceHeight}`, // Screen resolution

            mobileDeviceModel: this.deviceModel, // iPhone 6S

            cn: this.campaignName, // Campaign name
            cs: this.campaignSource, // Campaign source
            cm: this.campaignMedium, // Campaign medium

            av: config.version, // App version
            an: config.internalAppName, // App name
            ds: "app", // Datasource, app or web

            cd1,
            cd2,
            cd3,
            cd4,
            cd5,
            cd6,
            cd7,

            ua: this.userAgent, // Force GA to read user agent

            z: Math.round(Math.random() * 1e8) // Cache break
        }
    },

    // For use with manual calls
    convertToQueryString(obj) {
        var str = []
        for (var p in obj) {
            if (obj.hasOwnProperty(p) && obj[p]) {
                str.push(encodeURI(p) + '=' + encodeURI(obj[p]))
            }
        }
        return str.join('&')
    },

    async getToken() {
        const clientId = config.oktaClientId;
        const clientSecret = config.oktaClientSecret;
        const base64Credentials = btoa(clientId + ':' + clientSecret);

        const requestedScopes = encodeURIComponent('data-drop');

        try {
            const res = await fetch(
                config.apiGatewayTokenUrl,
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Basic " + base64Credentials
                    },
                    body: 'grant_type=client_credentials&scope=' + requestedScopes
                }
            );

            if (res.ok) {
                const tokenWrapper = await res.json();
                fetchedToken = tokenWrapper.access_token;
                tokenExpirationTime = new Date(Date.now() + tokenWrapper.expires_in * 1000);
                return tokenWrapper.access_token;
            }
        } catch (e) {

        }
        return null;
    },

    // Data is an object
    // Type is a string phrase 
    async postToDataDrop(data, type) {
        // Data Drop
        if (config.sendToDataDrop) {
            // Gateway token retrieve
            let token = fetchedToken;
            if (token) {
                //Check if token expired
                token = new Date() > tokenExpirationTime ? await this.getToken() : token;
            }
            else {
                //Get new token
                token = await this.getToken();
            }
            try {
                // This simply reports data, no response needed
                await fetch(config.datadropUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        source: 'citymotion-webapp',
                        version: config.version,
                        type: type,
                        metadata: {
                            url: window.location.href,
                            appName: 'Citymotion',
                            appVersion: config.version,
                            browser: platform.name,
                            browserVersion: platform.version,
                            os: platform.os.family,
                            osVersion: platform.os.version,
                            device: platform.product,
                            apiKey: paramsObject.key,
                            coordinates: paramsObject.coordinates
                                ? [
                                    { latitude: paramsObject.coordinates.split(',')[0] },
                                    { longitude: paramsObject.coordinates.split(',')[1] }
                                ]
                                : null,
                            isoDate: new Date().toISOString(),
                        },
                        data: data
                    })
                });
            } catch (e) {
                console.error('postToDataDrop:', e);
            }
        };
    }

}
