import { showTapRowMore, showTapRowOpenApp } from 'utils/params'

import Icon from 'components/Icon';
import Tappable from 'components/common/Tappable';
import lokalise from 'utils/lokalise';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8em;
    font-weight: 200;
    padding: 0.5em 1em 0.5em 1em;
    color: var(--unitText);
    position: relative;
    .left-column {
        flex: 0 1 30%;
        svg * { 
            fill: var(--unitText);
        }
    }
    .right-column {
        flex: 0 1 70%;
        text-align: right;
        svg * { 
            stroke: var(--fadedText);
        }
    }
    .Icon {
        display: inline-block;
        vertical-align: middle;
        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }
    .open-external {
        flex: 1 1 20%;
        text-align: right;
    }
    .TapArea {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
    }
`;

export default function CardBodyHeader({ card, showCardScreen }) {
    const getWalkingInfo = () => {
        if (card.category === "masstransit") {
            const walkMinutes = card.walkMinutes;

            if (walkMinutes === 0 || !walkMinutes) return <></>;

            const mphWalk = 60 / 2;
            // const distanceMiles = Math.floor(walkMinutes) / mphWalk;
            // const distanceText = distanceMiles > 0.1 ? `(${distanceMiles.toFixed(1)} mi)` : '';

            return <><Icon category="modes" name="walk" /> <b>{Math.floor(walkMinutes)}</b> {lokalise.getKey("ts3i")}</>;
        } else if (card.locations && card.locations.length > 0) {
            const walkStart = card.locations[0].walkMinutes || 0;
            const walkEnd = card.locations[card.locations.length - 1].walkMinutes || 0;
            const mins = walkStart === walkEnd ? walkStart : `${walkStart} - ${walkEnd}`;
            return <><Icon category="modes" name="walk" /> <b>{mins}</b> {lokalise.getKey("ts3i")}</>;
        } else if (card.services && card.services.length > 0) {
            const walkStart = card.services[0].pointPrediction;
            const walkEnd = card.services[card.services.length - 1].pointPrediction;
            const mins = walkStart === walkEnd ? walkStart : `${walkStart} - ${walkEnd}`;
            return <><Icon category="modes" name="waiting" /> <b>{mins}</b> {lokalise.getKey("ts3i")}</>;
        }
        return null;
    }
    return (
        <Wrapper>
            <div className="left-column">
                {card && <div className="text">{getWalkingInfo()}</div>}
            </div>
            {/* <Icon className="open-external" category="ui" name="openExternal" /> */}
            <div className="right-column">
                {showCardScreen && <TapAnyRowMessage card={card} />}
            </div>
        </Wrapper>
    )
}

function TapAnyRowMessage({ card }) {
    if (showTapRowOpenApp(card)) {
        return <>{lokalise.getKey('action_tap_row_app')} <Icon className="open-external" category="ui" name="phone" /></>;
    } else if (showTapRowMore(card)) {
        return <>{lokalise.getKey('action_tap_row_more')}</>;
    }
    return null;
}