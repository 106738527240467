import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import PoweredByAF from './PoweredByAF';
import config from 'config';
import lokalise from 'utils/lokalise';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

const Wrapper = styled.div`
    padding: 1em;
    .badge-area {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .how {
        font-size: 0.8em;
    }
    p {
        padding-bottom: 0.5em;
    }
    .icon {
        padding: 1em;
        flex: 0 0 25%;
        min-height: 10em;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 100%;
            height: auto;
        }
    }
    .info {
        font-weight: 200;
        b {
            letter-spacing: 2px;
        }
        padding-left: 1em;
        flex: 0 1 auto;
    }
    .learn-more {
        text-align: center;
    }
    button {
        display: inline-block;
        margin: 1em;
        padding: 1em;
        background: var(--cardBackground);
        box-shadow: 0 0.2em 0.2em var(--cardBoxShadow);
        border-radius: 0.5em;
    }
`;

export default function MobilityScoreBadge({ startLocation }) {

    const [badge, setBadge] = useState(null);

    useEffect(() => {
        let componentMounted = true;
        if (startLocation) {
            fetchBadge({ startLocation, componentMounted });
        }
        return () => {
            componentMounted = false;
        }
    }, [startLocation]);

    function clickLearnMore() {
        window.open('https://actionfigure.ai/mobilityscore/', '_new');
    }

    function fetchBadge({ startLocation, componentMounted }) {
        setBadge(null);
        const mobScoreURL = `${config.mobilityScoreURL}?coordinates=${startLocation.coords.latitude},${startLocation.coords.longitude}&key=${config.mobilityScoreKey}`;
        fetch(mobScoreURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((json) => {
                if (componentMounted) {
                    setBadge(json.data);
                }
            })
            .catch((error) => {
                // console.error('Something went wrong:', error);
            });
    }

    function getDescription() {
        let key = "";
        // console.log(badge);
        if (badge.score >= 80) {
            key = "mobscore_greater_80";
        } else if (badge.score >= 60 && badge.score < 80) {
            key = "mobscore_60_80";
        } else if (badge.score >= 40 && badge.score < 60) {
            key = "mobscore_40_60";
        } else if (badge.score >= 20 && badge.score < 40) {
            key = "mobscore_20_40";
        } else if (badge.score >= 20 && badge.score < 1) {
            key = "mobscore_lessthan_20";
        } else {
            key = "mobscore_0_1";
        }
        return lokalise.getKey(key);
    }

    return (
        <Wrapper>
            <b>{lokalise.getKey('mobscore_title')}</b>
            <div className="badge-area">
                <div className="icon">
                    {badge?.badgeUrl ? <Badge badgeUrl={badge.badgeUrl} /> : 'Getting score...'}
                </div>
                <div className="info">
                    <p><b>MOBILITYSCORE<small>®</small></b></p>
                    {badge && getDescription()}
                    {!badge?.badgeUrl && '...'}
                </div>
            </div>

            <div className="how">
                <p><b>{lokalise.getKey('mobscore_how_title')}</b></p>
                {lokalise.getKey('mobscore_how_desc')}               <br />
                <br />

                <div className="learn-more">
                    <button onClick={clickLearnMore}>
                        <Icon category="ui" name="afIcon" />
                        {lokalise.getKey('button_learn_more')}
                    </button>
                </div>

            </div>

        </Wrapper>
    )
}

function Badge({ badgeUrl }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [markup, setMarkup] = useState(null);

    if (searchParams.has('accentColor')) {
        badgeUrl += `&secondaryColor=${searchParams.get('accentColor')}`;
    }

    useEffect(() => {
        fetch(badgeUrl)
            .then((response) => response.text())
            .then((response) => {
                if (response.includes('<img')) {
                    setMarkup(response);
                } else if (response.includes('data:image/svg+xml')) {
                    const parse = response.split(',');
                    const buf = Buffer.from(parse[1], 'base64');
                    setMarkup(buf);
                }
            });
    }, [badgeUrl]);

    function getMarkup() {
        return { __html: markup }
    }

    return (<>
        {markup && <div dangerouslySetInnerHTML={getMarkup()} />}
    </>)
}