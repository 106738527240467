import React, {
    Component,
} from 'react';

import CardColumnsHelper from 'utils/cardcolumns';
import CardList from 'components/MobilityScreen/CardList';
import CardListStatus from 'components/MobilityScreen/CardListStatus';
import Cookies from 'universal-cookie';
import config from 'config';
import memoize from 'memoize-one';

// @TODO Use of UniversalCookie as global declared needs to be refactored
// User cookie settings should probably move to some kind of localStorage or other 
// universal user data store
const UniversalCookie = new Cookies();
const data = UniversalCookie.get(config.cookies.userSettings.name);
const showNoArrivals = data && data.showNoArrivals !== undefined ? data.showNoArrivals : false;
let running = false;
let currHeight = 0;
let currCard = null;
let delay = 25;
let cardColumns = document.querySelectorAll('.CardList');
let curColumnIndex = 0;
let currCardHeight;

class CardColumns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfColumns: 1,
            cardColumns: [],
            refreshFavorites: true
        }
        this.browserWidthChange = this.browserWidthChange.bind(this);
        this.getNumberOfColumns = this.getNumberOfColumns.bind(this);
        this.slowMove = this.slowMove.bind(this);
        this.getCardLists = this.getCardLists.bind(this);
    }

    componentDidMount() {
        this.browserWidthChange();
        window.addEventListener("resize", this.browserWidthChange.bind(this));
        cardColumns = document.querySelectorAll('.CardList');
    }

    componentDidUpdate() {
        cardColumns = document.querySelectorAll('.CardList');
        //TODO: We should refine the params to get the correct types 
        if (!running && this.props.params.scroll === 'true') {
            running = true
            setTimeout(() => {
                this.slowMove();
            }, 500);
        }
    }

    browserWidthChange() {
        const maxColumns = this.props.params.maxColumns;
        this.setState({
            numberOfColumns: this.getNumberOfColumns(maxColumns)
        });
    }


    slowMove() {
        if (cardColumns[curColumnIndex]?.querySelectorAll('article.visible.TransportationCard')[0]) {

            //Get last card and compare to bottom
            const numCardsInCol = cardColumns[curColumnIndex]?.querySelectorAll('article.visible').length;
            const lastCard = cardColumns[curColumnIndex]?.querySelectorAll('article.visible')[numCardsInCol - 1]
            if (window.innerHeight > lastCard.getBoundingClientRect().bottom && !currCard) {
                curColumnIndex++;
                if (curColumnIndex >= cardColumns.length) {
                    curColumnIndex = 0;
                }
            }
            else {
                //Pick a new card to move if we went to height 0 just now
                if (!currCard) {
                    //Gets all transportation the cards that are currently showing
                    currCard = cardColumns[curColumnIndex]?.querySelectorAll('article.visible.TransportationCard')[0];
                    //Getting full height, including border
                    currHeight = currCard?.offsetHeight;
                    currCard.style.height = currHeight + 'px';
                    currCardHeight = currHeight;
                }
                currHeight = currHeight - 2;
                currCard.style.height = currHeight + 'px';
                //This actually scrolls the card to the top and makes it disappear
                currCard.scrollTop = currCardHeight - currHeight;

                if (currHeight <= 0) {
                    //Adds first card to the end
                    currCard?.parentElement?.appendChild(currCard);
                    currCard.style.height = 'auto';
                    currCard.scrollTop = 0;
                    currCard = null;
                    //Moves to next column of cards
                    curColumnIndex++;
                    if (curColumnIndex >= cardColumns.length) {
                        curColumnIndex = 0;
                    }
                }
            }

            setTimeout(() => {
                delay = 25;
                this.slowMove();
            }, delay || 25);
        }
        //In the case that we're scrolling already but for some reason there were no cards, go back to first column
        else if (running) {
            curColumnIndex = 0;
            setTimeout(() => {
                delay = 25;
                this.slowMove();
            }, delay || 25);
        }
        else {
            running = false
        }
    }

    getNumberOfColumns(maxColumns) {
        let width = window.innerWidth;
        let colCount;
        if (!width || width < 600) {
            colCount = 1;
        } else if (width >= 600 && width < 900) {
            colCount = 2;
        } else if (width >= 900 && width < 1200) {
            colCount = 3;
        } else if (width >= 1200) {
            colCount = 4;
        }
        return colCount > maxColumns ? parseInt(maxColumns) : colCount
    }

    /*
     * Allows Cards to trigger reflow for Favoriting
     * Just a state trigger, refreshFavorites does nothing
     * @param action string - "add" fav or "remove" unfav
     */
    refreshCardColumns = (action) => {
        this.setState({
            refreshFavorites: true
        });
    }

    getCardLists(filteredCardColumns) {
        const {
            cards,
            screen,
            appLocation,
            weather,
            alerts,
            selectedTab,
            params,
            toggleCardScreen,
            setCardFavorite,
            showCardScreen,
        } = this.props;
        return filteredCardColumns.map((filteredCards, i) => {
            return (
                <CardList
                    key={i}
                    cards={filteredCards}
                    screen={screen}
                    appLocation={appLocation}
                    weather={weather}
                    alerts={alerts}
                    selectedTab={selectedTab}
                    params={params}
                    refreshCardColumns={this.refreshCardColumns}
                    toggleCardScreen={toggleCardScreen}
                    setCardFavorite={setCardFavorite}
                    showCardScreen={showCardScreen}
                />
            );
        })
    };

    /*
     * Applies user settings in regards to card data
     * Sorting and favs are predone in CardColumns
     * memoize will cache and re-serve the result if values do not change on state update
     */
    getFilteredCards = memoize((cards, modeFilterButtons, selectedTab) => {

        let hiddenCount = 0;
        let filteredCards = [];
        cards.forEach((card) => {

            // Step 1. Apply show no arrivals to masstransit
            if (showNoArrivals !== undefined && showNoArrivals === false) {
                if (card.category === 'masstransit' && card.predictions && card.predictions.length === 0) {
                    return null;
                }
            }

            // Step 2. Visibility per selectedTab 
            card.visible = true;
            if (selectedTab && selectedTab !== 'all') {
                let selectedModes = modeFilterButtons[selectedTab].modes;
                if (card.agency && selectedModes.indexOf(card.agency.mode) !== -1) {
                    card.visible = true;
                } else {
                    card.visible = false;
                    hiddenCount += 1;
                }
            }
            filteredCards.push(card);
        });
        return {
            filteredCards,
            hiddenCount
        }
    })

    /*
     * Applies user settings in regards to card data
     * Sorting and favs are predone in CardColumns
     * Do not memoize here as CardColumnsHelper will update with Favorites order
     */
    getFilteredCardColumns = (cards, screen, numberOfColumns, params) => {
        let cardColumns = [];
        if (screen) {
            cardColumns = CardColumnsHelper.getCardColumnsHub(cards, numberOfColumns, params);
        } else {
            cardColumns = CardColumnsHelper.getCardColumns(cards, numberOfColumns, params);
        }
        return cardColumns;
    };

    render() {
        const {
            params,
            action,
            cards,
            screen,
            selectedTab,
            appLocation,
            userLocation,
        } = this.props;

        const {
            numberOfColumns
        } = this.state;

        const {
            modeFilterButtons
        } = config;

        // These filtering things should not run in render
        const filtered = this.getFilteredCards(cards, modeFilterButtons, selectedTab);
        const {
            filteredCards,
            hiddenCount
        } = filtered;

        const filteredCardColumns = this.getFilteredCardColumns(filteredCards, screen, numberOfColumns, params);

        // For performance, maintain Cardlist comps as Card comps will hide themselves with CSS
        return (
            <div id="CardColumns" role="tabpanel" className="CardColumns">

                {hiddenCount === filteredCards.length ? <CardListStatus selectedTab={selectedTab} action={action} /> : null}

                {this.getCardLists(filteredCardColumns)}

            </div>
        );
    }
}

export default CardColumns
