import React, {
	Component
} from 'react';
import {
	isAndroid,
	isIOS,
	isMobile
} from 'react-device-detect';

import Analytics from 'utils/analytics';
import Icon from 'components/Icon';
import Tappable from 'components/common/Tappable';

// Override JSON external links for webview
// This is for the parser rows, not for the editable agency app info
const agencySlugLinkReplacements = {
	uber: {
		uri: "uber://",
		replacement: "https://m.uber.com/ul/",
	},
	lyft: {
		uri: "lyft://",
		replacement: "https://lyft.com/",
	},
	citibike: {
		uri: "citibike://"
	}
}

class ExternalAppButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			externalLink: false,
		}

		this.handleTapEvent = this.handleTapEvent.bind(this);
		this.canShowLink = this.canShowLink.bind(this);
		this.getLink = this.getLink.bind(this);
		// console.log("External App Getlink?", props.universalLink);
	}

	componentDidMount() {
		if (this.canShowLink()) {
			let linkType = this.props.linkType;
			// Need to be told what kind of link to parse out
			if (linkType) {
				let link = this.getLink(linkType);
				if (link) {
					this.createLink(link);
				}
			}
		}
	}

	canShowLink() {
		let canShowLink = false;
		// External app buttons may only show up by a URL parameter
		let searchQuery = window.location.search || document.location.search;
		let params = new URLSearchParams(searchQuery);
		canShowLink = params.has("externalLinks"); // Accept any value such as true, universal, all
		if (process.env.NODE_ENV === 'development') canShowLink = true;
		return canShowLink;
	}

	/*
	 * @params linkType string
	 * Reads the agency object for links for the current phone we are on
	 * @return string URL link
	 * @return null if cannot be generated
	 */
	getLink(linkType) {

		if (!isMobile) return null;

		if (linkType === "GET_VEHICLE") {
			let link = this.props.universalLink;
			if (!link && this.props.agency.app.iosUniversalLink) {
				link = this.props.agency.app.iosUniversalLink
			} else if (!link && this.props.agency.app.androidUniversalLink) {
				link = this.props.agency.app.androidUniversalLink
			} else {
				// Experimental, just give app store link
				link = this.getAppStoreLink();
			}
			return link;
		}

		if (linkType === "GET_MOBILE_TICKET" || linkType === "GET_APP") {
			// Feature can specify universal links only
			let searchQuery = window.location.search || document.location.search;
			let params = new URLSearchParams(searchQuery);
			let linkContext = params.get("externalLinks");

			if (isIOS) {
				if (linkContext === "universal") {
					if (this.props.agency.app.iosUniversalLink) {
						return this.props.agency.app.iosUniversalLink;
					}
				} else {
					if (this.props.agency.app.iosUri) {
						return this.props.agency.app.iosUri;
					} else if (this.props.agency.app.iosUniversalLink) {
						return this.props.agency.app.iosUniversalLink;
					}
				}
			} else {
				// Were going to assume all other devices are Android
				// isAndroid doesnt work with older devices
				if (linkContext === "universal") {
					if (this.props.agency.app.androidUniversalLink) {
						return this.props.agency.app.androidUniversalLink;
					}
				} else {
					if (this.props.agency.app.androidUri) {
						return this.props.agency.app.androidUri;
					} else if (this.props.agency.app.androidUniversalLink) {
						return this.props.agency.app.androidUniversalLink;
					}
				}
			}
		}
	}

	/*
	 * @params link string URL
	 */
	createLink(link) {

		// Special: Swap Universal Link for URI instead
		if (this.props.agency && this.props.agency.slug) {
			let agencySlug = this.props.agency.slug;
			if (agencySlugLinkReplacements[agencySlug]) {
				if (this.props.linkType === "GET_VEHICLE") {
					let uri = agencySlugLinkReplacements[agencySlug].uri;
					link = link.replace(agencySlugLinkReplacements[agencySlug].replacement, uri);
				}
			}
			// console.log("SPECIAL", link);
		}


		// Append parameter for native code handling
		var linkParams = link.includes("http") ? 'CM_UNIVERSAL_LINK' : 'CM_URI';

		// Append store link for failures
		let storeLink = this.getAppStoreLink();
		if (storeLink) {
			linkParams = `${linkParams}&APP_STORE_LINK=${storeLink}`;
		}

		// Finally append it to the link
		link = link.includes("?") ? `${link}&${linkParams}` : `${link}?${linkParams}`;

		// console.log("FINALLY", link);

		this.setState({
			externalLink: link
		})
	}

	getAppStoreLink() {
		var storeLink = false;
		if (this.props.agency) {
			if (isIOS && this.props.agency.app.appStoreId) {
				storeLink = `https://apps.apple.com/us/app/${this.props.agency.app.appStoreId}`;
			} else if (isAndroid && this.props.agency.app.playStoreId) {
				storeLink = `https://play.google.com/store/apps/details?id=${this.props.agency.app.playStoreId}`;
			}
		}
		return storeLink
	}

	handleTapEvent() {
		var link = this.state.externalLink;

		Analytics.recordHit({
			t: 'event',
			ec: 'ExternalAppButton',
			ea: 'Tap Event',
			el: link
		});

		Analytics.postToDataDrop({
			"agencyId": this.props.agency.id,
			"agencyName": this.props.agency.fullName,
			"agencyMode": this.props.agency.mode,
			"appName": this.props.agency.app.appName,
			"appLink": link,
		}, "Mobile App Selection");

		// console.log("TAP EVENT", link);
		if (isMobile) {
			window.location.href = link;
		} else if (!isMobile && link.includes("http")) {
			// For desktop univeral links, open in new window
			window.open(link, "_new");
		} else {
			// For desktop URI links
			window.alert("Sorry this link doesn't work for your web browser.");
		}
	}

	render() {
		if (!this.state.externalLink) {
			return null;
		}

		const voText = this.props.agency ? `Tap to open ${this.props.agency.app.appName} app` : `Tap to open app`;

		// This component can also function as just a standalone wrapper or a floated tap area
		// Button text and arrow are optional
		return (
			<Tappable stopPropagation onTap={this.handleTapEvent} className={`ExternalAppButton ${this.props.className || ''}`}>
				<span className="voiceover-text">{voText}</span>
				{this.props.buttonText && <>
					<span aria-hidden="true">{this.props.buttonText}</span>
					<Icon category="ui" name="appButtonArrow" />
				</>}
			</Tappable>
		)
	}
}

export default ExternalAppButton
