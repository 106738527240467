import Icon from 'components/Icon';
import { useState } from 'react';

function DateTimeSelector({
    locked,
    type,
    startDate,
    setStartDate,
    startTime,
    setStartTime,
}) {

    // const onBlur = (input) => {
    //     if (!input) {
    //         setInputType('text')
    //     }
    // }

    const todayDate = new Date();

    let day = todayDate.getDate();
    let month = todayDate.getMonth() + 1;
    let year = todayDate.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    const defaultTodayDate = year + "-" + month + "-" + day;

    let minutes = todayDate.getMinutes();
    if (minutes < 10) minutes = "0" + minutes;

    const defaultNowTime = todayDate.getHours() + ":" + minutes;

    return (
        <div className="DateTimeSelector">
            <div className="icon">
                <Icon category="ui" name={type === 'date' ? 'calendar' : 'clock'} />
            </div>
            <div className="picker">
                {
                    type === 'date'
                        ? <input
                            className="dateInput"
                            type={type}
                            // onFocus={() => setInputType(type)} 
                            // onBlur={() => onBlur(startDate)}
                            value={startDate || defaultTodayDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            disabled={locked}
                        />
                        : <input
                            className="timeInput"
                            type={type}
                            // onFocus={() => setInputType(type)} 
                            // onBlur={() => onBlur(startTime)}
                            value={startTime || defaultNowTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            disabled={locked}
                        />
                }
            </div>
        </div>
    )
}

export default DateTimeSelector;
