import React, {
    Component
} from 'react';

import ExternalAppButton from 'components/Card/ExternalAppButton';
import MobileTicketButton from 'components/Card/MobileTicketButton';
import lokalise from 'utils/lokalise';

function CardFooter({
    opened,
    showCardScreen,
    card,
}) {

    // Dont show footer in non opened view due to border top
    if (showCardScreen) {

        function getExternalAppButton() {
            if (card.agency.mode === "bikeshare" || card.agency.mode === "carshare") {
                return (<ExternalAppButton buttonText={lokalise.getKey('ts1h')} linkType="GET_APP" agency={card.agency} />)
            }
            return null;
        }

        function getMobileTicketButton() {
            if (card && card.agency && card.category === "masstransit") {
                return (<MobileTicketButton card={card} />)
            }
            return null;
        }

        const button1 = getExternalAppButton();
        const button2 = getMobileTicketButton();

        if (button1 || button2) {
            return (
                <div className="CardFooter">
                    {button1}
                    {button2}
                </div>
            );
        }
    }

    return null;
}

export default CardFooter;
