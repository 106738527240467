import Icon from 'components/Icon'
import styled from "styled-components"
const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 1em;
    height: 60px;
    width: auto;
    color: var(--appHeaderText);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
        margin-right: 0.25em;
        height: 2em;
        width: auto;
        * {
        fill: var(--appHeaderText);
        }
    }
`;
export default function WeatherTag({ weather }) {

    const getWeather = () => {
        if (weather && weather.forecasts) {
            const forecast = weather.forecasts[0];

            return <div className="weather">
                <p>{forecast.temperatureFahrenheit}° F</p>
                <p>{forecast.temperatureCelsius}° C</p>
            </div>
        }
        return null;
    }

    const getIcon = () => {
        if (weather && weather.forecasts) {
            const forecast = weather.forecasts[0];
            return <Icon category="weather" name={forecast.icon} />;
        }
        return null;
    }

    return (
        <Wrapper>
            {getIcon()}
            {getWeather()}
        </Wrapper>
    )
}