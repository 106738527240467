import React, {
	Component
} from 'react';

import Icon from 'components/Icon';
import Moment from 'react-moment';
import MomentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import config from 'config.js';
import iconLibrary from 'img/svg/icon-library';
import lokalise from 'utils/lokalise';

class WeatherCard extends Component {

	constructor(props) {
		super(props);
		this.state = {};
		this.getWeatherBlocks = this.getWeatherBlocks.bind(this);
	}

	getTime(forecast, index) {
		const {
			screen,
			params,
		} = this.props;
		const {
			timezone,
		} = screen;
		const {
			lang,
		} = params;


		if (index === 0) return lokalise.getKey('ts8f');

		let format = 'h a';
		if (params.timeDisplay === 'actual') {
			format = 'h:00'
		}
		else if (params.timeDisplay === 'actual24') {
			format = 'HH:00'
		}

		if (lang) {
			if (lang.includes('fr')) {
				format = 'HH:00';
			}
		}

		return (
			<Moment unix tz={timezone} format={format}>
				{(forecast.timestamp + 3600000) / 1000}
			</Moment>
		);
		// let date = new Date(forecast.timestamp);
		// let hours = date.getHours();
		// let ampm = hours < 12 ? 'am' : 'pm';
		// hours = hours > 12 ? hours - 12 : hours;
		// return `${hours}${ampm}`;
	}


	getIcon(forecast) {
		let forecastIconName = forecast.icon;

		if (!forecastIconName) {

			// Try to determine if any Summary words match existing
			let label = forecast.label && forecast.label.toLowerCase() || '';
			let labels = label.split(' ');
			labels.forEach((word) => {
				if (iconLibrary.weather[word]) {
					forecastIconName = word;
				}
			})

			// If nothing is found return sun or moon
			if (!forecastIconName) {
				let now = new Date();
				let hours = now.getHours();
				forecastIconName = (hours > 7 && hours < 17) ? 'clear-day' : 'clear-night';
			}
		}

		return <Icon category="weather" name={forecastIconName} />;
	}

	getTemperature(forecast) {
		return `${forecast.units === 'C' ? forecast.temperatureCelsius : forecast.temperatureFahrenheit}°`;
	}

	getWeatherBlocks() {
		const {
			card,
		} = this.props;
		const {
			forecasts,
			rowLimit,
		} = card;

		let displayedRowLimit = rowLimit || config.defaultRowLimit.weather;

		if (!forecasts || !forecasts.length) return null;

		return forecasts.map((forecast, i) => {
			if (i >= displayedRowLimit) return null;
			const temperature = this.getTemperature(forecast);
			const time = this.getTime(forecast, i);
			const icon = this.getIcon(forecast);

			const label = forecast.label ? lokalise.getReverseKey(forecast.label) : null;

			return (
				<div role="listitem" key={forecast.timestamp || 'now'} className="Forecast">
					<div className="header">
						<div className="Icon">{icon}</div>
						<div className="Label">{label}</div>
					</div>
					<div className="content">
						<div className="Time">{time}</div>
						<div className="Temperature">{temperature}</div>
					</div>
				</div>
			);
		});
	}

	render() {
		return (
			<div className="WidgetContent" role="group" aria-describedby="WeatherHeader">
				<h2 id="WeatherHeader" className="voiceover-text">{lokalise.getKey("ts10y")}</h2>
				<div className="Blocks">
					{this.getWeatherBlocks()}
				</div>
			</div>
		);
	}
}

WeatherCard.propTypes = {
	card: PropTypes.shape({
		forecasts: PropTypes.array,
	}),
	screen: PropTypes.shape({
		timezone: PropTypes.string
	})
};

WeatherCard.defaultProps = {
	card: {
		forecasts: null,
	},
	screen: {
		timezone: MomentTimezone.tz.guess(),
	},
};

export default WeatherCard;
