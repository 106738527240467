import { Step, StepList } from 'components/TripScreen/TripPlanScreen/TripPlanContent/StepList';

import Icon from 'components/Icon';
import tripscreen from 'utils/tripscreen.js';

function TripPlanContentStepBased({ tripPlan, endingLocation }) {

    // console.log("STEPBASED", tripPlan);
    return (
        <div className="TripPlanContent TripPlanContentStepBased">

            <div className="directions">

                {tripPlan?.legs?.map((leg, i) => {

                    let verb = '';
                    if (leg.mode === 'walk') verb = 'walk';
                    if (leg.mode === 'bike') verb = 'bike';
                    if (leg.mode === 'car') verb = 'drive';

                    return (

                        <StepList key={i}>
                            <Step
                                modeIcon={leg.mode}
                                icon={tripscreen.getIcon(i, tripPlan.legs)}
                                leg={leg}
                                expandable={true}
                                expandedContent={<ExpandedContent leg={leg} endingLocation={endingLocation} />}
                            >
                                <div className="header">
                                    <b>{tripscreen.getDuration(leg.duration)} {verb}</b> ({tripscreen.getDistance(leg.distance)})
                                </div>
                                Your destination<br />
                                {endingLocation?.address?.split(',')[0]}<br />
                                {endingLocation?.address?.split(',')[1] && endingLocation?.address?.split(',')[1] + ','}
                                {endingLocation?.address?.split(',')[2]}
                            </Step>
                        </StepList>
                    );
                }
                )}

            </div>
        </div>
    )
}


function ExpandedContent({ leg, endingLocation }) {
    return (
        <StepList>
            {leg?.directions?.map((direction, i) => {
                return (
                    <Step
                        key={i}
                        maneuverType={direction.maneuverType}
                        icon={tripscreen.getIcon(i, leg?.directions)}
                    >
                        <div className="header"><b>{direction?.instruction}</b></div>

                        {direction.distance > 50 && <span className="time">{tripscreen.getDistance(direction.distance)}</span>}
                        {" "}
                        {(i === leg.directions.length - 1) && <>
                            {endingLocation && endingLocation?.address}
                        </>}
                    </Step>
                )
            })}
        </StepList>
    )
}

export default TripPlanContentStepBased;
