import React, {
	Component
} from 'react';

import LokaliseHelper from 'utils/lokalise';

// Warnings, notices, errors, etc
export default class CardListStatus extends Component {

	getMessage() {
		const {
			action,
			selectedTab,
		} = this.props;

		if (action === 'APP_START') {
			return "";
		}

		if (action === 'SET_NAVIGATION') {
			return LokaliseHelper.getKey('ts9a');
		}

		switch (selectedTab) {
			case 'bus':
				return LokaliseHelper.getKey('ts4f', {
					vehicles: LokaliseHelper.getKey('ts1m').toLowerCase()
				}).join('');
			case 'train':
				return LokaliseHelper.getKey('ts4f', {
					vehicles: LokaliseHelper.getKey('ts1n').toLowerCase()
				}).join('');
			case 'car':
				return LokaliseHelper.getKey('ts4f', {
					vehicles: LokaliseHelper.getKey('ts1o').toLowerCase()
				}).join('');
			case 'bike':
				return LokaliseHelper.getKey('ts4f', {
					vehicles: LokaliseHelper.getKey('ts1p').toLowerCase()
				}).join('');
			case 'shuttle':
				return LokaliseHelper.getKey('error_no_options_generic')
			default:
				return LokaliseHelper.getKey('error_no_options_generic');
		}
	}

	render() {
		return (
			<div className="CardListStatus">
				{this.getMessage()}
			</div>
		);
	}
}
