import AIResults from 'components/TripScreen/TripPlanScreen/AIResults';
import ButtonGroup from 'components/common/ButtonGroup';
import CardScreenHeader from 'components/common/CardScreenHeader';
import ExitButton from 'components/ExitButton';
import TripPlanContent from 'components/TripScreen/TripPlanScreen/TripPlanContent/TripPlanContent';
import TripPlanMap from 'components/TripScreen/TripPlanScreen/TripPlanMap';
import analytics from 'utils/analytics';
import config from 'config';
import googlemaps from 'utils/googlemaps';
import lokalise from 'utils/lokalise';
import tripscreen from 'utils/tripscreen';

function TripPlanScreen({
    selectedTripPlan,
    showTripPlanScreen,
    setTripPlanScreen,
    startingLocation,
    endingLocation
}) {
    function dimissScreen() {
        setTripPlanScreen(false);
    }

    function getGMLinkStartToEnd() {
        // Translate Journey API name field to Google Maps travelmode param
        let travelMode = "transit";
        if (selectedTripPlan.name === "Walking") {
            travelMode = "walking";
        } else if (selectedTripPlan.name === "Car" || selectedTripPlan.name === "Driving and Parking") {
            travelMode = "driving";
        } else if (selectedTripPlan.name === "Bicycle") {
            travelMode = "bicycling";
        }

        const url = googlemaps.getGMDirectionsURL({ startingLocation, endingLocation, travelMode });
        return url;
    }

    function getGMLinkStartToFirst() {
        // Translate Journey API name field to Google Maps travelmode param
        let travelMode = "walking";
        if (selectedTripPlan.name === "Transit") {
            travelMode = "transit";
        } else if (selectedTripPlan.name === "Car" || selectedTripPlan.name === "Driving and Parking") {
            travelMode = "driving";
        } else if (selectedTripPlan.name === "Bicycle") {
            travelMode = "bicycling";
        }
        const destinationLocation = {
            coords: {
                latitude: selectedTripPlan.legs[0].to.coordinates.latitude,
                longitude: selectedTripPlan.legs[0].to.coordinates.longitude,
            }
        }
        const url = googlemaps.getGMDirectionsURL({ startingLocation, endingLocation: destinationLocation, travelMode });
        return url;

    }

    function getDestination() {
        if (selectedTripPlan && selectedTripPlan.legs && selectedTripPlan.legs[0].to.name) {
            return selectedTripPlan.legs[0].to.name;
        } else if (endingLocation && endingLocation.name) {
            return endingLocation.name;
        }
    }

    const buttons = [{
        iconCategory: 'ui',
        iconName: 'googleMapsIcon',
        text: lokalise.getKey('button_start_your_trip'),
        onClick: () => {
            const targetUrl = getGMLinkStartToFirst();

            analytics.postToDataDrop({
                googleMapsUrl: targetUrl,
            }, 'Trip Plan Screen Button Tap: Start GM Trip');

            window.open(targetUrl, "_new");
        }
    }];

    function getScreenHeaderText() {
        if (selectedTripPlan?.duration) {
            return <>
                {lokalise.getKey('tripplan_min_trip', { time: tripscreen.getDuration(selectedTripPlan.duration) })} <span className="divider">/</span> {tripscreen.getEstimatedArrival(selectedTripPlan.duration / 60)}
            </>
        }
        return '';
    };

    return (
        <div className="TripPlanScreen">

            <header className="screen-header">
                <CardScreenHeader text={getScreenHeaderText()} tapEvent={() => dimissScreen()} />
            </header>

            <div className="body">

                <div className="map">
                    <TripPlanMap showTripPlanScreen={showTripPlanScreen} tripPlan={selectedTripPlan} startingLocation={startingLocation} endingLocation={endingLocation} />
                </div>

                <div className="content">
                    <h2>{lokalise.getKey('tripplan_what_to_expect')}</h2>
                    <TripPlanContent name={selectedTripPlan?.name} tripPlan={selectedTripPlan} endingLocation={endingLocation} />
                    <ButtonGroup buttons={buttons}></ButtonGroup>
                    <AIResults tripOption={selectedTripPlan} />
                </div>

            </div>
        </div>
    )
}

export default TripPlanScreen
