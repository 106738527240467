import React, {
    Component
} from 'react';

import FavoriteButton from 'components/Card/FavoriteButton';
import Icon from 'components/Icon';
import lokalise from 'utils/lokalise';

class CardHeader extends Component {

    getLocalization(cardTitle) {
        // console.log("Card Title?", cardTitle);
        switch (cardTitle) {
            case "scootershare":
                return lokalise.getKey("ts3w");
            case "sit-scootershare":
                return lokalise.getKey("ts3x");
            case "ebikeshare":
                return lokalise.getKey("ts3u");
            case "pointtopoint":
                return lokalise.getKey("ts3v");
            case "bikeshare":
                return lokalise.getKey("ts3s");
            case "dockless-bikeshare":
                return lokalise.getKey("ts3t");
            default:
                return cardTitle
        }
    }

    getVOLabel() {
        const {
            card,
        } = this.props;

        const {
            category,
            agency,
            stopName,
            type
        } = card;

        const {
            mode,
            fullName,
        } = agency;

        let cardTitle = stopName;

        if (category === 'masstransit') {
            return `Transit stop arrivals at ${cardTitle}`;
        }
        if (category === 'dockless') {
            if (!cardTitle) cardTitle = this.getLocalization(mode);
            return `Nearby options for ${cardTitle}`;
        }
        if (category === 'docked') {
            if (!cardTitle) cardTitle = fullName;
            return `Nearby bikeshare options for ${cardTitle}`;
        }
        if (category === 'floating') {
            if (!cardTitle) cardTitle = fullName;
            return `Nearby rideshare options for ${cardTitle}`;
        }
        if (type === 'parking') {
            return cardTitle || `Parking`;
        }

        return null;
    }

    render() {

        const {
            hasMaps,
            params,
            card,
            opened,
            tapOpenCardView,
            showCardScreen,
            isFavorite,
            setCardFavorite,
        } = this.props;

        const {
            ui,
        } = params;

        let agencyLogo = card?.agency.style.logoOnlightSvgUrl || card?.agency.style.logoSvgOnlight || null;
        if (agencyLogo) agencyLogo = agencyLogo.replace('http://', '//');

        let stopName = card?.stopName;
        if (card) {
            if (card.category === "dockless") {
                stopName = card.agency.mode;
                stopName = this.getLocalization(stopName);
                agencyLogo = null;
            } else if (card.category === "docked") {
                stopName = card.agency.fullName;
            } else if (card.type === "parking" && !stopName) {
                stopName = "Parking";
            }
        }

        const modeIcon = card?.agency.mode || null;

        const voTitle = card ? this.getVOLabel() : '';

        return (
            <div className="CardHeader">

                <div className="column-left">

                    <div className="StopName">
                        <h2>
                            <span className="voiceover-text voiceover-fill">{voTitle}</span>
                            <span aria-hidden="true">{stopName?.toUpperCase()}</span>
                        </h2>
                    </div>

                </div>
                <div className="column-right">

                    <div className="Buttons">
                        {params && params.favorites ? <FavoriteButton setCardFavorite={setCardFavorite} isFavorite={isFavorite} showOnlyWhenFavorited={!showCardScreen} card={card} opened={opened} showCardScreen={showCardScreen} /> : null}
                    </div>

                    {agencyLogo ? (<div className="AgencyLogo">
                        <img src={agencyLogo} alt={card.agency.shortName} />
                    </div>) : null}

                    {modeIcon ? (<div className="ModeIcon">
                        <Icon category="modes" name={modeIcon.toLowerCase()} />
                    </div>) : null}

                </div>

            </div>
        )
    }
}

export default CardHeader;
