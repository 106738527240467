import React, {
	Component
} from 'react';

import Capacity from 'components/Card/Capacity.js';
import Crowding from 'components/Card/Crowding.js';
import MassTransitPredictions from 'components/Card/MassTransitPredictions.js';
import ParkingCost from 'components/Card/ParkingCost.js';
import lokalise from 'utils/lokalise';

function CardRowRight(props) {
	const {
		card,
		rowData,
		rowAgency,
	} = props;
	const {
		category,
		agency,
		type,
	} = card;

	const {
		mode,
		isStatic,
	} = rowAgency;

	const {
		walkMinutes,
		pointPrediction,
		lowerBoundPrediction,
		upperBoundPrediction,
		crowding,
	} = rowData;

	let flexStyle = {
		flex: '1 0 25%',
	};

	let predictionComponent = null;

	let voText = null;

	let capacity = null;

	// Additional customizations
	if (category === 'masstransit') {
		// VO Text done in Predictions comp
		predictionComponent = (
			<MassTransitPredictions {...props} />
		);
	} else if (category === 'dockless' || category === 'docked') {
		voText = `${walkMinutes} minute walk away`;
		predictionComponent = (
			<MinutesWalk
				walkMinutes={walkMinutes}
			/>
		);
		flexStyle = {
			flex: '1 1 10%',
		};

		if (card.agency.mode === "bikeshare") {
			if (rowData.dock) {
				// console.log("BIKE", this.props);
				capacity = {
					brandColor: card.agency.style.brandColor,
					vehicleCount: rowData.dock.vehicleCount,
					electricVehicleCount: rowData.dock.electricVehicleCount,
					spaceCount: rowData.dock.availableSpaceCount
				}
			}
		}

	} else if (mode === 'floating') {
		voText = `${pointPrediction || lowerBoundPrediction} minute wait`;
		predictionComponent = (
			<MinutesAway
				pointPrediction={pointPrediction}
				lowerBoundPrediction={lowerBoundPrediction}
				upperBoundPrediction={upperBoundPrediction}
			/>
		);
		flexStyle = {
			flex: '0 1 20%',
		};
	} else if (type === 'parking') {
		flexStyle = {
			flex: '1 0 25%'
		};
		predictionComponent = (<ParkingCost card={card} rowData={rowData} />);
	}

	return (
		<div role="cell" className="CardRowRight" style={flexStyle}>

			{capacity ? <Capacity color={capacity.brandColor} vehicleCount={capacity.vehicleCount} electricVehicleCount={capacity.electricVehicleCount} spaceCount={capacity.spaceCount} /> : null}

			{crowding ? <Crowding crowding={crowding} /> : null}

			{voText ? <span className="voiceover-text voiceover-fill">{voText}</span> : null}

			{predictionComponent}

			{isStatic ? <div className="Scheduled">{lokalise.getKey("ts3l")}</div> : null}
		</div>
	);
}

function MinutesWalk(props) {
	const {
		walkMinutes
	} = props;
	return (
		<div className="WalkPredictions" aria-hidden="true">
			<div className="WalkMinutes">{walkMinutes}</div>
			<div className="Units">{lokalise.getKey("ts3j")}</div>
		</div>
	);
}

function MinutesAway(props) {
	const {
		pointPrediction,
		lowerBoundPrediction,
		upperBoundPrediction,
	} = props;

	// Min away requires grammar changes for other langs
	let minutesAway = <span className="Prediction">{`${pointPrediction}`}</span>;

	if (lowerBoundPrediction !== null) {
		minutesAway = (
			<span className="Prediction">{`${lowerBoundPrediction}-${upperBoundPrediction}`}</span>
		);
	}

	if (!minutesAway) return null;

	return (
		<div className="MinAway" aria-hidden="true">
			{lokalise.getKey('ts6r', { minutes: minutesAway })}
		</div>
	);
}


export default CardRowRight;
