import LocationList from 'components/AppHeader/LocationList';
import React, {} from 'react';
import SearchResults from 'components/AppHeader/SearchResults';
import Tappable from 'components/common/Tappable';
import lokalise from 'utils/lokalise';

function MenuDropdown(props) {
    const {
        menuOpen,
        menuType,
        hubs,
        savedPlaces,
        toggleLocation,
        deleteLocation,
        searchResults,
        toggleMenu,
        userLocation,
        children,
    } = props;

    return (
        <div className="MenuDropdown" aria-hidden={menuOpen ? 'false' : 'true'}>
            { menuType === 'anywhere' ? <SearchResults menuOpen={menuOpen} toggleLocation={toggleLocation} searchResults={searchResults} /> : null }
            <span aria-hidden={searchResults && searchResults.length > 0}>

                { menuType === 'anywhere' ? <ReturnButton toggleLocation={toggleLocation} userLocation={userLocation} /> : null }

                { menuType === 'anywhere' ? (
                    <div className="SavedLocations">
                        <h2 className="heading">{lokalise.getKey('ts5h')}</h2>
                        <div className="text">{lokalise.getKey("ts5k")}</div>
                        <LocationList type="place" buttonDelete={deleteLocation} buttonClick={toggleLocation} collection={savedPlaces} />
                    </div>
                ) : null}

                <div className="SavedLocations">
                    <h2 className="heading">{lokalise.getKey('ts5i')}</h2>
                    <div className="text">{lokalise.getKey("ts10r")}</div>
                    <LocationList type="hub" buttonClick={toggleLocation} collection={hubs} />
                </div>

                {children}

            </span>
        </div>
    );
}

function ReturnButton(props) {
    const {
        toggleLocation,
        userLocation,
    } = props;

    // console.log("User is at ", userLocation);

    function onTap() {
        toggleLocation(userLocation);
    }

    return (
        <Tappable className="return button" onTap={onTap} role="button">
            {lokalise.getKey("ts5j")}
        </Tappable>
    );
}

export default MenuDropdown;
