import React, {
    Component
} from 'react';

import AppButtonTester from 'components/Tester/AppButtonTester';


window.reportSuccess = () => {
    let errors = document.getElementById("errors");
    errors.className = "";
    void errors.offsetWidth;
    errors.className = "success";

    errors.innerHTML = "App link works.";
}

window.reportFailure = () => {
    let errors = document.getElementById("errors");
    errors.className = "";
    void errors.offsetWidth;
    errors.className = "failure";

    errors.innerHTML = "Couldn't open app.";
}


export default class ExternalAppLinkTester extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "",
            form: {
                link: ""
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.tryLink = this.tryLink.bind(this);
    }

    handleChange(event) {

        const {
            form
        } = { ...this.state
        };
        const currentState = form;

        const {
            name,
            value
        } = event.target;
        currentState[name] = value;

        this.setState({
            form: currentState
        });
    }

    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.tryLink();
        }
    }

    tryLink() {
        console.log("SUBMIT");
        try {
            let url = this.state.form.link;
            if (window.webkit && window.webkit.messageHandlers) {
                window.webkit.messageHandlers.tryLink.postMessage(url);
            }
            if (window.Android && window.Android.tryLink) {
                window.Android.tryLink(url);
            }
        } catch (e) {
            this.setState({
                error: e.toString()
            })
        }
    }

    render() {
        return (
            <div className="ExternalAppLinkTester">
                <h1>External App Link Tester</h1>

                <h2>Type in any link to see if it redirects in a mobile app</h2>

                <div>
                    <input autocomplete="off" autoCorrect="off" autoCapitalize="off" spellcheck="false" type="text" onKeyPress={this.onKeyPress} value={this.state.form.link} name="link" onChange={this.handleChange} />
                    <button className="InlineSubmit" type="submit" onClick={this.tryLink}>Try Link</button>
                </div>

                <div style={{clear: "both", marginTop: 10, padding: 20, backgroundColor: "#ddd"}}>
                    Link Behavior Result: <span id="errors">{this.state.error}</span>
                </div>


                <h2>Instructions</h2>

                <ul>
                <li>- Find the universal link (https://...) or URI scheme (app://). Try to Google app's name plus "Universal Link" such as <code>Uber "Universal Link"</code> or try <a href="https://www.universallinks.xyz/">https://www.universallinks.xyz/</a></li>
                <li>- You can also just try guessing the URI scheme like (uber://)</li>
                <li>- For scootershare/bikeshare sometimes its (appname-scooters://)</li>
                <li>- If nothing happens after 10 seconds, the link probably didn't work</li>
                <li>- <a href="http://metabase-ts.herokuapp.com/question/229">Metabase List of Universal Links</a></li>
                </ul>

                <AppButtonTester />

            </div>
        )
    }

}
