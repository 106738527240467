import { useEffect, useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import TripOption from './TripOption';
import lokalise from 'utils/lokalise';

function TripOptions({ tripOptions, selectTripOption, searchingTrips }) {

    if (searchingTrips || !tripOptions) return null;

    const [groupedTrips, setGroupedTrips] = useState(null);

    useEffect(() => {
        let grouped = {};
        tripOptions.forEach((trip, i) => {

            if (i === 0) trip.recommended = true;

            if (grouped[trip.category]) {
                grouped[trip.category].push(trip);
            } else {
                grouped[trip.category] = [trip];
            }
        })
        setGroupedTrips(grouped);
    }, [tripOptions]);

    // console.log(groupedTrips);
    return (
        <div className="TripOptions">
            <ErrorBoundary fallback={<>{lokalise.getKey("ts11q")}</>}>

                {groupedTrips && Object.values(groupedTrips).map((group, i) => {

                    return (<div key={i} className="trip-group">
                        <h3>{lokalise.getReverseKey(group[0].category)}</h3>
                        <div className="trip-group-content">
                            {
                                group.map((tripOption, i) => {
                                    return <TripOption key={i} tripOption={tripOption} selectTripOption={selectTripOption} />
                                })
                            }
                        </div>
                    </div>)

                })}

            </ErrorBoundary>
        </div>
    )
}

export default TripOptions;
