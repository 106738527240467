import React, {
	Component
} from 'react';

import ExitButton from 'components/ExitButton';
import LocationTitle from 'components/AppHeader/LocationTitle';
import MenuDropdown from 'components/AppHeader/MenuDropdown';
import MenuHelper from 'utils/menu';
import Settings from 'components/AppHeader/Settings';

class MenuHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			searchResults: null,
		};

		this.lastInput = '';

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate() {
		const {
			searchResults,
		} = this.state;

		const {
			menuOpen,
		} = this.props;

		// Always clear out results when closing menu
		if (searchResults && searchResults.length > 0 && !menuOpen) {
			this.setState({
				searchResults: null
			});
		}
	}

	handleChange(event) {
		const {
			appLocation,
		} = this.props;

		const {
			searchResults,
		} = this.state;

		const textInput = event.target.value;

		// This is a first time entry, so initialize the search results div with array
		if (!this.lastInput && !searchResults) {
			this.setState({
				searchResults: [],
			});
		}

		this.lastInput = textInput;

		// console.log("Change", event.target.value, event.target.value.length);

		if (textInput.length === 0) {
			this.setState({
				searchResults: null,
			});
		} else {
			MenuHelper.getSearchResults(textInput, appLocation, 'sessionMap').then((newResults) => {
				// console.log("New Search Results", newResults);
				// 1. User could have cleared the field by the time this result comes through
				// 2. User was previously searching and we shouldnt clear the results yet
				if (this.lastInput.length > 0 && newResults) {
					this.setState({
						searchResults: newResults,
					});
				}
			});
		}
	}

	render() {
		const {
			screen,
			hubs,
			savedPlaces,
			menuOpen,
			menuType,
			toggleLocation,
			deleteLocation,
			appLocation,
			toggleMenu,
			address,
			userLocation,
			showNoArrivals,
			updateApp,
		} = this.props;

		const {
			searchResults,
		} = this.state;

		// 100vh will include any browser chrome
		const wrapperStyle = menuOpen ? {
			height: '100vh',
		} : {
			height: 60,
		};

		const className = menuOpen ? 'expanded' : '';

		return (
			<div className={`MenuWrapper ${className}`} style={wrapperStyle}>
				<div className="MenuHeader">
					<LocationTitle
						handleChange={this.handleChange}
						toggleLocation={toggleLocation}
						appLocation={appLocation}
						screen={screen}
						address={address}
						menuType={menuType}
						menuOpen={menuOpen}
					/>
					<ExitButton tapEvent={toggleMenu} showButton={menuOpen} />
				</div>

				<MenuDropdown
					searchResults={searchResults}
					toggleLocation={toggleLocation}
					deleteLocation={deleteLocation}
					toggleMenu={toggleMenu}
					hubs={hubs}
					savedPlaces={savedPlaces}
					menuOpen={menuOpen}
					menuType={menuType}
					userLocation={userLocation}
				>
					<Settings showNoArrivals={showNoArrivals} updateApp={updateApp} />
				</MenuDropdown>

				<div id="sessionMap" />
			</div>
		);
	}
}

export default MenuHeader;
