import { useState, useRef, useEffect } from 'react';

// This component only addresses UI interactions with the field, no data is done here
function LocationInput({
    onChangeEvent,
    onBlurEvent,
    onKeyUpEvent,
    onFocusEvent,
    inputValue,
    disabled,
    locked,
    placeholder
}) {
    const inputEle = useRef(null);

    // useEffect(() => {
    // Previously this removes focus on the field when the user clicks away
    // Now we use onKeyUp
    // if (inputEle.current) {
    //     // if (menuOpen) {
    //     // This doesnt work for Mobile Safari, see original click action in
    //     // inputEle.current.focus();
    //     // } else {
    //     inputEle.current.value = '';
    //     inputEle.current.blur();
    //     // }
    // }
    // });

    return (
        <input
            role="search"
            type="search"
            ref={inputEle}
            className={`LocationInput ${disabled ? 'disabled' : ''} ${locked ? 'locked' : ''}`}
            value={inputValue}
            placeholder={placeholder}
            onFocus={onFocusEvent}
            onChange={onChangeEvent}
            onBlur={onBlurEvent}
            onKeyUp={onKeyUpEvent}
            disabled={disabled || locked}
        />
    );
}

export default LocationInput
