import React, {
	Component,
	useState,
} from 'react';

import Cookies from 'universal-cookie';

import config from 'config';
import lokalise from 'utils/lokalise';

const UniversalCookie = new Cookies();

function Settings(props) {

	const {
		showNoArrivals,
		updateApp,
	} = props;

	const [selected, setSelected] = useState(showNoArrivals);

	function onChange() {
		let data = UniversalCookie.get(config.cookies.userSettings.name);
		if (!data) {
			data = {};
		}
		data.showNoArrivals = !selected;
		UniversalCookie.set(config.cookies.userSettings.name, data, {
			secure: true,
			sameSite: 'none',
			path: '/',
			expires: config.cookies.favorites.expires
		});
		setSelected(!selected);
		updateApp({
			showNoArrivals: !selected
		})
	};

	return (
		<div className="Settings">
			<h2 className="heading">{lokalise.getKey("ts10s")}</h2> 

			<label className={`CheckboxControl ${selected ? 'selected' : ''}`} htmlFor="noarr">
				{lokalise.getKey("ts10t")}				
				<input id="noarr" name="noarr" type="checkbox" defaultChecked={selected} onChange={onChange} />
			</label>

		</div>
	);
}

export default Settings;
