import { Marker } from 'react-map-gl';

function UserMarker({ latitude, longitude }) {

    if (!latitude || !longitude) return null;

    return (
        <Marker latitude={latitude} longitude={longitude} anchor="bottom">
            <div className="UserMarker">
                <Bubble />
                <BubbleBg />
            </div>
        </Marker>
    );
}

function Bubble() {
    return (
        <div className="Bubble" />
    );
}

function BubbleBg() {
    return (
        <div className="BubbleBg" />
    );
}

export default UserMarker