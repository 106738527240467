import { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';

import config from 'config.js';
import lokalise from 'utils/lokalise';

const UniversalCookie = new Cookies();

// This is a console like DIV that will appear when ?debug=true is added to the URL
// to allow for debugging live for the client and ability to send a report
function Debug({ appState }) {

	const [problemHref, setProblemHref] = useState(null);

	useEffect(() => {

		const mailto = 'mailto:app@actionfigure.ai';
		const subject = 'subject=CityMotion Plugin Problem Report';

		let body = 'body=Describe your problem and add a screenshot:\n\n\n\n\n';
		const url = `\n\nURL:\n${window.location.toString()}`;
		const userAgent = `\n\nUser Agent:\n${navigator.userAgent}`;

		let cookies = UniversalCookie.getAll();
		cookies = `\n\nCookies:\n${JSON.stringify(cookies)}`;

		let json = JSON.stringify(appState);
		json = `\n\nApp State:\n${json}`;

		body = `${body}${url}${userAgent}${cookies}${json}`;

		body = body.replace(/&/gi, '(and)');

		const sendString = `${mailto}?${subject}&${body}`;
		// console.log("SEND?", send);

		setProblemHref(encodeURI(sendString));

	}, []);

	function getCookies() {
		const cookies = UniversalCookie.getAll();
		const cookieArr = Object.entries(cookies);
		return cookieArr.map((cookie) => {
			return (
				<li key={cookie[0]} className="cookie">
					<b>{cookie[0]}</b> -
					{JSON.stringify(cookie[1])}
				</li>
			)
		});
	}

	// Used to determine if the environment is allowed to set a cookie
	function testRandomCookie() {
		// console.log("Cookie", favs);

		UniversalCookie.set("RandomCookie1" + Math.random(), {
			cookieAsObject: "save",
			otherValue: "saved"
		}, {
			secure: true,
			path: '/',
			expires: config.cookies.favorites.expires
		});
	}

	let cookies = getCookies();

	let searchQuery = window.location.search || document.location.search;
	let params = new URLSearchParams(searchQuery);

	return (
		<div className="Debug">
			<a href={problemHref}><button>{lokalise.getKey("ts12g")}</button></a>

			<span>
				URL: {window.location.toString()}<br />

				<br /><br />

				{lokalise.getKey("ts12h")}:
				{cookies}

				<br /><br />
				<button type="button" className="Button" onClick={testRandomCookie}>{lokalise.getKey("ts12i")}</button>

				<br /><br />
				{lokalise.getKey("ts12j")}: {appState.debugMessage}
			</span>
		</div>
	)
}

export default Debug
