import React from 'react';
import lokalise from 'utils/lokalise';

function PredictionFrequency(props) {
	const {
		predictionFrequency,
	} = props;

	// Wait times only have 1 prediction in the array that is Text with Unicode
	return (
		<div className="PredictionFrequency">
			<div className="PredictionPhrase">{lokalise.getKey('ts5e')}</div>
			<div className="PredictionText">{predictionFrequency}</div>
			<div className="Unit" aria-hidden="true">{lokalise.getKey("ts3i")}</div>
		</div>
	);
}

export default PredictionFrequency;
