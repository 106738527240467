import React, {
    Component
} from 'react';

import Tappable from 'components/common/Tappable';

const TestButton = {
    border: "1px solid #ccc",
    borderRadius: 20,
    display: "block",
    width: "100%",
    padding: 15,
    textAlign: "left",
    background: "#fff",
    marginTop: 20,
    fontWeight: 600,
    marginBottom: 10
}

const wrapper = {
    padding: 20,
    textAlign: "left",
    display: "block",
    fontFamily: "Helvetica"
}

export default class AppButtonTester extends Component {
    constructor(props) {
        super(props);
        this.openLink = this.openLink.bind(this);
        this.openFallbackLink = this.openFallbackLink.bind(this);
    }

    openLink(link) {
        window.location = link;
    }

    openWindow(link) {
        window.open(link);
    }

    openFallbackLink(link, fallbackLink) {
        setTimeout(function () {
            window.location = fallbackLink;
        }, 25);
        window.location = link;
        // clearTimeout(fallback);
    }

    openFallbackWindow(link, fallbackLink) {

        var newWindow = window.open(link, "_new");

        setTimeout(function () {
            newWindow.location = fallbackLink;
        }, 25);

        // clearTimeout(fallback);
    }

    render() {
        return (
            <div style={wrapper}>

                <h1>QA Preset App Links</h1>
                Open these in a Mobile browser or  Third Party App
                <hr />

                <h3>Method: window.location = link</h3>

                <TestLink handleTapEvent={() => this.openLink("nextbike://")} buttonText="OPEN nextbike://" />
                Open Nextbike<br />

                <TestLink handleTapEvent={() => this.openLink("citibike://")} buttonText="OPEN citibike://" />
                Open Citibike<br />


                <TestLink handleTapEvent={() => this.openLink("bluebikes://")} buttonText="OPEN bluebikes://" />
                Open bluebikes<br />



                <hr />

                <TestLink handleTapEvent={() => this.openLink("uber://")} buttonText="OPEN uber://" />
                Open Uber app with Uniform Resource Identifier (URI)<br />

                <TestLink handleTapEvent={() => this.openLink("https://m.uber.com/ul")} buttonText="OPEN https://m.uber.com/ul" />
                Open Uber app with Universal Link (UL)<br />

                <TestLink buttonText="OPEN uber:// Deep Link 40.8,-73.95" handleTapEvent={() => this.openLink("uber://?action=setPickup&client_id=aPh8i8LSR8WyIY9h0xalFUuNKDrcUNgk&product_id=1b64bf82-a0ba-4b0f-be32-df8d05481d7e&pickup[latitude]=40.8&pickup[longitude]=-73.95")} />
                Open Uber app to this location with URI<br />

                <TestLink buttonText="OPEN https://m.uber.com/ul Deep Link 40.8,-73.95" handleTapEvent={() => this.openLink("https://m.uber.com/ul/?action=setPickup&client_id=aPh8i8LSR8WyIY9h0xalFUuNKDrcUNgk&product_id=1b64bf82-a0ba-4b0f-be32-df8d05481d7e&pickup[latitude]=40.8&pickup[longitude]=-73.95")} />
                Open Uber app to this location with UL<br />

                <TestLink buttonText="OPEN Uber App Store" handleTapEvent={() => this.openLink("https://apps.apple.com/us/app/368677368")} />

                <TestLink buttonText="OPEN Uber Google Play" handleTapEvent={() => this.openLink("https://play.google.com/store/apps/details?id=com.ubercab")} />

                <TestLink handleTapEvent={() => this.openLink("https://viarider.onelink.me/1335417427/5d7d325d?referrer=TransitScreen&origin_lat=38.9049&origin_lng=-77.05&destination_lat=38.9049&destination_lng=-77.05")} buttonText="OPEN Via Universal Link" />
                Open Via with their third-party UL viarider.onelink<br />

                <hr />
                <h3>Method: window.open(link)</h3>
                Android may tell you it blocks the popups.<br />
                <TestLink handleTapEvent={() => this.openWindow("uber://")} buttonText="OPEN uber://" />
                Open Uber app with Uniform Resource Identifier (URI)<br />

                <TestLink handleTapEvent={() => this.openWindow("https://m.uber.com/ul")} buttonText="OPEN https://m.uber.com/ul" />
                Open Uber app with Universal Link (UL)<br />

                <TestLink handleTapEvent={() => this.openWindow("https://viarider.onelink.me/1335417427/5d7d325d?referrer=TransitScreen&origin_lat=38.9049&origin_lng=-77.05&destination_lat=38.9049&destination_lng=-77.05")} buttonText="OPEN Via Universal Link" />
                Open Via with their third-party UL viarider.onelink<br />

                <hr />

                <h3>Method: window.location with setTimeout to App Store</h3>
                iOS will leave a failure message on Safari. Double redirects.<br />
                Android seems to have no problems<br />

                <TestLink buttonText="Try uber:// then App Store" handleTapEvent={() => this.openFallbackLink("uber://", "https://apps.apple.com/us/app/368677368")} />

                <TestLink buttonText="Try uber:// then Google Play" handleTapEvent={() => this.openFallbackLink("uber://", "https://play.google.com/store/apps/details?id=com.ubercab")} />

                <TestLink buttonText="Try lyft:// then App Store" handleTapEvent={() => this.openFallbackLink("lyft://", "https://apps.apple.com/us/app/lyft/id529379082")} />

                <TestLink buttonText="Try lyft:// then Google Play" handleTapEvent={() => this.openFallbackLink("lyft://", "https://play.google.com/store/apps/details?id=me.lyft.android")} />

                <hr />

                <h3>Method: window.open with setTimeout to App Store (on that new window)</h3>
                Best for iOS because it eliminates seeing that failure message. Still double redirects.<br />
                Android may tell you it blocks the popups.<br />

                <TestLink buttonText="Try uber:// then App Store" handleTapEvent={() => this.openFallbackWindow("uber://", "https://apps.apple.com/us/app/368677368")} />

                <TestLink buttonText="Try uber:// then Google Play" handleTapEvent={() => this.openFallbackWindow("uber://", "https://play.google.com/store/apps/details?id=com.ubercab")} />

                <TestLink buttonText="Try lyft:// then App Store" handleTapEvent={() => this.openFallbackWindow("lyft://", "https://apps.apple.com/us/app/lyft/id529379082")} />

                <TestLink buttonText="Try lyft:// then Google Play" handleTapEvent={() => this.openFallbackWindow("lyft://", "https://play.google.com/store/apps/details?id=me.lyft.android")} />

            </div>
        )
    }

}

function TestLink(props) {
    return <Tappable stopPropagation onTap={props.handleTapEvent} style={TestButton}>{props.buttonText}</Tappable>
}
