import React, {
	Component,
} from 'react';

import lokalise from 'utils/lokalise';

function capitalize(text) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

function CardNotice(props) {
	const { card } = props;

	let message = lokalise.getKey('ts1q');
	const hasError = card.api_error;

	// Modes may preempt the category conditional
	if (card.agency) {
		if (card.agency.mode === 'floating') {
			if (hasError) {
				message = lokalise.getKey('ts5t');
			} else {
				message = lokalise.getKey('ts5u');
			}
		} else if (card.agency.mode === 'bikeshare') {
			if (hasError) {
				message = lokalise.getKey('ts5v', {
					vehicle_type: capitalize(lokalise.getKey('ts4i', null, 'one')),
				});
			} else if (card.agency.available === false) {
				message = lokalise.getKey('ts10e');
			} else {
				message = lokalise.getKey('ts6a', {
					vehicle_type: lokalise.getKey('ts4i', null, 'other'),
				});
			}
		} else if (card.agency.mode === 'scootershare') {
			message = lokalise.getKey('ts6a', {
				vehicle_type: lokalise.getKey('ts4h', null, 'other'),
			});
		} else if (card.agency.mode === 'dockless-bikeshare') {
			message = lokalise.getKey('ts6a', {
				vehicle_type: lokalise.getKey('ts4i', null, 'other'),
			});
		} else if (card.category === 'dockless') {
			message = lokalise.getKey('ts5u');
		} else if (card.category === 'cars') {
			message = lokalise.getKey('ts5u');
		} else if (card.agency.fullName === 'Traffic') {
			message = hasError ? lokalise.getKey('ts5r') : lokalise.getKey('ts6g');
		} else if (card.agency.mode === 'shuttle') {
			message = hasError ? lokalise.getKey('ts5w') : lokalise.getKey('ts5x');
		} else if (card.category === 'masstransit') {
			message = hasError ? lokalise.getKey('ts5r') : lokalise.getKey('ts5s');
		} else if (card.agency.mode === 'flight') {
			message = lokalise.getKey('ts5r');
		} else {
			// Generic no data
			message = lokalise.getKey('ts5t');
		}
		// console.log(card);
	} else {
		// Generic no data
		message = lokalise.getKey('ts5t');
	}

	return (
		<div className="NoArrivals" style={{ textAlign: 'center', padding: '1em' }}>
			{message}
		</div>
	);
}

export default CardNotice;
