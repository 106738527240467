import React from 'react';
import lokalise from 'utils/lokalise';

function PredictionString(props) {
	const {
		predictionString,
	} = props;

	// Wait times only have 1 prediction in the array that is Text with Unicode
	return (
		<div className="PredictionString">
			<div className="PredictionText" dangerouslySetInnerHTML={{ __html: predictionString }}></div>
		</div>
	);
}

export default PredictionString;
