import React, {
	Component
} from 'react';

import Analytics from 'utils/analytics';
import AppSyncHelper from 'utils/appsync';
import { BrowserRouter } from 'react-router-dom'; // Not really used but hope to transition to it in the future
import CardScreen from 'components/CardScreen/CardScreen';
import Cookies from 'universal-cookie';
import Debug from 'components/Debug';
import { ErrorBoundary } from '@sentry/react';
import { GoogleMapsProvider } from 'components/context/GoogleMapsProvider';
import MobilityScreen from 'components/MobilityScreen/MobilityScreen';
import ReactGA from 'react-ga4';
import ThemeStyle from 'components/ThemeStyle';
import TripScreen from 'components/TripScreen/TripScreen';
import analytics from 'utils/analytics';
import config from 'config';
import {
	isMobileSafari,
} from 'react-device-detect';
import { loadCardScreen } from 'utils/params';
import lokalise from 'utils/lokalise';

const UniversalCookie = new Cookies();
class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// Data - Updater.js
			cards: [],
			screen: undefined, // {}
			address: undefined, // string
			alerts: undefined, // []
			weather: undefined, // {}

			// Data - AppSync.js
			customer: undefined, // {}
			screens: undefined, // []

			// Locations - AppLocation.js and Menu Header
			appLocation: undefined, // { coords: {latitude, longitude}, locationCode: "" }
			userLocation: undefined, // same as above but where the user actually is

			// UI 
			ready: false, // true false
			updating: false, // true false
			loading: true, // General working state of the app

			action: 'APP_START', // @TODO Remove this/merge with status

			statusMessage: 'Loading', // string toggles some UI stuff 
			statusInstructions: null,
			statusContactSupport: false,

			showAppStatus: true, // true false

			selectedTab: 'all', // 'all, bus, rail, etc'

			showCardScreen: null, // true/false
			selectedCard: null, // Card object

			showTripScreen: null, // true/false
			tripScreenLocation: null, // Location object

		};
		this.watcher = false;

		this.updateApp = this.updateApp.bind(this);
		this.onNavBarButtonClick = this.onNavBarButtonClick.bind(this);
		this.setNavigation = this.setNavigation.bind(this);
		this.scrollTo = this.scrollTo.bind(this);
		this.toggleTripScreen = this.toggleTripScreen.bind(this);
		this.toggleCardScreen = this.toggleCardScreen.bind(this);
		this.setCardFavorite = this.setCardFavorite.bind(this);
		this.openTripScreenFromCardScreen = this.openTripScreenFromCardScreen.bind(this);
		this.updateBrowserHistoryState = this.updateBrowserHistoryState.bind(this);

		this.app = React.createRef();
	}

	async componentDidMount() {
		const {
			params,
		} = this.props;

		const {
			locationCode,
			key,
			debug,
			lang,
			locale,
			openTo,
			geolocation,
		} = params;

		Analytics.recordPageHit();

		Analytics.postToDataDrop(params, 'page-open');

		// Setup localization
		if (lang || locale) {
			const targetLanguage = locale || lang;
			lokalise.setUserLanguage(targetLanguage);
		}

		// Run through initial checks
		if (!key) {
			this.setState({
				statusMessage: lokalise.getKey('ts4c'),
				debugMessage: 'No API Key was supplied in URL',
				loading: false,
				ready: true,
			});
		} else {
			// Ok normal run, setup with params and appsync
			const appSync = await AppSyncHelper.getAppSync(key);
			// @TODO AppSync could be used as Auth validation here
			// Right now appSync is simply additive information

			let newState = {};

			// Apply the openTo= parameter to state
			if (config.modeFilterButtons) {
				const navigationOptions = Object.keys(config.modeFilterButtons);
				if (openTo && navigationOptions.indexOf(openTo) !== -1) {
					newState.selectedTab = openTo;
				}
			}

			// Setup initial "page" 
			// NOTE that URLs must pass the config.allowedPathNames rules first in index.js
			const url = new URL(window.location.href || document.location.search);
			if (url.pathname === '/trip-plan') {
				newState.showTripScreen = true;
			}

			this.setState({
				...newState,
				ready: true,
				customer: appSync && appSync.customer || null,
				screens: appSync && appSync.screens || null
			}, () => {
				if (url.pathname === '/trip-plan') {
					// This still has to happen because the method provides some additional UI control
					this.toggleTripScreen(true);
				}
			});
		}
	}

	// Only used for showing error messages on DOM
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {
			loading: false,
			showAppStatus: true,
			statusMessage: 'Sorry the app had a problem!',
			statusContactSupport: true,
			debugMessage: error.toString(),
		};
	}

	// Only used to perform API/Airbrake calls upon error
	componentDidCatch(error, info) {
		Analytics.recordHit({
			t: 'event',
			ec: 'App',
			ea: 'App Crashed',
			el: error.toString(),
		});
	}

	/*
	 * EVENT: Change the Browser URL and Set State for Updater
	 * Updater will componentDidUpdate eval the location change and do a new call
	 * @NOTE THIS does not interact with AppLocation component, they should prob be merged together
	 * @TODO This should be moved into a Router component wrapper
	 * @param location object with {name, coords:{latitude,longitude}}
	 */
	setNavigation(location) {
		const {
			coords
		} = location;

		// Manipulates URL params to only ever have locationCode or coordinates never both
		const url = new URL(window.location.href || document.location.search);
		const queryParams = new URLSearchParams(url.search);

		let coordString = '';
		if (coords) {
			coordString = `${coords.latitude},${coords.longitude}`;
		}
		const locationCode = location.locationCode || null;

		if (locationCode) {
			queryParams.append('locationCode', locationCode);
			if (queryParams.has('coordinates')) {
				queryParams.delete('locationCode');
			}
		} else if (coordString) {
			if (queryParams.has('coordinates')) {
				queryParams.set('coordinates', coordString);
			} else {
				queryParams.append('coordinates', coordString);
				queryParams.delete('locationCode');
			}
		}

		this.updateBrowserHistoryState({ queryParams });

		// console.log("Set Navigation", newUrl);
		this.setState({
			appLocation: {
				name: location.name,
				address: location.address,
				locationCode,
				coords,
			},
			action: 'SET_NAVIGATION',
		}, () => {
			this.scrollTo(0, 0);
		});
	}

	updateBrowserHistoryState({ path, queryParams }) {
		const url = new URL(window.location.href || document.location.search);

		let newUrl = url.origin;

		if (path || path === '') {
			newUrl = newUrl + '/' + path;
		} else {
			newUrl = newUrl + url.pathname;
		}

		if (queryParams) {
			newUrl = newUrl + '?' + queryParams.toString();
		} else {
			const existingQueryParams = new URLSearchParams(url.search);
			newUrl = newUrl + '?' + existingQueryParams.toString();
		}

		// console.log("new URL", newUrl);
		if (window.history.pushState) {
			window.history.pushState({}, config.publicAppName, newUrl);
		}
	}

	/*
	 * Give Updater, the ability to update the entire app via our JSON call
	 * @param update object json - Any high level values for state change
	 */
	updateApp(newUpdate) {
		const {
			appLocation,
		} = this.state;

		// @TODO Overwrite new location? Map failing on update
		let newState = {
			...this.state,
			...newUpdate
		};

		// Critical downstream state changes -----------------------

		// append isFavorite to cards
		if (newState.cards) {
			const favs = UniversalCookie.get(config.cookies.favorites.name);
			if (favs) {
				newState.cards.forEach((card) => {
					card.isFavorite = favs.indexOf(card.id) !== -1;
				});
			}
		}

		// Critical downstream: Fills in screen coordinate data from updater call
		if (appLocation !== undefined && !appLocation.coords && newUpdate.screen) {
			// console.log("overwrite", appLocation);
			appLocation.coords = {
				latitude: newUpdate.screen.coordinates.latitude,
				longitude: newUpdate.screen.coordinates.longitude,
			};
			appLocation.name = newUpdate.screen.prettyName;
			appLocation.address = newUpdate.screen.address;
			newState.appLocation = appLocation;
		}

		// console.log("Update App", newUpdate.action);
		this.setState(newState);
	}

	onNavBarButtonClick(modeClass) {
		if (modeClass === 'tripplan') {
			ReactGA.event("Trip Plan", {
				category: "Trip Plan",
				action: `Trip Plan Clicked`,
				clicked: true,
				locationName: this.state.appLocation.name,
				latitude: this.state.appLocation.coords.latitude,
				longitude: this.state.appLocation.coords.longitude
			});

			analytics.postToDataDrop({
				locationName: this.state.appLocation.name,
				latitude: this.state.appLocation.coords.latitude,
				longitude: this.state.appLocation.coords.longitude
			}, 'trip-plan-opened');

			this.toggleTripScreen(true);
		} else {

			ReactGA.event("Mode Filter", {
				category: "Mode Filter",
				action: `Filter by ${modeClass}`,
				mode: modeClass
			});

			analytics.postToDataDrop({
				mode: modeClass
			}, 'filter-modes');

			// All other modeClasses filter cards via selectedTab->CardColumns.js
			this.setState({
				selectedTab: modeClass,
			}, () => {
				this.scrollTo(0, 0); // Bring the user back to top of the list 
			});
		}
	}

	scrollTo(x, y) {
		if (isMobileSafari) {
			this.app.current.scrollTop = x;
		} else {
			window.scroll(x, y);
		}
	}

	toggleCardScreen(showCardScreen, selectedCard = null) {

		if (showCardScreen) {
			document.addEventListener(
				'keydown',
				this.escFunction,
				false
			);

			// Set global className to lock body scroll
			window.document.body.classList.add('scroll-locked');

			// Disable voiceover for card columns by just moving out of frame
			// const CardColumns = document.getElementById('CardColumns');
			// if (CardColumns) CardColumns.style.marginLeft = '100vw';

			// Disable voiceover taps
			const AppHeader = document.getElementById('AppHeader');
			if (AppHeader)
				AppHeader.setAttribute('aria-hidden', 'true');
		} else {
			document.removeEventListener(
				'keydown',
				this.escFunction,
				false
			);

			// Remove locked body scroll
			window.document.body.classList.remove('scroll-locked');

			// const CardColumns = document.getElementById('CardColumns');
			// if (CardColumns) CardColumns.style.marginLeft = 0;

			const AppHeader = document.getElementById('AppHeader');
			if (AppHeader)
				AppHeader.setAttribute('aria-hidden', 'false');
		}

		this.setState({
			showCardScreen,
			selectedCard
		});
	}

	toggleTripScreen(showScreen, newLocation) {

		// Lock underlying body scroll and preserve where the user was on MobilityScreen
		if (showScreen) {
			document.body.style.top = `-${window.scrollY}px`; // To undo lock below

			document.body.classList.add('scroll-lock');
		} else {
			const scrollY = document.body.style.top;

			document.body.classList.remove('scroll-lock');
			window.scrollTo(0, Math.round(parseInt(scrollY || '0') * -1));
			document.body.style.top = null;
		}

		this.updateBrowserHistoryState({ path: showScreen ? 'trip-plan' : '' });

		this.setState({
			showTripScreen: showScreen,
			// NewLocation is only for "forced" trip screen events like from CardScreen
			// When user requests trip screen theyll usually always get the appLocation ("user location")
			tripScreenLocation: newLocation || null
		});
	}

	// Favoriting actions are here because the UI is handled in various comps
	setCardFavorite(isFavorite, card) {
		// console.log("FAV", card);
		if (!card) return null;

		const cardId = card.id;
		let favs = UniversalCookie.get(config.cookies.favorites.name);
		// console.log("Existing Favs Cookie", favs);
		if (!favs) favs = [];
		if (isFavorite) {
			favs.push(cardId);
		} else {
			favs.splice(favs.indexOf(cardId), 1);
		}
		// console.log("New Favs Cookie", favs);
		UniversalCookie.set(config.cookies.favorites.name, favs, {
			secure: true,
			path: '/',
			sameSite: 'none',
			expires: config.cookies.favorites.expires,
		});

		// Update state data model 
		const newCards = this.state.cards;
		for (let c = 0; c < newCards.length; c++) {
			if (newCards[c].id === card.id) {
				newCards[c].isFavorite = isFavorite;
				break;
			}
		}
		this.setState({ cards: newCards });

		// Additional analytics reporting
		if (isFavorite) {
			ReactGA.event("Favorite", {
				category: "Favorite",
				action: "Favorite Clicked",
				agencyId: card.agencyId,
				stopName: card.stopName,
				favorited: isFavorite
			});

			analytics.postToDataDrop({
				agencyId: card.agencyId,
				stopName: card.stopName,
				favorited: isFavorite
			}, 'favorite-selected');
		} else {
			ReactGA.event("Favorite", {
				category: "Favorite",
				action: "Favorite Removed",
				agencyId: card.agencyId,
				stopName: card.stopName,
				favorited: isFavorite
			});
		}
	}

	openTripScreenFromCardScreen(newLocation) {
		this.toggleCardScreen(false);
		this.toggleTripScreen(true, newLocation);
	}

	render() {
		const {
			params,
		} = this.props;
		const {
			appLocation,
			userLocation,

			loading,
			updating,

			cards,

			screen,
			address,
			customer,

			weather,
			alerts,

			action,
			statusMessage,

			selectedTab,
			showNoArrivals,

			showAppStatus,
			statusInstructions,
			statusContactSupport,

			ready,
			showCardScreen,
			selectedCard,

			showTripScreen,
		} = this.state;
		const {
			key,
			view,
			tripPlan,
			debug,
			interactive
		} = params;

		// We need to wait for certain data to be ready before attempting to load components
		// Not a great design pattern but it makes life easier
		// See componentDidMount which also checks for `key`
		if (!ready) return null;

		//Setting interactive to false make the scroll bar disappear, so it doesn't indicate interactions
		if (interactive === 'false') {
			document.body.style.overflow = 'hidden';
		}

		return (
			<BrowserRouter>
				<ErrorBoundary fallback={"Sorry the app had a problem. Please contact app@actionfigure.ai"}>
					<section className="App" ref={this.app}>

						{/* Higher Order UI Components ============================= */}

						{view !== 'onlyTripPlan' ?
							<MobilityScreen
								params={params}
								loading={loading}
								updating={updating}
								action={action}
								statusMessage={statusMessage}

								userLocation={userLocation}
								appLocation={appLocation}

								showAppStatus={showAppStatus}
								selectedTab={selectedTab}

								statusInstructions={statusInstructions}
								statusContactSupport={statusContactSupport}

								setNavigation={this.setNavigation}
								updateApp={this.updateApp}
								toggleTripScreen={this.toggleTripScreen}
								onNavBarButtonClick={this.onNavBarButtonClick}
								toggleCardScreen={this.toggleCardScreen}
								setCardFavorite={this.setCardFavorite}

								showCardScreen={showCardScreen}
								showTripScreen={showTripScreen}

								cards={cards}
								address={address}
								customer={customer}
								screen={screen}
								weather={weather}
								alerts={alerts}
							/> : null}

						{key && (tripPlan && tripPlan === 'true' || view === 'onlyTripPlan') ?
							<GoogleMapsProvider><TripScreen
								appLocation={appLocation}
								params={params}

								isPermanent={view === 'onlyTripPlan'}

								showTripScreen={this.state.showTripScreen}
								tripScreenLocation={this.state.tripScreenLocation}

								toggleTripScreen={this.toggleTripScreen}

							/></GoogleMapsProvider> : null}

						{loadCardScreen ? <CardScreen
							toggleCardScreen={this.toggleCardScreen}
							openTripScreenFromCardScreen={this.openTripScreenFromCardScreen}
							setCardFavorite={this.setCardFavorite}
							showCardScreen={showCardScreen}
							selectedCard={selectedCard}
							appLocation={appLocation}
							userLocation={userLocation}
							screen={screen}
							params={params}
						/> : null}

						{debug ? <Debug appState={this.state} /> : null}

						{/* Universal Data Components ================================ */}

						<ThemeStyle params={params} screenStyle={screen && screen.style} />

					</section>
				</ErrorBoundary>
			</BrowserRouter>
		);
	}
}

App.defaultProps = {
	params: {},
};

export default App;
