import React, {
	Component,
} from 'react';

import Cookies from 'universal-cookie';
import MenuHeader from 'components/AppHeader/MenuHeader';
import MenuHelper from 'utils/menu';
import StaticHeader from 'components/AppHeader/StaticHeader';
import Tappable from 'components/common/Tappable';
import WeatherTag from 'components/common/WeatherTag';
import config from 'config';

const UniversalCookie = new Cookies();

class AppHeader extends Component {

	static getDerivedStateFromProps(props, state) {
		const {
			menu,
		} = props;

		const isChangeLocation = menu === 'anywhere' || menu === 'hub';
		const menuType = menu === 'anywhere' ? 'anywhere' : 'hub';

		return {
			isChangeLocation,
			menuType,
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
			hubs: [],
			savedPlaces: [],
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleLocation = this.toggleLocation.bind(this);
		this.deleteLocation = this.deleteLocation.bind(this);
	}

	componentDidMount() {
		if (this.props.customerApiKey) {
			this.getHubScreens();
		}

		const savedPlaces = UniversalCookie.get(config.cookies.savedPlaces.name);
		// console.log("savedPlaces?", savedPlaces);
		if (savedPlaces && savedPlaces.length > 0) {
			this.setState({
				savedPlaces,
			});
		}
	}

	componentDidCatch(error, info) {
		this.setState({
			error: true,
		});
	}

	async getHubScreens() {
		const {
			customerApiKey,
		} = this.props;

		let hubs = await MenuHelper.getScreens(customerApiKey);
		if (hubs) {
			// Convert to location object
			hubs = hubs.map((hub) => {
				return {
					name: hub.prettyName,
					coords: {
						latitude: hub.coordinates.latitude,
						longitude: hub.coordinates.longitude,
					},
				};
			});
			this.setState({
				hubs,
			});
		}
	}

	async toggleLocation(location) {
		const {
			setNavigation,
		} = this.props;

		const {
			savedPlaces,
		} = this.state;

		if (location.type === 'newPlace') {
			const savedLocation = location;
			savedLocation.type = 'place';
			savedPlaces.push(savedLocation);
			UniversalCookie.set(config.cookies.savedPlaces.name, savedPlaces, {
				secure: true,
				sameSite: 'none',
				path: '/',
				expires: config.cookies.savedPlaces.expires,
			});
		}

		await this.toggleMenu();

		this.setState({
			savedPlaces,
		});

		setNavigation(location);
	}

	deleteLocation(location) {

		const {
			savedPlaces
		} = this.state;

		const editedPlaces = savedPlaces.filter((place) => {
			return place.name !== location.name;
		});

		UniversalCookie.set(config.cookies.savedPlaces.name, editedPlaces, {
			secure: true,
			sameSite: 'none',
			path: '/',
			expires: config.cookies.savedPlaces.expires,
		});

		this.setState({
			savedPlaces: editedPlaces
		});
	}

	toggleMenu() {
		const {
			menuOpen,
			isChangeLocation,
		} = this.state;

		return this.setState({
			menuOpen: !menuOpen,
		}, () => {
			if (!menuOpen) {
				// Mobile Safari requires focus to be tied to the tap event here
				const ele = document.getElementById('SearchInput');
				if (ele) {
					ele.focus();
				}

				window.document.body.classList.add('scroll-locked');
				// Disable voiceover taps under
				const CardColumns = document.getElementById('CardColumns');
				if (CardColumns) CardColumns.setAttribute('aria-hidden', 'true');
			} else {
				window.document.body.classList.remove('scroll-locked');
				// Disable voiceover taps under
				const CardColumns = document.getElementById('CardColumns');
				if (CardColumns) CardColumns.setAttribute('aria-hidden', 'false');
			}
		});
	}

	render() {
		const {
			menu,
			screen,
			address,
			appLocation,
			userLocation,
			showNoArrivals,
			updateApp,
			weather,
		} = this.props;

		const {
			hubs,
			savedPlaces,
			menuOpen,
			error,
			isChangeLocation,
			menuType,
		} = this.state;

		if (error) return null;

		const tapAreaStyle = menuOpen ? {
			width: 20, // Throws tap area to the left side
		} : {
			width: '100%',
		};

		// Tappable accessibility button faking is added to LocationTitle.js
		return (
			<div role="banner" id="AppHeader" className="AppHeader" style={{ height: menuOpen ? '100vh' : '60px' }}>
				<Tappable
					className="TapArea"
					style={tapAreaStyle}
					onTap={this.toggleMenu}
				/>

				{isChangeLocation ? (
					<MenuHeader
						toggleLocation={this.toggleLocation}
						deleteLocation={this.deleteLocation}
						toggleMenu={this.toggleMenu}
						hubs={hubs}
						savedPlaces={savedPlaces}
						menuType={menuType}
						menuOpen={menuOpen}
						screen={screen}
						address={address}
						appLocation={appLocation}
						userLocation={userLocation}
						showNoArrivals={showNoArrivals}
						updateApp={updateApp}
					/>
				) : <StaticHeader
					screen={screen}
					menuOpen={menuOpen}
				/>}

				{!menuOpen ? <WeatherTag weather={weather} /> : null}
			</div>
		);
	}
}

export default AppHeader;
