import React, {
	Component
} from 'react';

import PlacesOverrides from 'constants/places-overrides.json';
import Tappable from 'components/common/Tappable';
import lokalise from 'utils/lokalise';

let overrides = [];
PlacesOverrides.forEach((place) => overrides.push(place.match));

function SearchResults(props) {
	const {
		searchResults, // searchResults come from input in LocationTitle.js
		toggleLocation,
		menuOpen,
	} = props;

	const showSearchResults = searchResults;

	const style = {
		display: showSearchResults ? 'block' : 'none'
	};

	return (
		<div className="SearchResults" style={style}>
			<div className="Content" aria-labelledby="SearchHeader" aria-describedby="SearchList">
				<h3 id="SearchHeader" aria-live="polite">
					Search Results:
				</h3>
				<ul id="SearchList" role="group"><SearchList searchResults={searchResults} toggleLocation={toggleLocation} /></ul>
			</div>
		</div>
	);
}

function SearchList(props) {
	const {
		searchResults,
		toggleLocation,
	} = props;

	if (!searchResults || searchResults.length === 0) {
		return (<li>{lokalise.getKey('ts5n')}</li>);
	}

	function checkPlaceOverrides(place) {
		const index = overrides.indexOf(place.name);
		if (index !== -1) {
			place.name = PlacesOverrides[index].name;
			place.formatted_address = PlacesOverrides[index].formatted_address;
		}
		return place;
	}

	return searchResults.map((place, i) => {

		const result = checkPlaceOverrides(place);

		if (result && result.business_status && result.business_status === 'CLOSED_PERMANENTLY') return null;
		const location = {
			name: result.name || `New Location ${result.geometry.location.lat()},${result.geometry.location.lng()}`,
			address: result.formatted_address,
			type: 'newPlace',
			coords: {
				latitude: result.geometry.location.lat(),
				longitude: result.geometry.location.lng(),
			},
		};
		return (
			<li role="menuitem" key={result.place_id}>
				<Tappable className="button" role="button" ariaLabel={`Result ${i + 1}: ${result.name}`} onTap={() => toggleLocation(location)}>
					{result.name}
					<div className="address">{result.formatted_address}</div>
				</Tappable>
			</li>
		);
	});
}

export default SearchResults;
