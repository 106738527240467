import { useRef, useState } from 'react';

import Icon from "components/Icon";
import Tappable from "components/common/Tappable";

function StepList({ style, children }) {
    return (
        <ul className="StepList" style={style}>
            {children}
        </ul>
    )
}

function Step({ url, icon, children, color, modeIcon, directionIcon, maneuverType, expandable, expandedContent }) {

    const expansionRef = useRef(null);

    const [expanded, setExpanded] = useState(false);

    const onTap = () => {
        setExpanded(!expanded);
    }

    const expandedHeight = expansionRef && expansionRef.current && expansionRef.current.clientHeight || 800;

    const expandStyle = {
        maxHeight: expanded ? expandedHeight : 0,
        transition: 'all 300ms ease-in',
        overflow: 'hidden'
    };

    const maneuverToIcon = {
        'left': 'left',
        'slight left': 'slight left',
        'sharp left': 'left',
        'right': 'right',
        'slight right': 'slight right',
        'sharp right': 'right',
        'east': 'right',
        'west': 'left',
        'north': 'up',
        'south': 'down',
    }

    return (
        <li className={`Step ${color ? 'with-color' : ''}`}>

            <div className="container">

                {expandable && <Tappable className="TapArea" onTap={onTap} />}

                <div className="step-line-dot-column">
                    {(icon === 'stepStart' || icon === 'stepSingle') && <Icon className="stepStart" category="ui" name="stepStart" />}
                    {(icon === 'stepEnd' || icon === 'stepSingle') && <Icon className="stepEnd" category="ui" name="stepEnd" />}
                    {maneuverType !== 'destinationPin' ? <div className="line" style={{ backgroundColor: color }}></div> : null}
                </div>

                <div className="step-icon-column">
                    {maneuverType ? <div className="direction-icon">
                        <Icon category="directions" name={maneuverToIcon[maneuverType] ? maneuverToIcon[maneuverType].toLowerCase() : ''} />
                    </div> : null}

                    {modeIcon ? <div className="mode-icon">
                        <Icon category="modes" name={modeIcon.toLowerCase()} />
                    </div> : null}
                </div>

                <div className="step-info-column">
                    {children}
                </div>

                {expandable && (
                    <div className="step-expand-icon">
                        {expanded ? <Icon category="ui" name="collapse" /> : <Icon category="ui" name="expand" />}
                    </div>)
                }
            </div>

            {
                expandable && (
                    <div className="step-expanded" style={expandStyle}>
                        {expandedContent && <div ref={expansionRef}>{expandedContent}</div>}
                    </div>
                )
            }

        </li>
    )
}

export { StepList, Step }
