import Icon from 'components/Icon';

const afIcon = <Icon category="ui" name="crowding" className="crowding-icon visual-icon-fill" />;
const afIconOff = <Icon category="ui" name="crowding" className="crowding-icon empty" />;

const iconCounts = {
	"EMPTY": null,
	"NOT_CROWDED": [1, 2],
	"SOMEWHAT_CROWDED": [2, 1],
	"CROWDED": [3, 0],
}

function CrowdingIcon({ crowdingLevel }) {

	const iconCount = iconCounts[crowdingLevel];

	if (iconCount) {

		let iconOn = [];
		let iconOff = [];

		for (let i = 0; i < iconCount[0]; i++) {
			iconOn.push(<span key={i}>{afIcon}</span>);
		}

		for (let j = 0; j < iconCount[1]; j++) {
			iconOff.push(<span key={j}>{afIconOff}</span>);
		}

		return (
			<>
				{iconOn}
				{iconOff}
			</>
		)
	}
	return null;
}

function Crowding({ crowding }) {

	if (crowding) {
		return (
			<div className="Crowding">
				<CrowdingIcon crowdingLevel={crowding} />
			</div>
		)
	}

	return null;
}


export default Crowding;
