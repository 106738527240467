import React from 'react';

import PropTypes from 'prop-types';

import cityMotionLogo from 'img/svg/citymotion-app-logo.svg';



function StaticHeader(props) {

    const {
        screen,
    } = props;

    const {
        buildingLogo,
        prettyName,
        address,
    } = screen;

    return (
        <div className="StaticHeader">
            <div className="AppLogo">
                <img src={cityMotionLogo} alt="CityMotion" />
            </div>
            <div className="CustomerInfo">
                <div className="Container">
                    <div className="CustomerLogo">{ buildingLogo ? <img src={buildingLogo} alt="Logo" /> : null }</div>
                    <h2>
                        {prettyName || 'CityMotion'}
                        <div className="address">{address}</div>
                    </h2>
                </div>
            </div>
        </div>
    );
}

StaticHeader.propTypes = {
    screen: PropTypes.shape({
        buildingLogo: PropTypes.string,
        prettyName: PropTypes.string,
        address: PropTypes.string,
    })
}

StaticHeader.defaultProps = {
    screen: {
        buildingLogo: null,
        prettyName: 'CityMotion',
        address: null,
    },
};

export default StaticHeader;
