import React, {
	useState,
} from 'react';

import qaList from 'components/Tester/qa-list.json';

function QAList({ setNativeURL, handleNativeSubmit }) {

	const domains = [
		{
			name: 'Current Server',
			url: 'https://' + window.location.host,
		},
		{
			name: 'citymotion.io (ie: Mobile Safari)',
			url: 'https://citymotion.io'
		},
		{
			name: 'staging.citymotion.io (ie: Mobile Safari)',
			url: 'https://staging.citymotion.io',
		},
		{
			name: 'Web View in a Third Party App',
			url: 'Web View',
		},
		{
			name: 'dev localhost',
			url: 'https://localhost:8000',
		}
	];

	const [domain, setDomain] = useState(domains[0]);


	function handleClick(url) {
		const newUrl = domain.url + url;
		if (domain.url === 'Web View') {
			setNativeURL(newUrl);
			handleNativeSubmit("webview");
		} else {
			window.open(newUrl, "_new");
		}
	}

	return (
		<div className="QAList">
			<h1>Check QA List of Hub and Anywhere Plugin Links</h1>

			<h2>Select which QA environment to load in</h2>

			<div className="select-group">
				{domains.map((d, i) =>
					<button key={i} className={`${domain.url === d.url && 'selected'}`} onClick={() => setDomain(d)}>{d.name}</button>
				)}
			</div>

			<h2>Preset links of common QA screens</h2>

			{
				qaList.map((link, i) => {
					return (
						<div key={i} className="qalink">
							<button type="Button" onClick={() => handleClick(link.url)}>{i}. {link.name}</button>
						</div>
					)
				})
			}


		</div>
	);
}

export default QAList;
