import AppHeader from 'components/AppHeader/AppHeader';
import AppLocation from 'components/AppLocation';
import AppStatus from 'components/AppStatus';
import CardColumns from 'components/MobilityScreen/CardColumns';
import PoweredByAF from 'components/common/PoweredByAF';
import ScrollArea from 'components/ScrollArea';
import TabBar from 'components/TabBar/TabBar';
import UpdateAnimation from 'components/UpdateAnimation';
import Updater from 'components/MobilityScreen/Updater';

function MobilityScreen({
	params,
	loading,
	updating,
	action,
	statusMessage,
	statusInstructions,
	statusContactSupport,

	userLocation,
	appLocation,

	address,
	customer,

	showAppStatus,
	selectedTab,

	setNavigation,
	updateApp,
	toggleTripScreen,
	onNavBarButtonClick,
	setCardFavorite,

	toggleCardScreen,

	cards,
	screen,
	weather,
	alerts,

	showCardScreen,
	showTripScreen

}) {

	const {
		key,
		header,
		menu,
		tripPlan,
		barPosition,
	} = params;


	return (
		<div className="MobilityScreen">
			<UpdateAnimation params={params} loading={loading} updating={updating} />

			{key && (header || menu) ? <AppHeader
				updateApp={updateApp}
				userLocation={userLocation}
				appLocation={appLocation}
				setNavigation={setNavigation}
				menu={menu}
				screen={screen}
				address={address}
				weather={weather}
				customerApiKey={key} /> : null}

			<AppStatus
				showAppStatus={showAppStatus}
				params={params}
				action={action}

				statusMessage={statusMessage}
				statusInstructions={statusInstructions}
				statusContactSupport={statusContactSupport}

				// For error reporting
				customer={customer}
				screen={screen}
			/>

			<ScrollArea params={params}>

				{/* {tripPlan ? <TripPromoCard
					appLocation={appLocation}
					userLocation={userLocation}
					screen={screen}
					toggleTripScreen={toggleTripScreen} /> : null} */}

				<CardColumns
					toggleTripScreen={toggleTripScreen}
					toggleCardScreen={toggleCardScreen}

					appLocation={appLocation}
					userLocation={userLocation}

					action={action}
					params={params}
					selectedTab={selectedTab} // filters cards

					cards={cards}
					screen={screen}
					weather={weather}
					alerts={alerts}

					showCardScreen={showCardScreen}
					setCardFavorite={setCardFavorite}
				/>

				{/*<PoweredByAF />*/}

			</ScrollArea>

			{barPosition ? <TabBar
				params={params}
				customer={customer}
				selectedTab={selectedTab}
				onNavBarButtonClick={onNavBarButtonClick} /> : null}

			<AppLocation
				customer={customer}
				screen={screen}
				address={address}
				params={params}
				updateApp={updateApp} />

			<Updater
				params={params}
				appLocation={appLocation}
				updateApp={updateApp}
				showTripScreen={showTripScreen}
			/>
		</div>
	)

};

export default MobilityScreen
