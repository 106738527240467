import { useContext, useEffect, useRef } from 'react';

import { GoogleMapsContext } from 'components/context/GoogleMapsProvider';
import LocationSearchDropdown from 'components/LocationSearchField/LocationSearchDropdown';
import lokalise from 'utils/lokalise';

const url = new URL(window.location.href || document.location.search);
const queryParams = new URLSearchParams(url.search);
const searchLanguage = queryParams.has('lang') ? queryParams.get('lang') : 'en';

export default function GoogleMapsPlacesSearcher({ inputValue, searchAreaLocation, setResults, searchResults, searchMessage, isActive, onSelect, setUserPosition }) {

    const gmaps = useContext(GoogleMapsContext);

    const _throttle = useRef(null);
    // const _queue = useRef([]);

    useEffect(() => {
        // Each new request can cancel the previous 
        if (_throttle.current) {
            clearTimeout(_throttle.current);
            _throttle.current = false;
        }
        _throttle.current = setTimeout(() => {
            // Throttle call after 200ms for about 10qps
            doSearch();
            _throttle.current = false;
        }, 200);

    }, [inputValue]);

    function doSearch() {
        if (inputValue && inputValue.length > 0) {
            gmaps.getGMPlacesResults(inputValue, searchAreaLocation, searchLanguage).then((newResults) => setResults(newResults)).catch((e) => { });
        }
    }

    function onUserSelectLocation(location) {
        gmaps.getGMPlacesLocation(location).then((parsedLocation) => onSelect(parsedLocation)).catch((e) => onSelect(location));
    };

    return (
        <LocationSearchDropdown isActive={isActive} onSelect={onUserSelectLocation} searchResults={searchResults}>
            <div className="search-message">{searchMessage}</div>
            <div className="select-item" onClick={setUserPosition}>{lokalise.getKey("ts10p")}</div>
        </LocationSearchDropdown>
    );
}