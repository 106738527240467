import React, { Component } from 'react';
import fields from './url-generator.json';
import ActivationCodeGenerator from './ActivationCodeGenerator';

export default class URLGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Form values are optional as they are added to this object on event
            formValues: Object.fromEntries(
                Object.entries(fields).map(([key, val]) => {
                    const defaultVal = [val.name, ""];
                    if (val.defaultOption) {
                        defaultVal[1] = Object.values(val.options)[val.defaultOption - 1];
                    } else if (val.defaultText) {
                        defaultVal[1] = val.defaultText;
                    }
                    return defaultVal;
                }),
            ),
            formFields: fields,
            shortCode: '',
            updateShortCode: '',
            playlist: {},
            slideId: '',
            slideUrl: '',
            isUpdateInstance: false,
            error: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateURL = this.updateURL.bind(this);
        this.copyURL = this.copyURL.bind(this);        
    }

    componentDidMount() {
        this.updateURL();
    }

    handleInputChange(event) {
        const { name, value } = event.target;

        const newFormValues = this.state.formValues;

        newFormValues[name] = value;

        this.setState(
            {
                formvalues: newFormValues,
            },
            () => {
                this.updateURL();
            }
        );
    }

    handleDropdownChange(event) {
        let newFormValues = this.state.formValues;

        const { name, value } = event.target;
        newFormValues[name] = value;

        // console.log('handleDropdownChange', name, value, newFormValues);

        this.setState(
            {
                formValues: newFormValues,
            },
            () => {
                this.updateURL();
            }
        );
    }

    handleMultiSelectChange(event) {
        let newFormValues = this.state.formValues;
        const { name, value } = event.target;

        if (!newFormValues[name]) {
            newFormValues[name] = value;
        }
        else if (value === 'd') {
            newFormValues[name] = ""
        }
        else if (!newFormValues[name].includes(value)) {
            newFormValues[name] = newFormValues[name].length > 0 ? newFormValues[name] + ',' + value : value;
        }
        else if (newFormValues[name].includes(value)) {
            const removeIndex =  newFormValues[name].indexOf(value);
            if (removeIndex === 0) {
                //Cut the first character and the following comma
                newFormValues[name] = newFormValues[name].slice(2);
            }
            else {
                //Cut the first character and the previous comma
                newFormValues[name] = newFormValues[name].slice(0, removeIndex - 1) + newFormValues[name].slice(removeIndex + 1);
            }
        }

        this.setState(
            {
                formValues: newFormValues,
            },
            () => {
                this.updateURL();
            }
        );
    }

    handleSubmit() {
        window.open(this.state.url, '_new');
    }

    updateURL() {
        let form = this.state.formValues;

        var url = `https://${form.environment}/${form.context || ''}?`;
        var i = 0;

        // Take each form value and create it as a parameter except for certain ones
        for (let name in form) {
            let value = form[name];
            if (
                name !== 'environment' &&
                name !== 'context' &&
                value
            ) {
                if (i === 0) {
                    url += `${name}=${value}`;
                } else {
                    url += `&${name}=${value.replace(/\s/g, '')}`;
                }
            }
            i++;
        }

        // console.log('URL?', url);

        this.props.setNativeURL(url);

        this.setState({
            url,
        });
    }

    copyURL() {
        this.refs.textarea.select();
        this.refs.copied.opacity = 1;
        document.execCommand('copy');
        setTimeout(() => {
            this.refs.copied.opacity = 0;
        }, 2000);
        return false;
    }

    //Get plugin instance that matches activation key
    async getSlideUrl() {

        const domain = this.state.formValues.environment === 'staging.citymotion.io'
        ? 'https://ts-business-core-staging.herokuapp.com'
        : this.state.formValues.environment === 'citymotion.io'
        ? 'https://biz.actionfigure.ai'
        : 'http://localhost:6060'
        const headers = { headers: { 'X-api-key': process.env.BIZ_API_KEY } };

        const url = `${domain}/api/playlists/getPluginPlaylist/${this.state.shortCode}`;

        //Making call to BIZ
        const response = await fetch(url, headers)
            .then(response => response.json())
            .catch(e => {
                this.setState({
                    ...this.state,
                    error: true
                });
            });
        
        if (!response) {
            return;
        }
        
        const updatedFormValues = { ...this.state.formValues };

        const urlObject = new URLSearchParams(response.slide.url.split('?')[1]);

        // Update current state to BIZ values
        for (const [key, value] of Object.entries(updatedFormValues)) {
            //If the value isn't BIZ specific, update
            if (urlObject.get(key)) {
                if (value === null) {
                    updatedFormValues[key] = urlObject.get(key)
                }
                else {
                    updatedFormValues[key] = urlObject.get(key).toString()
                }
            }
        }

        this.setState({
            ...this.state,
            formValues: updatedFormValues,
            updateShortCode: response.shortCode,
            playlist: response.playlist,
            slideId: response.slide.id,
            slideUrl: response.slide.url,
            shortCode: this.state.shortCode,
            isUpdateInstance: true,
            error: false
        },
        () => {
            this.updateURL();
        })

        return;
    }

    render() {
        return (
            <div className="URLGenerator">
                <hr />
                <h1>Generate a CityMotion Plugin URL</h1>
                <p className="desc">
                    <em>
                        You can use{' '}
                        <a href="https://transitscreen.io/admin/customers/edit/4">
                            dev test customer key
                        </a>{' '}
                        for TESTING only. <br />
                        <code>
                            kDgUcNxt25pUpxy8ivQ5UYSvXc4dF0acwzLCLu3wMHa0rDZLGDcm3rsZ2LlMjsjs
                        </code>
                        <br />
                        <a href="https://transitscreen.io/admin/customers">
                            Generate your own key
                        </a>{' '}
                        for public customers.
                    </em>
                </p>
                <hr />
                <div className="ActivationCodeInput">
                    <h1>Update existing Activation Code (Beta / Optional)</h1>
                    <div className="activationInput">
                        <div className="code pure-u-1-4">
                            <p>Code:</p>
                            <input className={"codeInput " + (this.state.error ? "error" : null)} value={this.state.shortCode} onChange={(e) => this.setState({
                                ...this.state,
                                shortCode: e.target.value
                            })} />
                            {
                                this.state.error 
                                ? <p style={{"color": "#FD4A5C"}}>Invalid Activation Code</p>
                                : <></>
                            }
                        </div>
                        <div className="pure-u-1-8">
                            <p style={{'margin': '2em 1em'}}>or</p>
                        </div>
                        <div className="url pure-u-1-3">
                            <p>Customer facing URL:</p>
                            <input className="urlInput" value={`https://api.actionfigure.ai/`} disabled />
                        </div>
                        <div className="load pure-u-1-4">
                            <p style={{"visibility": "hidden"}}>hidden</p>
                            <div className="loadButton" onClick={() => this.getSlideUrl()}>Load</div>
                        </div>
                    </div>
                </div>
                <hr />
                {this.state.formFields.map((field, i) => {
                    return (
                        <div className="FieldGroup" key={i}>
                            {field.type === 'input' ||
                                field.type === 'inputWithDropdown' ? (
                                <InputField
                                    label={field.inputLabel}
                                    name={field.name}
                                    value={this.state.formValues[field.name]}
                                    handleInputChange={this.handleInputChange}
                                    inputAttrs={field.attrs || {}}
                                />
                            ) : null}

                            {field.type === 'inputWithDropdown' ||
                                field.type === 'dropdown' ? (
                                <SelectDropdown
                                    label={field.dropdownLabel}
                                    name={field.name}
                                    value={this.state.formValues[field.name]}
                                    options={field.options}
                                    handleDropdownChange={this.handleDropdownChange}
                                    showArrow={field.showArrow}
                                />
                            ) : null}

                            {field.type === 'multiSelect' ? (
                                <MultiSelect
                                    label={field.dropdownLabel}
                                    name={field.name}
                                    value={this.state.formValues[field.name]}
                                    options={field.options}
                                    handleMultiSelectChange={this.handleMultiSelectChange}
                                    showArrow={field.showArrow}
                                />
                            ) : null}
                        </div>
                    );
                })}
                <hr />
                Final URL - Tap to Copy{' '}
                <span ref="copied" style={{ color: '#1C99EF' }}></span>
                <br />
                <div className="Field">
                    <textarea
                        ref="textarea"
                        value={this.state.url}
                        onClick={this.copyURL}
                    ></textarea>
                </div>
                {!window.webkit || !window.webkit.messageHandlers ? (
                    <button className="Submit" onClick={this.handleSubmit}>
                        Open URL in New Window
                    </button>
                ) : (
                    <div>
                        <button
                            className="Submit"
                            onClick={() =>
                                this.props.handleNativeSubmit('webview')
                            }
                        >
                            OPEN IN WEBVIEW/PLUGIN
                        </button>

                        <br />
                        <br />
                        <button
                            className="Submit"
                            onClick={() =>
                                this.props.handleNativeSubmit('safari')
                            }
                        >
                            Open in Safari
                        </button>
                        <button
                            className="Submit"
                            onClick={() =>
                                this.props.handleNativeSubmit('iframe')
                            }
                        >
                            Open in iFrame
                        </button>
                    </div>
                )}
                <h2>Important Notes</h2>
                <ul>
                    <li>
                        * coordinates will override locationCode if both are
                        present
                    </li>
                    <li>* coordinates will serve a Hub if within radius</li>
                    <li>
                        * Customers with Anywhere disabled cannot use
                        coordinates, will see error page
                    </li>
                    <li>
                        * External Links decides if buttons will show but not
                        necessarily if the phone can navigate to the app
                        properly
                    </li>
                </ul>
                <ActivationCodeGenerator pluginParams={this.state.formValues} updateShortCode={this.state.updateShortCode} playlist={this.state.playlist} slideId={this.state.slideId} slideUrl={this.state.url} isUpdateInstance={this.state.isUpdateInstance}/>
            </div>
        );
    }
}

function InputField({ value, options, inputAttrs, label, name, handleInputChange }) {
    return (
        <div className="Field">
            <label title={label}>
                <span>
                    {label}
                    {label && <br />}
                </span>
                {name}=
            </label>
            <input
                autoCorrect="off"
                autoCapitalize="none"
                type="text"
                value={value}
                name={name}
                onChange={handleInputChange}
                placeholder="None"
                {...inputAttrs}
            />
        </div>
    );
}

function SelectDropdown({
    value,
    options,
    label,
    name,
    handleDropdownChange,
    showArrow,
}) {
    let optionComponents = [];
    for (var key in options) {
        optionComponents.push(
            <option key={key} value={options[key]}>
                {key}
            </option>
        );
    }

    return (
        <div className={`Field Dropdown ${showArrow ? 'show-arrow' : ''}`}>
            <label title={label}>
                <span>
                    {label}
                    {label && <br />}
                </span>
                {!showArrow ? `${name}=` : ''}
            </label>
            <select name={name} value={value} onChange={handleDropdownChange}>
                {optionComponents}
            </select>
            {showArrow ? <div>⤴</div> : null}
        </div>
    );
}

function MultiSelect({
    value,
    options,
    label,
    name,
    handleMultiSelectChange,
    showArrow,
}) {
    let optionComponents = [];
    for (var key in options) {
        optionComponents.push(
            <option key={key} value={options[key]}>
                {key}
            </option>
        );
    }

    return (
        <div className={`Field Dropdown ${showArrow ? 'show-arrow' : ''}`}>
            <label title={label}>
                <span>
                    {label}
                    {label && <br />}
                </span>
                {!showArrow ? `${name}=` : ''}
            </label>
            <select name={name} value={value} onChange={handleMultiSelectChange} multiple>
                {optionComponents}
            </select>
            {showArrow ? <div>⤴</div> : null}
        </div>
    );
}
