import React, {
	Component
} from 'react';

import Cookies from 'universal-cookie';
import Icon from 'components/Icon';
import ReactGA from 'react-ga4';
import Tappable from 'components/common/Tappable';
import analytics from 'utils/analytics';
import config from 'config.js';

const UniversalCookie = new Cookies();

function FavoriteButton({ isFavorite, setCardFavorite, showOnlyWhenFavorited, card }) {
	function handleTapEvent() {
		setCardFavorite(!isFavorite, card);
	}

	if (showOnlyWhenFavorited === true) {
		if (!isFavorite) return null;
	}

	return (
		<div className={isFavorite ? "FavoriteButton is-favorite" : "FavoriteButton"}>
			<Tappable className="FavoriteTap" pressDelay={1500} onTap={handleTapEvent}>
				<Icon category="ui" name="favorite" />
			</Tappable>
		</div>
	);
}

export default FavoriteButton
