import ExternalAppLinkTester from 'components/Tester/ExternalAppLinkTester';
import QAList from 'components/Tester/QAList.js';
import URLGenerator from 'components/Tester/URLGenerator.js';
import { useState } from 'react';

function Tester() {

    const [nav, setNav] = useState('urlgen');

    const navList = [
        {
            id: 'urlgen', name: 'URL Generator'
        },
        {
            id: 'qa', name: 'QA List'
        },
        {
            id: 'applink', name: 'External App Link Tester'
        },
    ];


    function handleNativeSubmit(outlet) {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.navigateTo.postMessage(outlet);
        }

        if (window.Android && window.Android.navigateTo) {
            window.Android.navigateTo(outlet);
        }

    }

    function setNativeURL(url) {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.newURL.postMessage(url);
        }

        if (window.Android && window.Android.setURL) {
            window.Android.setURL(url);
        }
    }



    return (
        <div className="Tester">
            <h1 className="page-title">Tester</h1>

            <div className="button-group">{navList.map((navItem) => <button key={navItem.id} onClick={() => setNav(navItem.id)} className={navItem.id === nav ? 'selected' : ''}>{navItem.name}</button>)}</div>

            {nav === 'applink' ? <ExternalAppLinkTester /> : null}
            {nav === 'urlgen' ? <URLGenerator handleNativeSubmit={handleNativeSubmit} setNativeURL={setNativeURL} /> : null}
            {nav === 'qa' ? <QAList handleNativeSubmit={handleNativeSubmit} setNativeURL={setNativeURL} /> : null}

        </div>
    )
}

export default Tester
