import React from 'react';

import lokalise from 'utils/lokalise';
import CardRows from 'utils/cardrows';

function PredictionMinutesAway(props) {
	const {
		predictionMinutesAway,
		vehicleGroup,
		timeDisplay,
	} = props;

	// Get predictions
	var firstPrediction, secondPrediction;
	if (predictionMinutesAway !== null) {
		firstPrediction = CardRows.displayArrivalTime(predictionMinutesAway, timeDisplay);
		// firstPrediction = firstPrediction === 0 ? "<1" : firstPrediction; // Converts 0 to <1

		if (vehicleGroup && vehicleGroup[1] && vehicleGroup[1].predictionMinutesAway !== null) {
			secondPrediction = CardRows.displayArrivalTime(vehicleGroup[1].predictionMinutesAway, timeDisplay);
		}
	}

	return ( 
		<div className={`PredictionMinutesAway ${timeDisplay}`}>
			{secondPrediction ? null : <div className="Separator"></div>}
			{firstPrediction ? <PredictionText value={firstPrediction} position={1} /> : null}
			<div className="Separator">{secondPrediction && firstPrediction ? ',' : null}</div>
			{secondPrediction ? <PredictionText value={secondPrediction} position={2} /> : null }
			{
				<div className="Unit" aria-hidden="true">
					{!timeDisplay || timeDisplay === 'remaining' ? lokalise.getKey("ts3i") : null}
				</div>
			}
		</div>
	)
}

function PredictionText(props) {
	const {
		value,
		position,
		timeDisplay
	} = props;

	const prefix = position === 1 ? lokalise.getKey("ts11o") : lokalise.getKey("ts11p");

	const voText = `${prefix} ${value} ${lokalise.getKey("ts3i")}`;

	return (
		<div className="PredictionText">
			<span className="voiceover-text voiceover-fill">{voText}</span>
			<span aria-hidden="true">{value}</span>
		</div>
	);
}

export default PredictionMinutesAway;
