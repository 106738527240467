
// Eventually this component could be re-used in the menu header so we let children props pass in
function LocationSearchDropdown({ isActive, onSelect, searchResults, children }) {

    function listResults() {
        if (!searchResults) return null;
        return searchResults.map((result, i) => {
            if (!result) return null;
            return (
                <div className="Result" key={i}>
                    <button className="select-item" onClick={() => onSelect(result)}>
                        {result.name}
                        <div className="select-item-address">{result.address}</div>
                    </button>
                </div>
            )
        });
    }

    return (
        <div className={`LocationSearchDropdown ${isActive ? 'active' : ''}`}>
            {children}
            {listResults()}
        </div>
    )
}

export default LocationSearchDropdown;
