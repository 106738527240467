import React, {
	Component,
} from 'react';
import AlertIcon from 'img/svg/alert-light.svg';
import InsertHTML from 'components/InsertHTML.js';


/*
 * Route alert data is merged into `predictions` in Sorters.helper.js
 */
function RouteAlert(props) {
	const {
		rowData,
		isExpanded,
	} = props;

	const {
		alerts,
	} = rowData;

	if (alerts && alerts.length > 0) {
		return alerts.map((alert) => {
			if (!alert) return null;
			return (
				<div key={alert.id} className="RouteAlert">
					<div className="AlertIcon"><img src={AlertIcon} alt="Route Alert:" /></div>
					<div className="AlertContent">
						<p className="AlertHeader">{alert.header}</p>
						{isExpanded ? <p className="AlertDescription"><InsertHTML html={alert.description} /></p> : null}
					</div>
				</div>
			);
		});
	}
	return null;
}

export default RouteAlert;
