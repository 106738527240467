import React, {
	useState
} from 'react';
import AlertIcon from 'img/svg/alert-light.svg';
import InsertHTML from 'components/InsertHTML.js';


/*
 * Agency Alert Card format is defined/created in utils/sorter
 */
export default function AgencyAlertCard(props) {

	const [showMore, toggleShow] = useState(false);

	const {
		card
	} = props;
	const {
		alerts
	} = card;

	function getAlerts() {
		return alerts.map((alert) => {

			const description = showMore && alert.description ? <InsertHTML html={alert.description} /> : null;

			const link = showMore && alert.url ? <a href={alert.url} target="_new">Visit Alert Info Page</a> : null;

			const ellipsis = !showMore && alert.description ? <span className="ShowMore">(tap to show more)</span> : null;

			return (
				<div key={alert.id} className="AgencyAlert">
					<div className="AlertIcon"><img src={AlertIcon} alt="Route Alert:" /></div>
					<div className="AlertContent">
						<div className="AlertHeader">{alert.header} {ellipsis}</div>
						<div className="AlertDescription">{description}</div>
						<div className="AlertLink">{link}</div>
					</div>
				</div>
			);
		});
	}

	return (
		<div className="Card AgencyAlertCard">
			<span role="button" aria-label="Double tap to show more details" onClick={() => toggleShow(!showMore)}>
				<div className="Content">
					<Header {...props} />
					{getAlerts()}
				</div>
			</span>
		</div>
	)
}

function Header(props) {
	let agency = props.card.agency;

	let agencyLogo = agency.style.logoSvgOnlight;
	let headerTitle = agency.fullName;

	const fontSize = headerTitle.length > 20 ? '0.9em' : '1.2em';

	// Borrows CSS from CardHeader.scss
	return (
		<div className="CardHeader" style={{fontSize}}>
			<div className="AgencyLogo">
				<img src={agencyLogo} alt="" />
			</div>
			<h2 className="StopName">{headerTitle}</h2>
		</div>
	)
}
