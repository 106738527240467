import ButtonGroup from "components/common/ButtonGroup";
import analytics from "utils/analytics";
import googlemaps from "utils/googlemaps";
import lokalise from "utils/lokalise";
import styled from "styled-components";
import { useSearchParams } from 'react-router-dom';

const Wrapper = styled.div`
    margin: 0.5em;
`;

export default function CardButtons({ googleMapsRouting, setCardFavorite, isFavorite, card, openTripScreenFromCardScreen }) {

    const [params, setParams] = useSearchParams();

    const leg = googleMapsRouting?.routes[0].legs[0] || null;

    let buttons = [];

    if (card && !card.agency.mode.match(/floating|flight/)) {
        buttons.push({
            text: lokalise.getKey('button_start_your_trip'),
            iconCategory: "ui",
            iconName: "googleMapsIcon",
            onClick: () => {
                if (leg) {
                    const url = googlemaps.getGMDirectionsURL({
                        startingLocation: { address: leg.start_address },
                        endingLocation: { address: leg.end_address },
                        travelMode: "walking"
                    })

                    analytics.postToDataDrop({
                        googleMapsURL: url,
                        firstLegStart: leg.start_address,
                        firstLegEnd: leg.end_address,
                        travelMode: "walking"
                    }, 'Card Screen Button Tap: Start GM Trip');

                    window.open(url, "new");

                } else {
                    const url = googlemaps.getGMHomeURL();
                    window.open(url, "new");
                }
            }
        });
    }

    if (params.has('favorites') && params.get('favorites') === 'true') {
        buttons.push({
            text: isFavorite ? lokalise.getKey('button_remove_fav') : lokalise.getKey('button_add_fav'),
            iconCategory: "ui",
            iconName: "favoriteAlt",
            onClick: () => {
                // Data drop is in App.js
                setCardFavorite(!isFavorite, card);
            }
        });
    };

    if (card && !card.agency.mode.match(/floating|flight/) && params.has('tripPlan') && params.get('tripPlan') === 'true') {
        // console.log(card);
        buttons.push({
            text: lokalise.getKey('button_plan_your_trip'),
            iconCategory: "ui",
            iconName: "compassAlt",
            onClick: () => {
                if (leg) {
                    const gmap = leg.start_location.toJSON();

                    analytics.postToDataDrop({
                        startingAddress: leg.start_address,
                        startingCoords: { latitude: gmap.lat, longitude: gmap.lng }
                    }, 'Card Screen Button Tap: Plan your trip');

                    openTripScreenFromCardScreen({
                        name: leg.start_address,
                        address: leg.start_address,
                        coords: { latitude: gmap.lat, longitude: gmap.lng }
                    });
                } else {
                    openTripScreenFromCardScreen();
                }
            }
        });
    }

    return (
        <Wrapper>
            <ButtonGroup buttons={buttons} />
        </Wrapper>
    )
}