import React, {
    Component
} from 'react';

import AlertIcon from 'img/svg/alert-light.svg';


class StopAlerts extends Component {

    getAgencyAlerts = () => {
        // console.log(this.props);
        if (this.props.card.agency && this.props.card.agency.alert_ids) {
            let alertIds = this.props.card.agency.alert_ids;
            return alertIds.map((alertId) => {

                return this.props.alerts.map((alert) => {
                    if (alert.id === alertId && alert.scope === "stop") {
                        return (
                            <div className="StopAlert" key={alert.id}>
                                <div className="AlertIcon"><img src={AlertIcon} alt="Route Alert:" /></div>
                                <div className="AlertBody">
                                    <div className="AlertHeader">{alert.header}</div>
                                    <div className="AlertDescription">{alert.description}</div>
                                </div>
                            </div>
                        )
                    }
                    return null
                })

            })
        }
        return null
    }

    render() {
        if (this.props.alerts && this.props.alerts.length > 0) {

            return (
                <div className="StopAlerts">
                    {this.getAgencyAlerts()}
                </div>
            )
        }
        return null
    }
}


export default StopAlerts;
