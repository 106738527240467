import Cookies from 'universal-cookie';

import config from 'config';

const UniversalCookie = new Cookies();

/*
 * CardColumns helper provides sorting within columns and between columns
 * Regards the UI portion of the visual card sorting, and does not manage data merging/integrity
 * Refer to SortHelper for first-pass data cleanup
 */
export default {

	negativeIncrement: 0,
	positiveIncrement: 1,

	/*
	 * Sort cards top down left right using the order given by PHP
	 * Uses the data in the order given by the API, no sort order is applied
	 */
	getCardColumns(cards, columnCount, params) {

		if (params && params.favorites && params.favorites === 'true') {
			const favs = UniversalCookie.get(config.cookies.favorites.name);
			cards = this.getSortedByFavorites(cards, favs);
		}

		// Return sorted single column
		if (columnCount === 1) {
			return [cards];
		}

		// Or distribute cards Pinterest style
		var columns = [];
		cards.forEach((card, position) => {
			var divis = position % columnCount; // Determines even, odd, etc
			for (var i = 0; i < columnCount; i++) {
				if (!columns[i]) {
					columns[i] = [];
				}
				if (divis === i) {
					columns[i].push(card);
				}
			}
		});
		return columns;
	},

	/*
	 * Sort cards top down left right using the order given by Admin UI
	 * Uses each card's context sortOrder to determine where things belong
	 * THE SEQUENCE OF ACTIONS IN THIS METHOD IS IMPORTANT
	 * @param cards that pre-contain property sortOrder with column row group
	 * @return columns array of arrays
	 */
	getCardColumnsHub(cards, columnCount, params) {
		const appSortOrderKeyValue = config.cmwCardProps.sortOrder;
		const apiSortOrder = config.apiProps.sortOrder; 
		const favs = UniversalCookie.get(config.cookies.favorites.name);
		// Create placeholder arrays
		let columns = [];
		for (let i = 0; i < columnCount; i++) {
			columns.push([]);
		}
		// Assign favorites ranking and move into columns
		cards.forEach((card, i) => {
			// Fav sorting
			if (params && params.favorites && params.favorites === 'true' && favs) {
				if (favs.indexOf(card.id) !== -1) {
					// console.log("ROW?", card[appSortOrderKeyValue].row);
					card.favorited = true;
					// We arbitrarily put it at the top of the stack
					card[appSortOrderKeyValue].row = this.negativeIncrement--;
					// In Mobile, go to top of first column
					if (columnCount === 1) {
						card[appSortOrderKeyValue].column = 1;
					}
				} else {
					card.favorited = false;
					// Important: Restore original API sort order
					if (card[apiSortOrder]) {
						const sortOrderString = card[apiSortOrder].toString();
						card[appSortOrderKeyValue].column = parseFloat(sortOrderString[0]);
						card[appSortOrderKeyValue].row = parseFloat(sortOrderString.slice(1, 3));
					}
				}
			}

			let colIndex = card[appSortOrderKeyValue].column - 1;
			if (!columns[colIndex]) {
				columns[colIndex] = [card];
			} else {
				columns[colIndex].push(card);
			}
		});
		this.negativeIncrement = 0;

		//Find the right last column that the cards would have occupied
		const lastCol = cards.reduce((prev, curr) => {
			return (prev.sortProps.column > curr.sortProps.column) ? prev : curr;
		}).sortProps.column;
		//If the cards naturally have less columns than the max columns set, then use number of columns that the API gave us
		columnCount = lastCol < columnCount ? lastCol : columnCount;
		// Restructures columns array for responsive design
		columns = this.responsiveDesignSort(columnCount, columns, params);
		// console.log("getCardColumnsHub", columnCount, columns);
		return columns;
	},

	// Special Rules for Responsive Design for HUB ONLY
	// Could be achieved with CSS Grid but in JS for Unit Testing
	responsiveDesignSort(columnCount, columns, params) {
		const appSortOrderKeyValue = config.cmwCardProps.sortOrder;
		let sortedColumns = columns;

		if (columnCount === 1) {
			const columnOne = [].concat(...columns); // Flatten into 1 array
			sortedColumns = [columnOne];
		} else if (columnCount === 2) {
			if (columns[3]) {
				// With 4 columns of data, put 3 below 1 and 4 below 2
				if (columns[2]) {
					sortedColumns[0].push(...columns[2]);
				}
				if (columns[3]) {
					sortedColumns[1].push(...columns[3]);
				}
			} else {
				// Otherwise put 3 below 2
				if (columns[2]) {
					sortedColumns[1].push(...columns[2]);
				}
			}
			// Important, remove the columns from the array
			sortedColumns.splice(3, 1);
			sortedColumns.splice(2, 1);
		} else if (columnCount === 3) {
			// 3 column, move col 4 cards below col 3
			if (columns[3]) {
				sortedColumns[2].push(...columns[3]);
			}
			// Important, remove the columns from the array
			sortedColumns.splice(3, 1);
		}

		// Lastly bubble favorite cards to the top
		if (columnCount === 1) {
			sortedColumns = sortedColumns.map((columnCards) => {
				return columnCards.sort((a, b) => {
					// In 1 column mobile, favorites must be pushed to the very top
					// But preserve the order of the concatenated columns
					if (a[appSortOrderKeyValue].column > b[appSortOrderKeyValue].column) return 1;
					if (a[appSortOrderKeyValue].column < b[appSortOrderKeyValue].column) return -1;

					if (a[appSortOrderKeyValue].row > b[appSortOrderKeyValue].row) return 1;
					if (a[appSortOrderKeyValue].row < b[appSortOrderKeyValue].row) return -1;
				});
			});
		} else {
			sortedColumns = sortedColumns.map((columnCards) => {
				// In multi-column layouts, favorite cards go to top of respective arrays by row
				return columnCards.sort((a, b) => {
					return (a[appSortOrderKeyValue].row - b[appSortOrderKeyValue].row);
				});
			});
		}

		// if (params.scroll === 'true') {
		// 	sortedColumns = sortedColumns.map(col => {
		// 		let logo = []
		// 		let weather = [];
		// 		let staticText = [];
		// 		let predictions = [];
		// 		col.forEach(card => {
		// 			if (card.type === 'logo') {
		// 				logo.push(card);
		// 			}
		// 			else if (card.type === 'weather') {
		// 				weather.push(card);
		// 			}
		// 			else if (card.type === 'staticText') {
		// 				staticText.push(card);
		// 			}
		// 			else {
		// 				predictions.push(card)
		// 			}
		// 		})
		// 		return [...logo, ...weather, ...staticText, ...predictions]
		// 	})
		// }

		return sortedColumns;
	},

	// Helper: Pushes favorited cards to the top of the array
	getSortedByFavorites(cards, favs) {
		if (favs && favs.length > 0) {
			var favCards = [];
			cards = cards.filter((card) => {
				let cardId = card.id;
				if (favs && favs.indexOf(cardId) !== -1) {
					favCards.push(card);
					return false
				}
				return true
			});
			cards = favCards.concat(cards);
		}
		return cards;
	},

	organizeCardsIntoColumns(cards, columns) {

	}
}
