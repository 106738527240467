import config from 'config';

export default {

	// AppSync endpoint is used by the Mobile App to sync certain customer data before app is ready
	// It can also be used to verify if key= are valid
	// @return { customer, screens }
	async getAppSync(apiKey) {
		return new Promise(async (resolve, reject) => {
			const encodedQueryUrl = `${config['production'].appSyncEndpoint}?key=${apiKey}`;
			try {
				const timedOut = setTimeout(() => {
					resolve(false);
				}, 15000);
				const response = await this.fetchJSON(encodedQueryUrl);
				// console.log("CUSTOMER", response);
				if (response && response.data) {
					resolve(response.data);
					clearTimeout(timedOut);
				}
				resolve(false); // Ignore bad responses
			} catch (e) {
				resolve(false); // Basically ignore the error
			}
		})
	},

	fetchJSON(encodedQueryUrl, method, data) {
		const body = method === 'POST' ? JSON.stringify({
			data,
		}) : null;
		return new Promise((resolve, reject) => {
			fetch(encodedQueryUrl, {
				method,
				body,
				cache: 'no-store', // always clear cache here
				mode: 'cors',
				redirect: 'follow',
				credentials: 'same-origin',
			}).then((response) => {
				if (response.status === 200) {
					response.json().then((json) => {
						resolve(json);
					}).catch((error) => {
						reject(error);
					});
				} else {
					reject(response);
				}
			}).catch((error) => {
				reject(error);
			});
		});
	}

};
