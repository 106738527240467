import BicycleRentContent from 'components/TripScreen/TripPlanScreen/TripPlanContent/BicycleRentContent';
import TripPlanContentStepBased from 'components/TripScreen/TripPlanScreen/TripPlanContent/TripPlanContentStepBased';
import TripPlanContentTransit from 'components/TripScreen/TripPlanScreen/TripPlanContent/TripPlanContentTransit';
import tripscreen from 'utils/tripscreen';

function TripPlanContent({ name, tripPlan, endingLocation }) {
    if (tripscreen.isTransitTrip(name)) {
        return <TripPlanContentTransit tripPlan={tripPlan} endingLocation={endingLocation} />
    }
    else if (tripscreen.isNonTransitTrip(name)) {
        return <TripPlanContentStepBased tripPlan={tripPlan} endingLocation={endingLocation} />
    }
    else {
        return <div className="TripPlanContent">Trip plan content for {name} coming soon... </div>
    }
}

export default TripPlanContent
