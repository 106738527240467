import React, {
	useState
} from 'react';

import Icon from 'components/Icon';
import Tappable from 'components/common/Tappable';
import lokalise from 'utils/lokalise';

function LocationList(props) {
	const {
		collection,
		titleKey,
		buttonClick,
		type,
		buttonDelete,
	} = props;

	let list = null;
	if (collection && collection.length) {
		list = collection.map((location, i) => {

			// Disallow screens with no navigation ability
			if (!location.coords || !location.coords.latitude || !location.coords.longitude) {
				return null;
			}

			const displayedLocation = location;
			displayedLocation.type = type;

			// @NOTE CS has sometimes duplicated Hub screens
			return (
				<li role="menuitem" key={i}>
					{buttonDelete ? <DeleteButton buttonDelete={buttonDelete} location={displayedLocation} /> : null}
					<Tappable className="button" role="button" onTap={() => buttonClick(displayedLocation)}>
						{displayedLocation.name}
						<div className="address">{displayedLocation.address || ''}</div>
					</Tappable>
				</li>
			);
		});
	}
	return <ul role="group">{list}</ul>;
}

function DeleteButton(props) {
	const {
		buttonDelete,
		location,
	} = props;

	const [prompt, setPrompt] = useState(false);

	function toggleDelete() {
		setPrompt(!prompt);
	}

	function doDelete() {
		buttonDelete(location);
	}

	return (
		<Tappable role="button" className={`delete button ${prompt ? 'prompt' : ''}`} onTap={toggleDelete}>
			{prompt ? <Tappable className="delete-tap-area" onTap={doDelete}><div className="prompt-text">{lokalise.getKey("ts12k")}</div></Tappable> : null}
			{!prompt ? <Icon category="ui" name="exit" /> : null}
		</Tappable>
	);
}

export default LocationList;
