import React from 'react';
import lokalise from 'utils/lokalise';

const ParkingCost = ({ card, rowData }) => {
    // console.log(rowData);

    let symbol = rowData.currency === 'USD' ? '$' : null;

    let priceText = null;
    if (!rowData.pricePerHour && rowData.price) {
        priceText = (
            <div className="prediction">
                {symbol}{rowData.price} <span className="unit">{lokalise.getKey("ts11j")}</span>
            </div>
        );
        if (rowData.price === 99999) {
            priceText = <div className="prediction unknown">{lokalise.getKey("ts11k")}</div>;
        }
    }

    let parkingText = null;

    if (rowData.parkingTypes) {
        parkingText = rowData.parkingTypes.map((garage, i) => {
            let spaces = garage.type !== 'General' ? `${garage.type} ${lokalise.getKey("ts11l")}` : `${lokalise.getKey("ts11l")}`;
            return (
                <div key={i} className="prediction">
                    {garage.availableSpaceCount} <span className="unit">{spaces}</span>
                </div>
            );
        });
    }

    let waitText = null;
    if (rowData.predictionString) {
        let text = rowData.predictionString.replace('Minute Wait', '');
        waitText = (
            <div className="prediction">
                {text} <span className="unit">{lokalise.getKey("ts11m")}</span>
            </div>
        );
    }

    return (
        <div className="ParkingCost">
            {rowData.pricePerHour ? `${symbol}${rowData.pricePerHour} / ${lokalise.getKey("ts11n")}` : null}
            {priceText}
            {parkingText}
            {waitText}
        </div>
    )
}
export default ParkingCost