import React, {
	Component
} from 'react';

import CardRows from 'utils/cardrows';
import lokalise from 'utils/lokalise';

class ExpandedInfo extends Component {
	constructor(props) {
		super(props);

		this.expandedHeight = 50; // Fallback default value
		this.expandedInfoDiv = React.createRef();

		this.getPredictions = this.getPredictions.bind(this);
		this.updateVisibility = this.updateVisibility.bind(this);
	}

	componentDidMount() {

		if (this.expandedInfoDiv.current) {
			const height = this.expandedInfoDiv.current.offsetHeight + 10;
			this.expandedHeight = height;
			this.updateVisibility(this.props.isExpanded);
		}
	}

	componentDidUpdate() {
		// console.log("Expand me?", this.props.isExpanded);
		this.updateVisibility(this.props.isExpanded);
	}

	getPredictions() {
		// console.log("Get additional predictions", this.props.vehicleGroup);
		// Remove first two and limit it to 6 future
		let moreVehicles = this.props.vehicleGroup.slice(2, 8);

		if (moreVehicles.length === 0) return null;
		let limit = this.props.timeDisplay === 'actualAMPM'
		? 1
		: this.props.timeDisplay === 'actual' ||  this.props.timeDisplay === 'actual24'
		? 3
		: moreVehicles.length;

		limit = moreVehicles.length < limit ? moreVehicles.length : limit;
		
		return moreVehicles.map((vehicle, i) => {
			if (limit < i) {
				return null;
			}			
			return (
				<span key={i}>
					{CardRows.displayArrivalTime(vehicle.predictionMinutesAway, this.props.timeDisplay)}
					{limit >= i + 1 && moreVehicles[i+1] !== undefined  ? ',' : null}
				</span>
			);
		});
	}

	/*
	 * Changes the visibility of row DOM element
	 * Sets a CSS height of either 0 or the measured height
	 */
	updateVisibility(isExpanded) {
		if (this.expandedInfoDiv.current) {
			const ele = this.expandedInfoDiv.current;
			// console.log("Expand?", isExpanded, this.expandedHeight);
			ele.style.height = isExpanded ? `${this.expandedHeight}px` : '0px';
		}
	}

	render() {
		if (this.props.vehicleGroup && this.props.vehicleGroup.length > 2) {
			return (
				<div className="ExpandedInfo" ref={this.expandedInfoDiv}>
					<div className={`ExpandedInfoInner ${this.props.timeDisplay}`}>
						<div className="label">{lokalise.getKey('ts4j')} </div>
						<div className="numbers">{this.getPredictions()}</div>
					</div>
				</div>
			);
		}
		return null;
	}
}
export default ExpandedInfo;
