import React, {
    useEffect,
    useRef,
    useState
} from 'react';

import { Map } from 'react-map-gl';
import MapLanguage from 'components/common/MapLanguage';
import TripPlanMapUI from 'components/TripScreen/TripPlanScreen/TripPlanMapUI';
import WebMercatorViewport from 'viewport-mercator-project';
import config from 'config';

function TripPlanMap({ showTripPlanScreen, tripPlan, startingLocation, endingLocation }) {

    const mapWrapper = useRef();
    const mapRef = useRef();

    const [mapViewport, setMapViewport] = useState({
        width: 390, // This is just a default it gets re-calculated in onMapLoad
        height: 350, // This is generally fixed
    });

    const [viewState, setViewState] = useState({
        latitude: startingLocation && startingLocation.coords.latitude || config.mapboxDefaultMapCenter.latitude,
        longitude: startingLocation && startingLocation.coords.longitude || config.mapboxDefaultMapCenter.longitude,
        zoom: startingLocation ? 10 : 3,
    })

    const [mapStyle, setMapStyle] = useState(config.mapboxLightStyle);

    function onMapLoad() {
        const searchParams = new URLSearchParams(window.location.search || document.location.search);
        if (searchParams.get("ui") === 'dark') {
            setMapStyle(config.mapboxDarkStyle);
        }

        // Delay required to wait for TripPlanScreen animate in
        setTimeout(() => {
            refreshViewport();
        }, 100);
    }

    function setMapBounds(newCoords) {
        if (mapViewport.width === 0 || mapViewport.height === 0) {
            refreshViewport();
        }

        setTimeout(() => { // Wait for above to conclude
            if (mapViewport.width > 0 && mapViewport.height > 0) {
                const viewport = new WebMercatorViewport({ width: mapViewport.width, height: mapViewport.height });
                const bound = viewport.fitBounds(
                    newCoords,
                    { padding: 60, offset: [0, -50] }
                );
                setViewState(bound);
            }
        }, 1);
    }

    // Because the parent div is offscreen, Mapbox may not properly load in
    function refreshViewport() {
        if (mapWrapper && mapWrapper.current) {
            const wrapperSize = mapWrapper.current.getBoundingClientRect();
            // Programatically record the map actual pixel size
            setMapViewport({ width: wrapperSize.width, height: wrapperSize.height });
        }
        if (mapRef && mapRef.current) {
            mapRef.current.resize(); // Verify map is re-sized to 100% of parent div
        }
    }

    useEffect(() => {
        if (startingLocation && endingLocation && showTripPlanScreen === true) {
            // Screen has been reopened, lets setup the map again
            setMapBounds([[startingLocation.coords.longitude, startingLocation.coords.latitude], [endingLocation.coords.longitude, endingLocation.coords.latitude]]);
        }
    }, [showTripPlanScreen, mapViewport]);

    return (
        <div className="TripPlanMap" ref={mapWrapper}>
            <Map
                ref={mapRef}

                // Decoupled from state so that the map doesnt constantly re-size itself
                style={{
                    width: '100%',
                    height: 350
                }}

                onLoad={onMapLoad}

                // Allows us to control this map dynamically
                onMove={evt => setViewState(evt.viewState)}
                latitude={viewState.latitude}
                longitude={viewState.longitude}
                zoom={viewState.zoom}

                mapStyle={mapStyle}
                mapboxAccessToken={config.mapboxToken}
            >
                <MapLanguage />
                <TripPlanMapUI
                    setMapBounds={setMapBounds}
                    startingLocation={startingLocation}
                    endingLocation={endingLocation}
                    showTripPlanScreen={showTripPlanScreen}
                    tripPlan={tripPlan}
                />
            </Map>
        </div>
    )
}

export default TripPlanMap
